import React, { useState } from "react";
import { styled } from "@material-ui/styles";
import moment from "moment";
import { MorePopUp } from "../../../../components/src";
import { DeleteIcon, EditIcon } from "../../../appointmentmanagement/src/assets";
import { MarkAsCompleteIcon, MarkAsInCompleteIcon } from "../assets";
import { ITaskItem } from "../RoutineController";
import RoutineDetailPopup from "./RoutineDetailPopup.web";
const { HelperFunctions: helper } = require("../../../../components/src/HelperFunctions");

interface Props {
    routine: ITaskItem;
    onDelete?: (title: string, routineId?: string)=> void;
}


const RoutineCard: React.FC<Props> = (props: Props) => {
    const {
        routine,
        onDelete
    } = props;

    const [openDetailPopup, setOpenDetailPopup] = useState(false)
    const [openDeleteOptions, setOpenDeleteOptions] = useState(false);
    const [openEditOptions, setOpenEditOptions] = useState(false);

    const getHourAndMinute = (timeString: string) => {
        const time = moment(timeString, "hh:mm A");
        return [time.format("hh"), time.format("mm")]
    }

    const onCloseOptionsMenu = ()=>{
        setTimeout(()=>{
            setOpenDeleteOptions(false);
            setOpenEditOptions(false)
        }, 300)
    }

    const moreOptions = [
        {
            label: "Edit",
            icon: EditIcon,
            onClick: () => { 
                setOpenEditOptions(true)
            },
            stayOpenAfterItemClicked: true
        },
        {
            label: "Delete",
            icon: DeleteIcon,
            onClick: () => { 
                setOpenDeleteOptions(true)
            },
            stayOpenAfterItemClicked: true
        },
        {
            label: "Mark as Complete",
            icon: MarkAsCompleteIcon,
            onClick: () => { }
        },
        {
            label: "Mark as Incomplete",
            icon: MarkAsInCompleteIcon,
            onClick: () => { }
        },
    ]

    const deleteOptions = [
        {
            label: "Delete this occurrence",
            icon: DeleteIcon,
            onClick: () => {
                if (onDelete) {
                    setOpenDetailPopup(false);
                    onDelete(routine.title, routine.id)
                }
            }
        },
        {
            label: "Delete all occurrence",
            icon: DeleteIcon,
            onClick: () => {
                if (onDelete) {
                    setOpenDetailPopup(false);
                    onDelete(routine.title, routine.id)
                }
            }
        },
    ]

    const editOptions = [
        {
            label: "Edit this occurrence",
            icon: EditIcon,
            onClick: () => {}
        },
        {
            label: "Edit all occurrence",
            icon: EditIcon,
            onClick: () => {}
        },
    ]

    const getMenuOptions = ()=>{
        if(openDeleteOptions) return deleteOptions;
        else if(openEditOptions) return editOptions;
        return moreOptions;
    }

    const getClassNameForRoutineContainer = ()=>{
        let className = "left";
        if(routine.completed){
            className += " disabled";
        }
        if(helper.isPastRoutine(routine.start_date, routine.start_time, routine.end_date, routine.end_time) && routine.status === "active"){
            className += " past_routine"
        }
        return className;
    }

    return (
        <StyledRoutineCard className="RoutineCard_StyledRoutineCard">
            <div 
              className={getClassNameForRoutineContainer()}>
                <div className="time">
                    <div className="time_text">
                        <div className="hour">{getHourAndMinute(routine.start_time)[0]}</div>
                        <div className="minute">{getHourAndMinute(routine.start_time)[1]}</div>
                    </div>
                    {
                        routine.end_time && (
                            <>
                                <div className="divider"></div>
                                <div className="time_text">
                                    <div className="hour">{getHourAndMinute(routine.end_time)[0]}</div>
                                    <div className="minute">{getHourAndMinute(routine.end_time)[1]}</div>
                                </div>
                            </>
                        )
                    }
                </div>
                <div className="details">
                    <div className="text_details">
                        <header className="title" onClick={()=> setOpenDetailPopup(true)}>{routine.title}</header>
                        <p className="description ellipsis">{routine.description}</p>
                    </div>
                    <div className="recurrence">{routine.recurrence}</div>
                </div>
            </div>
            <div className="right">
                <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                    {
                        routine.completed && (
                            <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect width="42" height="42" rx="21" fill="#303746" />
                                <mask id="mask0_29205_116917" style={{ maskType: "alpha" }} maskUnits="userSpaceOnUse" x="0" y="0" width="52" height="52">
                                    <rect width="52" height="52" fill="#D9D9D9" />
                                </mask>
                                <g mask="url(#mask0_29205_116917)">
                                    <path d="M18.55 29.05L30.8875 16.7125L28.4375 14.2625L18.55 24.15L13.5625 19.1625L11.1125 21.6125L18.55 29.05ZM21 38.5C18.5792 38.5 16.3042 38.0403 14.175 37.121C12.0458 36.2028 10.1938 34.9563 8.61875 33.3813C7.04375 31.8063 5.79717 29.9542 4.879 27.825C3.95967 25.6958 3.5 23.4208 3.5 21C3.5 18.5792 3.95967 16.3042 4.879 14.175C5.79717 12.0458 7.04375 10.1938 8.61875 8.61875C10.1938 7.04375 12.0458 5.79658 14.175 4.87725C16.3042 3.95908 18.5792 3.5 21 3.5C23.4208 3.5 25.6958 3.95908 27.825 4.87725C29.9542 5.79658 31.8063 7.04375 33.3813 8.61875C34.9563 10.1938 36.2028 12.0458 37.121 14.175C38.0403 16.3042 38.5 18.5792 38.5 21C38.5 23.4208 38.0403 25.6958 37.121 27.825C36.2028 29.9542 34.9563 31.8063 33.3813 33.3813C31.8063 34.9563 29.9542 36.2028 27.825 37.121C25.6958 38.0403 23.4208 38.5 21 38.5Z" fill="#00DF80" />
                                </g>
                            </svg>
                        )
                    }
                    {
                        routine.lapsed && (
                            <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M17.9825 0.5C8.3225 0.5 0.5 8.34 0.5 18C0.5 27.66 8.3225 35.5 17.9825 35.5C27.66 35.5 35.5 27.66 35.5 18C35.5 8.34 27.66 0.5 17.9825 0.5ZM18 32C10.265 32 4 25.735 4 18C4 10.265 10.265 4 18 4C25.735 4 32 10.265 32 18C32 25.735 25.735 32 18 32ZM17.51 9.25H17.615C18.315 9.25 18.875 9.81 18.875 10.51V18.455L25.6475 22.48C26.26 22.83 26.4525 23.6175 26.085 24.2125C25.735 24.8075 24.965 24.9825 24.37 24.6325L17.1075 20.275C16.565 19.96 16.25 19.3825 16.25 18.77V10.51C16.25 9.81 16.81 9.25 17.51 9.25Z" fill="url(#paint0_linear_29820_42296)" />
                                <defs>
                                    <linearGradient id="paint0_linear_29820_42296" x1="18" y1="0.5" x2="18" y2="35.5" gradientUnits="userSpaceOnUse">
                                        <stop stop-color="#F37F78" />
                                        <stop offset="1" stop-color="#F75367" />
                                    </linearGradient>
                                </defs>
                            </svg>
                        )
                    }
                </div>
                <div style={{ width: "3rem" }}>
                    {
                        !routine.lapsed && (
                            <MorePopUp
                                menuItems={getMenuOptions()}
                                onClose={onCloseOptionsMenu}
                            />
                        )
                    }
                </div>
            </div>
            <RoutineDetailPopup 
              open={openDetailPopup}
              onClose={()=> setOpenDetailPopup(false)}
              routine={routine}
              editOptions={editOptions}
              deleteOptions={deleteOptions}
            />
        </StyledRoutineCard>
    )
}

const StyledRoutineCard = styled("div")({
    "&.RoutineCard_StyledRoutineCard": {
        background: "linear-gradient(180deg, #23404B 0%, #19282F 100%)",
        borderRadius: "1rem",
        border: "1px solid rgba(25, 40, 47, 1)",
        height: "8.3125rem",
        display: "flex",
        justifyContent: "space-between",
        padding: "0rem 0.5rem",
        alignItems: "center",
        "& > .left": {
            display: "flex",
            alignItems: "center",
            gap: "1.5rem",
            width: "80%",
            "& > .time": {
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                width: "1rem",
                "& > .time_text": {
                    display: "flex",
                    flexDirection: "column",
                    "& > .hour": {
                        fontSize: "1.125rem",
                        fontWeight: 400,
                        color: "rgba(255, 255, 255, 1)",
                        lineHeight: "1.6875rem",
                        textAlign: "center"
                    },
                    "& > .minute": {
                        fontSize: "0.6875rem",
                        fontWeight: 400,
                        color: "rgba(255, 255, 255, 0.75)",
                        lineHeight: "1rem",
                        marginTop: "-5px",
                        textAlign: "center"
                    }
                },
                "& > .divider": {
                    height: "1.125rem",
                    width: "1px",
                    background: "white"
                }
            },
            "& > .details": {
                display: "flex",
                flexDirection: "column",
                gap: "1rem",
                width: "calc(100% - 1rem)",
                "& > .text_details": {
                    display: "flex",
                    flexDirection: "column",
                    gap: "0.3125rem",
                    "& > header": {
                        fontSize: "2rem",
                        color: "rgba(255, 255, 255, 1)",
                        fontWeight: 500,
                        cursor: "pointer",
                        textTransform: "capitalize"
                    },
                    "& > p": {
                        fontSize: "0.75rem",
                        color: "rgba(255, 255, 255, 1)",
                        fontWeight: 400,
                        maxWidth: "100%"
                    }
                },
                "& > .recurrence": {
                    height: "0.9375rem",
                    border: "1px solid white",
                    display: "flex",
                    alignItems: "center",
                    padding: "0rem 0.5rem",
                    boxSizing: "border-box",
                    borderRadius: "1.4375rem",
                    color: "rgba(255, 255, 255, 1)",
                    fontSize: "0.625rem",
                    fontWeight: 400,
                    width: "fit-content"
                }
            },
            "&.disabled":{
                opacity: 0.5
            },
            "&.past_routine": {
                "& > .time": {
                    "& > .time_text": {
                        "& > .hour": {
                            color: "rgba(247, 83, 103, 1)"
                        },
                        "& > .minute": {
                            color: "rgba(247, 83, 103, 1)"
                        }
                    },
                },
                "& > .details": {
                    "& > .text_details": {
                        "& > header": {
                            color: "rgba(247, 83, 103, 1)"
                        }
                    }
                }
            }
        },
        "& > .right": {
            display: "flex",
            height: "100%",
            boxSizing: "border-box",
            paddingTop: "1rem"
        }
    }
})

export default RoutineCard;
// Customizable Area Start
import React from "react";
import ProfileDetailsController, {
    Props
} from "./ProfileDetailsController";
import { styled } from "@material-ui/styles";
import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonOutlineOutlined';
import { Button } from "../../../components/src";
import { Box } from "@material-ui/core";
const { HelperFunctions: helper } = require("../../../components/src/HelperFunctions");
// Customizable Area End

export default class ProfileDetails extends ProfileDetailsController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    ProfileDetailsContainer = ()=>{
        const {
          userInfo,
          isProfileIconLoading,
        } = this.state;
        return (
            <StyledProfileDetailsContainer className="ProfileDetails_StyledProfileDetailsContainer">
                <div style={{ display: "flex", width: "10%" }}></div>
                <div className="profile_details">
                    <div className="image">
                        {
                            userInfo && userInfo.image_url ? (
                                <>
                                    <img id="user-profile-icon" src={userInfo.image_url} onLoad={() => this.setState({ isProfileIconLoading: false })} />
                                    {isProfileIconLoading && <Box width={"inherit"} height={"inherit"} borderRadius={"inherit"} top={"0px"} position={"absolute"} className="shimmer"></Box>}
                                </>
                            ) : (
                                <PersonOutlineOutlinedIcon style={{ fill: "white", width: "3rem", height: "3rem" }}/>
                            )
                        }
                    </div>
                    <div className="details">
                        <div className="detail-col">
                            <div className="item">
                                <label>Full Name</label>
                                <p>{userInfo && userInfo.full_name}</p>
                            </div>
                            <div className="item">
                                <label>Date Of Birth</label>
                                <p>{userInfo && userInfo.dob && helper.formatDate(userInfo.dob, 'D MMMM YYYY')}</p>
                            </div>
                        </div>
                        <div className="detail-col">
                            <div className="item">
                                <label>User Name</label>
                                <p>{userInfo && userInfo.user_name}</p>
                            </div>
                            <div className="item">
                                <label>Email ID</label>
                                <p>{userInfo && userInfo.email}</p>
                            </div>
                        </div>
                        <div className="detail-col">
                            <div className="item">
                                <label>Phone Number</label>
                                <p>{userInfo && `+${userInfo.country_code} ${userInfo.phone_number}`}</p>
                            </div>
                            <div className="item">
                                <label>Interest</label>
                                <p>{userInfo && userInfo.interests}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{ display: "flex", height: "2rem", width: "10%", justifyContent: "center" }}>
                    <Button 
                      text="Edit"
                      type="link"
                      buttonStyles={{
                        fontSize: "1rem"
                      }}
                      onClick={()=> this.redirectTo("EditProfile")}
                    />
                </div>
            </StyledProfileDetailsContainer>
        )
    }
    // Customizable Area End

    render() {
        // Customizable Area Start

        return (
            <>
              {
                this.ProfileDetailsContainer()
              }
            </>
        );
        // Customizable Area End
    }
}

// Customizable Area Start
const StyledProfileDetailsContainer = styled("div")({
   "&.ProfileDetails_StyledProfileDetailsContainer":{
     display: "flex",
     justifyContent: "center",
     padding: "1.5rem 1rem",
     boxSizing: "border-box",
     width: "100%",
     "& > .profile_details":{
        width: "80%",
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
        gap: "2rem",
        "& > .image":{
          width: "6.25rem",
          height: "6.25rem",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          background: "linear-gradient(180deg, #23404B 0%, #19282F 100%)",
          borderRadius: "1rem",
          position: "relative",
          "& > img":{
            width: "100%",
            height: "100%",
            objectFit: "cover",
            borderRadius: "1rem"
          }
        },
        "& > .details":{
            display: "grid",
            width: "100%",
            boxSizing: "border-box",
            gridTemplateColumns: "repeat(3, 1fr)",
            rowGap: "1rem",
            columnGap: "1rem",
            background: "linear-gradient(180deg, #23404B 0%, #19282F 100%)",
            borderRadius: "1rem",
            padding: "1rem 2rem",
            "& > .detail-col":{
                display: "flex",
                flexDirection: "column",
                gap: "1rem",
                "& > .item":{
                    "& > label":{
                        fontSize: "0.875rem",
                        fontWeight: 500,
                        color: "rgba(255, 255, 255, 0.8)"
                    },
                    "& > p":{
                        fontSize: "1rem",
                        fontWeight: 400,
                        color: "rgba(255, 255, 255, 1)",
                        wordBreak: "break-all"
                    }
                }
            },
            [`@media screen and (max-width: 900px)`]: {
                gridTemplateColumns: "repeat(1, 1fr)",
            },
        }
     }
   }
})
// Customizable Area End

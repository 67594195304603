import React, { useRef, useState } from "react";
import { styled } from "@material-ui/styles";
import { Box, Menu, IconButton } from "@material-ui/core";
import InsertEmoticonIcon from '@material-ui/icons/InsertEmoticon';
import EmojisPicker from "../../blocks/emojis/src/EmojisPicker.web";
const {
    HelperFunctions: helper,
  } = require("./HelperFunctions");
import { closeIcon, ImageIcon, VideoIcon, AttachIcon, SendIcon } from "../../blocks/postcreation/src/assets";
interface Props {
    postImages: any[];
    onClickSend?: (event:{text: string}) => void;
}


const ChatInputField: React.FC<Props> = (props: Props) => {
    const { postImages, onClickSend } = props;
    const inputRef = useRef<HTMLInputElement>(null);
    const inputVideoRef = useRef<HTMLInputElement>(null);
    const inputAllRef = useRef<HTMLInputElement>(null);
    const [attachmentAnchorElement, setAttachmentAnchorElement] = useState<HTMLImageElement | null>(null);
    const [message, setMessage] = useState("");
    const [emojiButtonElement, setEmojiButtomElement] = useState<HTMLButtonElement | null>(null);
    const chatInputFieldRef = useRef<HTMLInputElement | null>(null);

    const clearImages = () => {

    }

    const onCloseAttachmentPopup = () => {
        setAttachmentAnchorElement(null)
    }

    const onEmojiClick = (event: React.MouseEvent, emojiObject: { emoji: string }) => {
        setMessage((prevText) => prevText + emojiObject.emoji);
    };

    const toggleEmojiPicker = (event: React.MouseEvent<HTMLButtonElement>) => {
        setEmojiButtomElement(event.currentTarget)
    };

    const onCloseEmojiPicker = () => {
        setEmojiButtomElement(null);
        setTimeout(() => {
            if (chatInputFieldRef.current) {
                chatInputFieldRef.current.focus();
            }
        }, 10);
    }

    const AttachmentPopup = () => {
        return (
            <>
                <Menu
                    open={Boolean(attachmentAnchorElement)}
                    anchorEl={attachmentAnchorElement}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    onClose={onCloseAttachmentPopup}
                    PaperProps={{
                        style: {
                            background: "transparent",
                            transform: "translate(-30%, -120%)"
                        }
                    }}
                    MenuListProps={{
                        style: {
                            padding: "0",
                        }
                    }}
                    getContentAnchorEl={null}
                >
                    <StyledAttachmentPopup className="AttachmentPopup_StyledAttachmentPopup">
                        <div className="item" onClick={() => {
                            if (inputRef.current) {
                                inputRef.current.click()
                            }
                        }}>
                            <img src={ImageIcon} />
                            Upload Image
                        </div>
                        <div className="item" onClick={() => {
                            if (inputVideoRef.current) {
                                inputVideoRef.current.click()
                            }
                        }}>
                            <img src={VideoIcon} />
                            Upload Video
                        </div>
                    </StyledAttachmentPopup>
                </Menu>
            </>
        )
    }

    const onSend = () => {
        if (message.trim()) {
            onClickSend?.({text: message.trim()})
            setMessage("")
        }
    }

    const InputField = () => {
        return (
            <StyledInputField className="GroupPostCreation_StyledInputField">
                <Box>
                    <IconButton id="chat-emoji-picker" onClick={toggleEmojiPicker}>
                        <InsertEmoticonIcon className="insert-emoticon" />
                    </IconButton>
                    <EmojisPicker emojiRefElement={emojiButtonElement} onClose={() => onCloseEmojiPicker()} onEmojiClick={onEmojiClick} />
                </Box>
                <input 
                  ref={chatInputFieldRef} 
                  placeholder="Message" 
                  value={message} 
                  onChange={(event) => {
                    const sanitizedValue = event.target.value.replace(/^\s+/, '');
                    const parsedValue = helper.parseEmojis(sanitizedValue);
                    setMessage(parsedValue)
                  }} 
                  onKeyDown={(event)=>{
                       if(event.key === "Enter") onSend();  
                  }}
                />
                <div className="actions">
                    <img src={AttachIcon} onClick={(event) => setAttachmentAnchorElement(event.currentTarget)} />
                    <img
                        src={SendIcon}
                        className={`${message ? "" : "disabled"}`}
                        onClick={onSend}
                    />
                    {AttachmentPopup()}
                </div>
                <input
                    type="file"
                    ref={inputRef}
                    // onChange={this.onAddImage}
                    multiple
                    accept="image/png, image/jpg, image/jpeg"
                    style={{ display: "none" }}
                />
                <input
                    type="file"
                    ref={inputVideoRef}
                    // onChange={this.onAddImage}
                    multiple
                    accept="video/*"
                    style={{ display: "none" }}
                />
                <input
                    type="file"
                    ref={inputAllRef}
                    // onChange={this.onAddImage}
                    multiple
                    accept="image/png, image/jpg, image/jpeg, video/*"
                    style={{ display: "none" }}
                />
            </StyledInputField>
        )
    }

    return (
        <StyledChatInputField
            className={`ChatInputField_StyledChatInputField ${postImages.length > 0 ? "active" : ""}`}
        >
            {
                postImages.length > 0 && (
                    <div>
                        <div className="close-icon" onClick={clearImages}>
                            <img src={closeIcon} />
                        </div>
                    </div>
                )
            }
            {InputField()}
        </StyledChatInputField>
    )
}

const StyledChatInputField = styled("div")({
    "&.ChatInputField_StyledChatInputField": {
        width: "100%",
        boxSizing: "border-box",
        display: "flex",
        flexDirection: "column",
        "&.active": {
            height: "calc(100% - 2rem)",
            top: "1rem",
        },
        "& .close-icon": {
            background: "rgba(0, 0, 0, 0.2)",
            width: "2.5rem",
            height: "2.5rem",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            cursor: "pointer",
            borderRadius: "100%"
        }
    }
})

const StyledInputField = styled("div")({
    "&.GroupPostCreation_StyledInputField": {
        background: "linear-gradient(180deg, #23404B 0%, #19282F 100%)",
        border: "1px solid rgba(25, 181, 185, 0.27)",
        borderRadius: "6.25rem",
        display: "flex",
        width: "100%",
        boxSizing: "border-box",
        height: "3rem",
        alignItems: "center",
        gap: "10px",
        padding: "0rem 1rem",
        flexShrink: 0,
        "& .insert-emoticon": {
            color: "rgb(255 255 255 / 65%)"
        },
        "& #chat-emoji-picker": {
            padding: "0px"
        },
        "& > input": {
            width: "100%",
            border: "none",
            outline: "none",
            background: "transparent",
            fontSize: "1rem",
            fontWeight: 400,
            color: "rgba(255, 255, 255, 0.8)",
            "&::placeholder": {
                fontSize: "1rem",
                fontWeight: 400,
                color: "rgba(255, 255, 255, 0.8)"
            }
        },
        "& > .actions": {
            display: "flex",
            alignItems: "center",
            gap: "0.5rem",
            "& > img": {
                "&:nth-child(2)": {
                    width: "24px"
                },
                cursor: "pointer",
                "&.disabled": {
                    opacity: 0.3,
                    cursor: "not-allowed"
                }
            }
        }
    }
})

const StyledAttachmentPopup = styled("div")({
    "&.AttachmentPopup_StyledAttachmentPopup": {
        display: "flex",
        flexDirection: "column",
        background: "linear-gradient(180deg, #23404B 0%, #19282F 100%)",
        border: "1px solid rgba(25, 181, 185, 0.27)",
        borderRadius: "0.5rem",
        width: "11.375rem",
        height: "4.75rem",
        justifyContent: "space-around",
        padding: "0rem 1rem",
        boxSizing: "border-box",
        "& .item": {
            display: "flex",
            alignItems: "center",
            gap: "0.5rem",
            fontSize: '1rem',
            fontWeight: 400,
            color: "rgba(255, 255, 255, 1)",
            cursor: "pointer"
        }
    }
})

export default ChatInputField;
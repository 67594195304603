// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { v4 as uuidv4 } from "uuid";
import _ from "lodash";
export const configJSON = require("./config.js");
import { getStorageData } from "../../../framework/src/Utilities";
import { IAddPost, IComment, IMentionInputType, IMetaPagination, IPost } from "../../../components/src/CommonTypes";
const {
    HelperFunctions: helper,
} = require("../../../components/src/HelperFunctions");
// Customizable Area End

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

export interface S {
    // Customizable Area Start
    token: string | null;
    trendingPostsList: Array<IPost>;
    selectedPostDetail: IAddPost | null;
    userId: number | undefined;
    lastElementRef: React.RefObject<HTMLInputElement> | null;
    isEdit: boolean;
    confirmationPostDeletePopUpData: {
        header: string;
        message: string;
        type: string;
        eventId: string;
    } | null;
    perPage: number;
    page: number;
    isAllPostFetched: boolean;
    currentLikedPostId: string;
    deletedPostId: string | number;
    likedUserList: { id: string | number, attributes: { like_by_name: string } }[] | undefined;
    currentCommentModal: {
        id: string | number;
    } | null;
    commentsCurrentPage: number;
    currentPostCommentsList: IComment[];
    isLoadingComments: boolean;
    isAllCommentsFetched: boolean;
    currentUploadingBlobIds: number[];
    currentUploadingPostDetails: IAddPost | null;
    isPostUploading: boolean;
    currentSelectedCommentId: string | number;
    numberOfFilesUploaded: number;
    searchedMentionsList: IMentionInputType[];
    postCommentsMetaData: IMetaPagination | null;
    currentLikedCommentId: string | number;
    selectedPost: IPost | null
    // Customizable Area End
}

export interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class TrendingPostsListController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    getAllTrendingPostsListApiId: string = "";
    editPostApiId: string = "";
    deletePostApiCallId: string = "";
    likeTrendingPostApiCallId: string = "";
    getCommentsApiCallId: string = "";
    createCommentApiCallId: string = "";
    addNewCommentApiCallId: string = "";
    deletePostCommentApiCallId: string = "";
    uploadFileApiCallId: string[] = [];
    removeAttachmentFromPostApiCallId: string = "";
    likedUsersListApiCallId: string = "";
    searchMentionsApiCallId: string = "";
    likeDislikeCommentApiCallId: string = "";
    commentLikedUsersListApiCallId: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            // Customizable Area Start
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionResponseMessage),
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            token: "",
            trendingPostsList: [],
            selectedPostDetail: null,
            userId: undefined,
            lastElementRef: null,
            isEdit: false,
            confirmationPostDeletePopUpData: null,
            perPage: 3,
            page: 1,
            isAllPostFetched: false,
            deletedPostId: "",
            currentLikedPostId: "",
            likedUserList: undefined,
            currentCommentModal: null,
            commentsCurrentPage: 1,
            currentPostCommentsList: [],
            isLoadingComments: false,
            currentUploadingPostDetails: null,
            currentUploadingBlobIds: [],
            isPostUploading: false,
            numberOfFilesUploaded: 0,
            isAllCommentsFetched: false,
            currentSelectedCommentId: "",
            searchedMentionsList: [],
            postCommentsMetaData: null,
            currentLikedCommentId: "",
            selectedPost: null,
            // Customizable Area End
        };

        // Customizable Area Start
        this.handleChangePageNumber = this.handleChangePageNumber.bind(this);

        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async componentDidMount() {
        await super.componentDidMount();
        // Customizable Area Start
        this.setToken();
        (async () => {
            const userData = await helper.getUserData();
            if (userData) {
                this.setState({ userId: userData.id });
            }
        })();
        // Customizable Area End
    }

    // Customizable Area Start
    receive = async (from: string, message: Message) => {
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const webApiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );
            switch (webApiRequestCallId) {
                case this.getAllTrendingPostsListApiId:
                    this.handleGetPostListCall(message);
                    break;
                case this.editPostApiId:
                    this.handleEditPostCall(message);
                    break;
                case this.deletePostApiCallId:
                    this.handleDeletePostCall(message);
                    break;
                case this.likeTrendingPostApiCallId:
                    this.likeTrendingPostApiCallHandler(message);
                    break;
                case this.getCommentsApiCallId:
                    this.handleGetCommentsApiCall(message);
                    break;
                case this.addNewCommentApiCallId:
                    this.handleAddCommentApiCall(message);
                    break;
                case this.deletePostCommentApiCallId:
                    this.handleDeleteCommentApiCall(message);
                    break;
                case this.likedUsersListApiCallId:
                    this.handleGetPostLikedUsersListCall(message);
                    break;
                case this.removeAttachmentFromPostApiCallId:
                    this.handleDeleteAttachmentFromPostCall(message);
                    break;
                case this.searchMentionsApiCallId:
                    this.handleGetMentionsListCall(message);
                    break;
                case this.likeDislikeCommentApiCallId:
                    this.likeCommentApiCallHandler(message);
                    break;
                case this.commentLikedUsersListApiCallId:
                    this.handleGetCommentLikedUsersListCall(message);
                    break;
            }

            if (this.uploadFileApiCallId.includes(webApiRequestCallId)) {
                this.handlerUploadFileApiCall(message);
            }
        }

        // Customizable Area End
    };

    setToken = async () => {
        const token = await getStorageData("authToken");
        this.setState({ token }, () => {
            this.getTrendingPostsList();
        });
    };

    openPostDeleteModal = (title: string, eventId: string | number) => {
        const findPost = this.state.trendingPostsList.find((item) => item.id === eventId);
        this.setState({
            confirmationPostDeletePopUpData: {
                header: "Delete Post?",
                message: `Are you sure that you want to delete this ${title}?`,
                type: "delete",
                eventId: eventId as string,
            },
            deletedPostId: eventId,
        }, () => {
            if(findPost) {
                this.setState({ selectedPost: findPost })
            }
        });
    };

    onCloseDeleteConfirmationModal = () => {
        this.setState({
            confirmationPostDeletePopUpData: null,
        });
    };

    onAcceptPostDeleteConfirmation = async () => {
        if (this.state.confirmationPostDeletePopUpData) {
            const { type, eventId } = this.state.confirmationPostDeletePopUpData;
            if (type === "delete") {
                this.onDeletePost(eventId);
            }
        }
    };

    handleDeletePostCall = (message: Message) => {
        const responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );

        if (!responseJson?.errors) {
            helper.showSuccessToast("Post deleted successfully!");
            const { trendingPostsList, deletedPostId } = this.state;
            const updatedPostsList = trendingPostsList.filter(
                (item) => item.id !== deletedPostId
            );
            this.setState({ trendingPostsList: _.uniqBy(updatedPostsList, 'id') });
        } else {
            const errorDelToastMsg = responseJson?.errors?.[0]?.message
                ? responseJson.errors[0].message
                : "Something went wrong, please try again!";
            helper.showErrorToast(errorDelToastMsg);
        }
        this.setState({ deletedPostId: "", selectedPost: null });
        helper.hideLoader();
    };

    getEndPoint = (postTypeOf: string) => {
        if(postTypeOf === "event") {
            return configJSON.postsEndpoint;
        }
        return configJSON.groupPostsEndpoint;
    }

    getEndPointQuery = (value: string) => {
        if(value === "event") {
            return "event_id";
        }
        return "group_id";
    }

    onDeletePost = async (postId: string | number) => {
        this.setState({
            confirmationPostDeletePopUpData: null,
        });
        const { selectedPost } = this.state;
        const type = selectedPost?.attributes?.event_id ? "event" : "group";
        const typeId = selectedPost?.attributes?.event_id || selectedPost?.attributes?.group_id
        helper.showLoader();
        this.deletePostApiCallId = await helper.apiCall({
            method: configJSON.deleteApiMethod,
            endPoint: `${this.getEndPoint(type)}/${postId}?${this.getEndPointQuery(type)}=${typeId}`,
            contentType: configJSON.validationApiContentType,
            token: this.state.token,
        });
    };

    handleDeleteAttachmentFromPostCall = (message: Message) => {
        const responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );

        if (responseJson?.errors) {
            const errorDelToastMsg = responseJson?.errors?.[0]?.message
                ? responseJson.errors[0].message
                : "Something went wrong while removing the file, please try again!";
            helper.showErrorToast(errorDelToastMsg);
        }
    };

    onRemoveAttachmentFromPost = async (postId: string | number, attachmentBlobId: number) => {
        const { selectedPost } = this.state;
        const type = selectedPost?.attributes?.event_id ? "event" : "group";
        const typeId = selectedPost?.attributes?.event_id || selectedPost?.attributes?.group_id
        this.removeAttachmentFromPostApiCallId = await helper.apiCall({
            method: configJSON.deleteApiMethod,
            endPoint: `${configJSON.removeAttachmentEndpoint}?${this.getEndPointQuery(type)}=${typeId}&blob_id=${attachmentBlobId}&id=${postId}`,
            contentType: configJSON.validationApiContentType,
            token: this.state.token,
        });
    };

    handleChangePageNumber = () => {
        if (!this.state.isAllPostFetched) {
            this.setState({ page: this.state.page + 1 }, () => this.getTrendingPostsList());
        }
    };

    handleChangeCommentsPageNumber = (postId: string) => {
        this.setState({ commentsCurrentPage: this.state.commentsCurrentPage + 1 }, () => this.getComments(postId))
    }

    handleGetPostListCall = (message: Message) => {
        const responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        if (!responseJson?.meta?.next_page) {
            this.setState({ isAllPostFetched: true });
        }
        if (responseJson?.trending_posts) {
            const trendingPosts = responseJson.trending_posts?.map((item: { post: { data: IPost } }) => {
                return item?.post?.data
            })
            this.setState({
                trendingPostsList: _.uniqBy([...this.state.trendingPostsList, ...trendingPosts], "id"),
            });
        } else {
            this.setState({ trendingPostsList: [] });
        }
        helper.hideLoader();
    };

    getTrendingPostsList = async () => {
        helper.showLoader();
        this.getAllTrendingPostsListApiId = await helper.apiCall({
            method: configJSON.exampleAPiMethod,
            contentType: configJSON.validationApiContentType,
            token: this.state.token,
            endPoint: `${configJSON.trendingPostsEndpoint}?per_page=15&page_no=${this.state.page}`,
        });
    };

    getFileType = (url: string) => {
        if (url.includes("type=image")) {
            return "image";
        } else if (url.includes("type=application")) {
            return "pdf";
        }
        return "video";
    };

    onOpenEditPostModal = (post: IPost) => {
        const files: Array<{
            selectedUrl: string;
            imgInvalid: boolean;
            type: string;
            blobId?: number;
        }> = post.attributes.images_and_videos.map((item) => {
            return {
                selectedUrl: item.url,
                type: this.getFileType(item.url),
                imgInvalid: false,
                blobId: item.blob_id,
                uniqId: uuidv4(),
            };
        });
        this.setState({
            selectedPostDetail: {
                postText: post.attributes.body,
                postImages: files,
                id: post.id,
            },
            selectedPost: post,
            isEdit: true,
        });
    };

    onCloseCreatePostModal = () => {
        this.setState({
            selectedPostDetail: null,
            isEdit: false,
            isPostUploading: false,
            numberOfFilesUploaded: 0,
            selectedPost: null,
        });
    };

    handleEditPostCall = (message: Message) => {
        const responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        if (!responseJson?.errors && responseJson?.data) {
            const { trendingPostsList } = this.state;
            const updatedPosts = trendingPostsList?.map((item) => {
                if (item.id === responseJson.data?.id) {
                    item = { ...responseJson.data };
                }
                return item;
            });
            this.setState({ trendingPostsList: updatedPosts });
        } else {
            const editErrorMsg = responseJson?.errors?.[0]?.message
                ? responseJson.errors[0].message
                : "Something went wrong, please try again!";
            helper.showErrorToast(editErrorMsg);
        }
        helper.hideLoader();
    };

    onEditPost = async () => {
        const {
            isEdit,
            currentUploadingPostDetails,
            currentUploadingBlobIds,
            selectedPost
        } = this.state;
        this.onCloseCreatePostModal();
        helper.showLoader();
        const type = selectedPost?.attributes?.event_id ? "event" : "group";
        const typeId = selectedPost?.attributes?.event_id || selectedPost?.attributes?.group_id || "";
        const body: { body?: string; event_id?: string; blob_ids?: string[] } = {};
        const formData = new FormData();
        if (currentUploadingPostDetails) {
            formData.append("body", currentUploadingPostDetails.postText)
        }
        formData.append(`${this.getEndPointQuery(type)}`, typeId?.toString())
        if (currentUploadingBlobIds.length) {
            formData.append("blob_ids", `[${JSON.stringify(currentUploadingBlobIds.join(","))}]`)
        }
        if (currentUploadingPostDetails) {
            body.body = currentUploadingPostDetails.postText;
        }
        if (currentUploadingBlobIds.length) {
            const blobIds = currentUploadingBlobIds.join(",");
            body.blob_ids = [blobIds];
        }

        if (isEdit) {
            this.editPostApiId = await helper.apiCall({
                method: configJSON.putApiMethod,
                token: this.state.token,
                type: "formData",
                endPoint: `${configJSON.postsEndpoint}/${currentUploadingPostDetails?.id}`,
                body: formData,
            });
        }
        this.setState({
            isEdit: false,
            selectedPostDetail: null,
            currentUploadingPostDetails: null,
            currentUploadingBlobIds: [],
            numberOfFilesUploaded: 0,
        });
        this.uploadFileApiCallId = [];
    };

    onClickShareTrendingPost = async (postDetails: IAddPost) => {
        this.setState(
            {
                currentUploadingPostDetails: postDetails,
                isPostUploading: true,
            },
            () => {
                if (postDetails?.postImgFiles?.length) {
                    postDetails.postImgFiles.forEach(async (file) => {
                        const formData = new FormData();
                        formData.append("media", file);

                        const apiId = await helper.uploadFilesToServer({
                            token: this.state.token,
                            body: formData,
                            type: "formData",
                        });
                        this.uploadFileApiCallId.push(apiId);
                    });
                } else {
                    this.onEditPost();
                }
            }
        );
    };

    handlerUploadFileApiCall = (message: Message) => {
        const responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        if (responseJson && responseJson.blob_id) {
            this.setState(
                (prevState) => {
                    return {
                        currentUploadingBlobIds: [
                            ...prevState.currentUploadingBlobIds,
                            responseJson.blob_id,
                        ],
                    };
                },
                () => {
                    const { currentUploadingBlobIds, currentUploadingPostDetails } =
                        this.state;
                    if (
                        currentUploadingPostDetails &&
                        this.state.numberOfFilesUploaded ===
                        currentUploadingBlobIds.length
                    ) {
                        this.onEditPost();
                    }
                }
            );
        }
    };

    onLikeTrendingPost = async (postId: string) => {
        const body = {
            likeable_id: postId,
            like_type: "post",
        };
        this.likeTrendingPostApiCallId = await helper.apiCall({
            method: configJSON.postApiMethod,
            contentType: configJSON.validationApiContentType,
            token: this.state.token,
            endPoint: `${configJSON.likePostEndPoint}`,
            body,
        });
        this.setState({
            currentLikedPostId: postId,
        });
    };

    likeTrendingPostApiCallHandler = (message: Message) => {
        const responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        if (responseJson && (responseJson.message || responseJson.data)) {
            const { currentLikedPostId, trendingPostsList } = this.state;
            const updatedPostList = trendingPostsList.map((post) => {
                if (post.id === currentLikedPostId) {
                    if (post.attributes.liked_by_me) {
                        post.attributes.total_likes -= 1;
                    } else {
                        post.attributes.total_likes += 1;
                    }
                    post.attributes.liked_by_me = !post.attributes.liked_by_me;
                }
                return post;
            });
            this.setState({
                trendingPostsList: updatedPostList,
            });
        } else {
            const likeCommentErrMsg = responseJson?.errors?.[0]?.message
                ? responseJson.errors[0].message
                : "Something went wrong, please try again!";
            helper.showErrorToast(likeCommentErrMsg);
        }
        helper.hideLoader();
    };

    handleGetPostLikedUsersListCall = (message: Message) => {
        const responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        if (responseJson?.data?.data) {
            this.setState({
                likedUserList: [...responseJson.data.data],
            });
        } else {
            this.setState({ likedUserList: [] });
        }
        helper.hideLoader();
    };

    getLikedUsersList = async (postId: number | string) => {
        helper.showLoader();
        this.likedUsersListApiCallId = await helper.apiCall({
            method: configJSON.exampleAPiMethod,
            contentType: configJSON.validationApiContentType,
            token: this.state.token,
            endPoint: `${configJSON.likedUsersListApiEndpoint}?post_id=${postId}`,
        });
    }

    setLikedUserList = () => {
        this.setState({
            likedUserList: undefined,
        });
    };

    onLikeComment = async (commentId: string | number) => {
        const body = {
            likeable_id: commentId,
            like_type: "comment",
        };
        this.likeDislikeCommentApiCallId = await helper.apiCall({
            method: configJSON.postApiMethod,
            contentType: configJSON.validationApiContentType,
            token: this.state.token,
            endPoint: `${configJSON.likePostEndPoint}`,
            body,
        });
        this.setState({
            currentLikedCommentId: commentId,
        });
    };

    likeCommentApiCallHandler = (message: Message) => {
        const responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        if (responseJson && (responseJson.message || responseJson.data)) {
            const { currentLikedCommentId, currentPostCommentsList } = this.state;
            const updatedCommentsList = currentPostCommentsList.map((comment) => {
                if (comment.id === currentLikedCommentId) {
                    if (comment.attributes.liked_by_me) {
                        comment.attributes.total_likes -= 1;
                    } else {
                        comment.attributes.total_likes += 1;
                    }
                    comment.attributes.liked_by_me = !comment.attributes.liked_by_me;
                }
                return comment;
            });
            this.setState({
                currentPostCommentsList: updatedCommentsList,
            });
        }
        helper.hideLoader();
    };

    handleGetCommentLikedUsersListCall = (message: Message) => {
        const responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        if (responseJson?.data?.data) {
            this.setState({
                likedUserList: [...responseJson.data.data],
            });
        } else {
            this.setState({ likedUserList: [] });
        }
        helper.hideLoader();
    };

    getCommentLikedUsersList = async (commentId: number | string) => {
        helper.showLoader();
        this.commentLikedUsersListApiCallId = await helper.apiCall({
            contentType: configJSON.validationApiContentType,
            method: 'GET',
            token: this.state.token,
            endPoint: `${configJSON.commentsLikedListUsers}?likeable_id=${commentId}`,
        });
    }

    getComments = async (postId: string | number) => {
        this.setState({
            isLoadingComments: true,
        });
        this.getCommentsApiCallId = await helper.apiCall({
            method: configJSON.exampleAPiMethod,
            contentType: configJSON.validationApiContentType,
            token: this.state.token,
            endPoint: `${configJSON.commentsApiEndpoint}?page_no=${this.state.commentsCurrentPage}&per_page=15&commentable_id=${postId}&order=desc`,
        });
    };

    handleGetCommentsApiCall = (message: Message) => {
        const responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        if (responseJson?.meta) {
            this.setState({ postCommentsMetaData: responseJson?.meta })
        }
        if (responseJson && responseJson.data) {
            this.setState({
                currentPostCommentsList: _.uniqBy([...this.state.currentPostCommentsList, ...responseJson.data], "id")
            })
        }
        this.setState({
            isLoadingComments: false,
        });
    };

    handleGetMentionsListCall = (message: Message) => {
        const responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        if (responseJson?.data) {
            const mentionsList: Array<IMentionInputType> = responseJson.data?.map((item: { attributes: { account_info: { id: number, user_name: string, name: string } } }) => {
                return {
                    id: item.attributes.account_info.id.toString(),
                    display: item.attributes.account_info.user_name,
                }
            })
            this.setState({
                searchedMentionsList: [...mentionsList],
            });
        } else {
            this.setState({ searchedMentionsList: [] });
        }
    };

    getMentionsList = async () => {
        const { selectedPost } = this.state;
        const type = selectedPost?.attributes?.event_id ? "event" : "group";
        const typeId = selectedPost?.attributes?.event_id || selectedPost?.attributes?.group_id || "";
        this.searchMentionsApiCallId = await helper.apiCall({
            method: configJSON.exampleAPiMethod,
            contentType: configJSON.validationApiContentType,
            token: this.state.token,
            endPoint: `${configJSON.searchMention}?${this.getEndPointQuery(type)}=${typeId}`,
        });
    }

    openCommentsModal = async (postId: string) => {
        const postsList = this.state.trendingPostsList.map((item) => {
            if (item.id === postId) {
                item.attributes.show_comments_section = true
            } else {
                item.attributes.show_comments_section = false
            }
            return item;
        })
        this.setState({
            trendingPostsList: postsList,
            currentCommentModal: {
                id: postId,
            }, currentPostCommentsList: [], commentsCurrentPage: 1, postCommentsMetaData: null
        }, async () => {
            await this.getComments(postId);
            this.getMentionsList();
        })
    };

    closeCommentsModal = (postId: number | string) => {
        const postsList = this.state.trendingPostsList.map((item) => {
            if (item.id === postId) {
                item.attributes.show_comments_section = false
            }
            return item;
        })
        this.setState({ trendingPostsList: postsList, currentCommentModal: null, currentPostCommentsList: [], commentsCurrentPage: 1, postCommentsMetaData: null, isAllCommentsFetched: false, })
    };

    handleUpdatePostCommentsReplyCount = (commentId: number | string, action?: string) => {
        const updatedCommentsList = this.state.currentPostCommentsList.map((item) => {
            if (item.id === commentId) {
                if (action === "decrease") {
                    item.attributes.reply_count -= 1
                } else {
                    item.attributes.reply_count += 1
                }
            }
            return item
        })
        this.setState({ currentPostCommentsList: updatedCommentsList })
    }

    handleShowReplySection = (commentId: number | string) => {
        const updatedCommentsList = this.state.currentPostCommentsList.map((item) => {
            if (item.attributes) {
                if (item.id === commentId) {
                    item.attributes.show_replies_section = true
                } else {
                    item.attributes.show_replies_section = false
                }
            }
            return item
        })
        this.setState({ currentPostCommentsList: updatedCommentsList })
    }

    handleHideReplySection = (commentId: number | string) => {
        const updatedCommentsList = this.state.currentPostCommentsList.map((item) => {
            if (item.id === commentId) {
                item.attributes.show_replies_section = false
            }
            return item
        })
        this.setState({ currentPostCommentsList: updatedCommentsList })
    }

    scrollToTopOnAddComment = () => {
        const topDiv = document.getElementById("top_comment_div");
        if (topDiv) {
            topDiv.scrollTo({
                top: 0,
                behavior: "smooth"
            })
        }
    }

    onAddNewComment = async (comment: { text: string; postId: string }) => {
        this.setState({
            isLoadingComments: true,
        });
        const body = {
            comment: {
                commentable_id: comment.postId,
                commentable_type: "BxBlockPosts::Post",
                comment: comment.text,
            },
        };
        this.addNewCommentApiCallId = await helper.apiCall({
            method: configJSON.postApiMethod,
            contentType: configJSON.validationApiContentType,
            token: this.state.token,
            endPoint: `${configJSON.commentsApiEndpoint}`,
            body,
        });
    };

    handleAddCommentApiCall = (message: Message) => {
        const responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        if (responseJson && responseJson.data) {
            const { currentCommentModal, trendingPostsList, currentPostCommentsList } = this.state;
            let updatedCommentsList = [{ ...responseJson.data }, ...currentPostCommentsList];
            const updatedPostList = trendingPostsList.map((post) => {
                if (post.id === currentCommentModal?.id) {
                    post.attributes.comments_count += 1;
                }
                return post;
            });
            this.setState({
                currentPostCommentsList: _.uniqBy(updatedCommentsList, "id"),
                trendingPostsList: updatedPostList,
            });
            this.scrollToTopOnAddComment();
        } else {
            helper.showErrorToast("Something went wrong! please try again");
        }
        this.setState({
            isLoadingComments: false,
        });
    };

    onDeleteComment = async (commentId: string | number) => {
        this.setState({
            isLoadingComments: true,
            currentSelectedCommentId: commentId,
        });
        const { selectedPost, token, currentCommentModal } = this.state;
        const type = selectedPost?.attributes?.event_id ? "event" : "group";
        const typeId = selectedPost?.attributes?.event_id || selectedPost?.attributes?.group_id || "";
        this.deletePostCommentApiCallId = await helper.apiCall({
            method: configJSON.deleteApiMethod,
            endPoint: `${configJSON.commentsApiEndpoint}/${commentId}?${this.getEndPointQuery(type)}=${typeId}&commentable_id=${currentCommentModal?.id}`,
            contentType: configJSON.validationApiContentType,
            token: token,
        });
    };

    handleDeleteCommentApiCall = (message: Message) => {
        const responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        if (responseJson && responseJson.message) {
            const { currentCommentModal, trendingPostsList, currentPostCommentsList, currentSelectedCommentId } = this.state;
            let updatedCommentsList = currentPostCommentsList.filter((item) => item.id !== currentSelectedCommentId);
            const updatedPostList = trendingPostsList.map((post) => {
                if (post.id === currentCommentModal?.id) {
                    post.attributes.comments_count -= 1;
                }
                return post;
            });

            this.setState({
                currentPostCommentsList: _.uniqBy(updatedCommentsList, "id"),
                trendingPostsList: updatedPostList,
            });
        } else {
            helper.showErrorToast("Something went wrong! please try again");
        }
        this.setState({
            isLoadingComments: false,
            currentSelectedCommentId: "",
        });
    };

    // Customizable Area End
}
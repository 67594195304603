// Customizable Area Start
import React from "react";
import AllPostsListController, { Props } from "./AllPostsListController";
import { Grid, styled } from "@material-ui/core";
import NavigationMenu from "../../navigationmenu/src/NavigationMenu.web";
import { PlusIcon } from "./assets";
import PostListCard from "./components/PostListCard.web";
import CustomNoDataComponent from "../../../components/src/CustomNoDataComponent.web";
import CreatePostModal from "../../postcreation/src/components/CreatePostModal.web";
import ConfirmationPopUp from "../../../components/src/ConfirmationPopUp.web";
import { IPost } from "../../../components/src/CommonTypes";
// Customizable Area End

export default class AllPostsList extends AllPostsListController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  PostList = () => {
    const {
      postsList,
      selectedPostDetail,
      userId,
      isEdit,
      confirmationPostDeletePopUpData,
      currentCommentList,
      likedUserList,
      isLoadingComments,
      isPostUploading,
      searchedMentionsList,
      postCommentsMetaData
    } = this.state;
    return (
      <StyledPostListContainer className="Post_List_Web_Container">
        <Grid container direction="row">
          <Grid item xs={12} sm={6}>
            <div
              data-test-id="post-list-back-btn"
              className="back-btn"
              onClick={() => this.props.navigation?.goBack()}
            >
              <svg
                width="8"
                height="12"
                viewBox="0 0 8 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5.99953 11.6553L0.345703 6.00148L5.99953 0.347656L7.05335 1.40148L2.45335 6.00148L7.05335 10.6015L5.99953 11.6553Z"
                  fill="white"
                />
              </svg>
              All Posts
            </div>
          </Grid>
          <Grid className="add-btn-grid-post" item xs={12} sm={6}>
            {
              this.showAddPostButton() && (
                <button
                  data-test-id="add-post-btn"
                  className="add-btn"
                  onClick={() => this.onOpenCreatePostModal()}
                >
                  <img src={PlusIcon} alt="" />
                  <p>Add Post</p>
                </button>
              )
            }
          </Grid>
        </Grid>
        <Grid container direction="row" spacing={2}>
          {postsList.length > 0 ? (
            postsList.map((post, index) => (
              <Grid key={post.id} className="post-detail-card" item xs={12}>
                <PostListCard 
                  handleFetchNextPage={this.handleChangePageNumber} 
                  isLastElement={index === postsList.length - 1} 
                  post={post} 
                  redirectTo={this.redirectTo}
                  userId={userId} 
                  onDeletePost={(title: string, id: string | number) => this.openPostDeleteModal(title, id)} 
                  onEditPost={(post: IPost) => this.onOpenEditPostModal(post)} 
                  onOpenCommentsModal={this.openCommentsModal}
                  comments={currentCommentList}
                  onLikePost={this.onLikePost}
                  setLikedUsers={this.setLikedUserList}
                  fetchLikedUsers={this.getLikedUsersList}
                  likedUserList={likedUserList}
                  onAddComment={this.onAddComment}
                  onDeleteComment={this.onDeleteComment}
                  onCloseCommentsModal={this.closeCommentsModal}
                  isLoadingComments={isLoadingComments}
                  handleFetchNextComments={this.handleChangeCommentsPageNumber}
                  mentionesUserList={searchedMentionsList}
                  fetchLikedUsersForComment={this.getCommentLikedUsersList}
                  onLikeGroupPostComment={this.onLikeComment}
                  commentsMetaData={postCommentsMetaData}
                  isPostCommentSectionOpen={post.attributes.show_comments_section}
                  handleReplySectionHide={this.handleHideReplySection}
                  handleReplySectionShow={this.handleShowReplySection}
                  handleUpdateRepliesCount={this.handleUpdatePostCommentsReplyCount}
                />
              </Grid>
            ))
          ) : (
            <CustomNoDataComponent
              titleMsg="No Posts"
              subTitleMsg="No posts available. Stay tuned for the updates."
            />
          )}
        </Grid>
        <CreatePostModal
          postDetails={selectedPostDetail}
          onCloseCreatePostModal={() => this.onCloseCreatePostModal()}
          isEditMode={isEdit}
          onSubmitPostDetails={this.onClickSharePost}
          isPostUploading={isPostUploading}
          onRemoveAttachmentFromPost={this.onRemoveAttachmentFromPost}
          handleUpdateFilesUploaded={(number) => this.setState({ numberOfFilesUploaded: number })}
        />
        <ConfirmationPopUp
          open={Boolean(confirmationPostDeletePopUpData)}
          header={confirmationPostDeletePopUpData?.header as string}
          message={confirmationPostDeletePopUpData?.message as string}
          onAccept={this.onAcceptPostDeleteConfirmation}
          onReject={this.onCloseDeleteConfirmationModal}
          onClose={this.onCloseDeleteConfirmationModal}
        />
      </StyledPostListContainer>
    );
  };

  // Customizable Area End

  render() {
    // Customizable Area Start

    return <NavigationMenu {...this.props} children={this.PostList()} />;
    // Customizable Area End
  }
}

// Customizable Area Start
const StyledPostListContainer = styled("div")({
  "&.Post_List_Web_Container": {
    display: "flex",
    flexDirection: "column",
    padding: "1rem 1rem",
    gap: "1.5rem",
    "& .ellipsis": {
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      overflow: "hidden",
    },
    "& .back-btn": {
      fontSize: "1.125rem",
      fontWeight: 500,
      color: "white",
      display: "flex",
      gap: "0.5rem",
      alignItems: "center",
      cursor: "pointer",
      width: "fit-content",
    },
    "& .upcoming-event-title": {
      fontSize: "16px",
      color: "#FFFFFF",
    },
    "& .add-btn-grid-post": {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
    },
    "& .add-btn": {
      background: "linear-gradient(180deg, #F37F78 0%, #F75367 100%)",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      cursor: "pointer",
      height: "3rem",
      padding: "0.75rem",
      border: "1px solid #F75367",
      gap: "0.25rem",
      borderRadius: "62.5rem",
      "& p": {
        color: "rgba(255, 255, 255, 1)",
        fontSize: "1rem",
        fontWeight: 500,
      },
    },
  },
});
// Customizable Area End

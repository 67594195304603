import React, { useState } from "react";

interface Props {
  className?: string;
  highResolutionImage?: string;
  lowResolutionImage?: string;
  alt?: string;
}


const LazyImageLoading: React.FC<Props> = (props: Props) => {
  const {
    className,
    highResolutionImage,
    lowResolutionImage,
    alt = ""
  } = props;
  const [loaded, setLoaded] = useState(false);

  return (
    <>
      {
        !loaded && (
          <img
            src={lowResolutionImage}
            alt={alt}
            className={className}
          />
        )
      }
      <img
        src={highResolutionImage}
        alt={alt}
        className={className}
        style={{ display: loaded ? "block" : "none" }}
        onLoad={()=> {
          setLoaded(true)
        }}
      />
    </>
  )
}

export default LazyImageLoading;
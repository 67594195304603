import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import _ from "lodash";
import { Appointment, UserContact } from "./AppointmentsController";
const { ApiCallFunction: apiCallFunction, rearrangeContacts } = require("./ApiCallFunction");
export const configJSON = require("./config.js");
import { getStorageData } from "../../../framework/src/Utilities";
import { ContactList, IAppointmentData, IGroupChat, AppointmentAttributes, IChatData } from "../../../components/src/CommonTypes";
const { HelperFunctions } = require("../../../components/src/HelperFunctions");
import { fetchContacts, 
  onSearchContact , 
  searchContactApiCallHandler,
  handleFetchContactsAPICall, handleFetchPhoneBookContactsAPICall, fetchPhoneBookContacts, 
  createChat, 
  handleEditGroupChat,
  handleAddMembersInGroupChat,
  deleteGroupChat,
  handleDeleteGroupChat,
  addMembersInGroupChat,
  editGroupChat} from "../../chat/src/actions";
import { CometChat } from "@cometchat-pro/chat";
// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export interface S {
  // Customizable Area Start
  id: string | number;
  token: string;
  appointment: Appointment | null;
  isRsvpStatusUpdateMode: boolean;
  invitedUserList: {
    owner_id:number;
    invited_users: UserContact[],
    invitation_members: {[key: string]: string}[]
  } | null;
  activeTab: string | null;
  optionAnchorEle: HTMLElement | null;
  isDeleteModalOpen: boolean;
  meetingTitle: string;
  chatList: IGroupChat[];
  openChatList: IGroupChat[];
  isChatListOpen: boolean;
  chatSearchString: string;
  isChatLoading: boolean;
  matchMedia: boolean;
  openChatCreateForm: boolean;
  contactList: ContactList[];
  phoneBookContactList: ContactList[];
  chatAppointment: AppointmentAttributes | null;
  selectedGroupChatForEdit: IChatData | null;
  editChatMode: boolean;
  isAddMemberToChatMode: boolean;
  selectedMembersForEditChat: ContactList[];
  selectedDeletedChatGuid: string;
  updateChildChatComponent?: any;
  registeredContactsPagination: {
    per_page: number,
    page_no: number,
    hasMore: boolean
  }
  callbackForContacts?: any;
  isLoadingContacts: boolean;
  // Customizable Area End
}

export interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class AppointmentDetailsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getAppointmentApiCallId?: string;
  rsvpStatusUpdateApiCallId?: string;
  deleteAppointmentApiCallId?: string;
  fetchChatsListApiCallId: string = "";
  fetchRegularContactsApiCallId: string = "";
  fetchPhonebookContactsApiCallId: string = "";
  searchContactApiCallId: string = "";
  createAppointmentChatApiCallId: string = "";
  deleteAppointmentChatApiCallId: string = "";
  editAppointmentChatApiCallId: string = "";
  addMemberInAppointmentChatApiCallId: string = "";
  mediaQueryForSmallMediumScreens = window.matchMedia("(max-width: 960px)");
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      id: 0,
      token: "",
      appointment: null,
      isRsvpStatusUpdateMode: false,
      invitedUserList: null,
      activeTab: null,
      optionAnchorEle: null,
      isDeleteModalOpen: false,
      meetingTitle: "",
      chatList: [],
      openChatList: [],
      isChatListOpen: false,
      chatSearchString: "",
      isChatLoading: false,
      matchMedia: this.mediaQueryForSmallMediumScreens.matches,
      openChatCreateForm: false,
      contactList: [],
      phoneBookContactList: [],
      chatAppointment: null,
      selectedGroupChatForEdit: null,
      editChatMode: false,
      isAddMemberToChatMode: false,
      selectedMembersForEditChat: [],
      selectedDeletedChatGuid: "",
      registeredContactsPagination: {
        per_page: 50,
        page_no: 1,
        hasMore: true
      },
      isLoadingContacts: false,
      // Customizable Area End
    };

    // Customizable Area Start
    this.handleMediaSizeChange = this.handleMediaSizeChange.bind(this);
    this.removeIdFromUrl = this.removeIdFromUrl.bind(this);
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    await super.componentDidMount();
    this.getToken();
    // Customizable Area Start
    this.setToken()
    document.addEventListener("click", this.closeOptionMenu, true);
    this.removeIdFromUrl();
    document.addEventListener('beforeunload', this.removeIdFromUrl);
    this.mediaQueryForSmallMediumScreens.addEventListener('change', this.handleMediaSizeChange);
    // Customizable Area End
  }

  getToken = () => {
    const message: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(message);
  };

  receive = async (from: string, message: Message) => {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const webApiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      switch (webApiRequestCallId) {
        case this.getAppointmentApiCallId:
          this.handleGetAppointmentCall(message);
          break;

        case this.rsvpStatusUpdateApiCallId:
          this.handleRsvpStatusUpdate(message);
          break;

        case this.deleteAppointmentApiCallId:
          this.handleDeleteAppointment(message);
          break;

        case this.fetchChatsListApiCallId:
          this.handleGetAppointmentChatsListCall(message);
          break;

        case this.fetchRegularContactsApiCallId:
          this.handleFetchContactsAPICall(message)
          break;

        case this.searchContactApiCallId:
          this.searchContactApiCallHandler(message)
          break;

        case this.createAppointmentChatApiCallId:
          this.onCreateChatApiHandler(message);
          break;

        case this.fetchPhonebookContactsApiCallId:
          this.handleFetchPhoneBookContactsApiCal(message);
          break;

        case this.editAppointmentChatApiCallId:
          this.handleEditGroupChatApiCall(message);
          break;

        case this.addMemberInAppointmentChatApiCallId:
          this.handleAddMemberApiCall(message);
          break;

        case this.deleteAppointmentChatApiCallId:
          this.handleOnDeleteGroupChat(message);
          break;
      }
    }
    // Customizable Area End
  };

  // Customizable Area Start
  async componentWillUnmount(): Promise<void> {
    document.removeEventListener("click", this.closeOptionMenu, true);
    document.removeEventListener('beforeunload', this.removeIdFromUrl);
    this.mediaQueryForSmallMediumScreens.removeEventListener('change', this.handleMediaSizeChange);
  }

  removeIdFromUrl = () => {
    const url = new URL(window.location.href);
    url.searchParams.delete('chat_id');
    window.history.pushState(null, '', url.toString());
  };

  handleMediaSizeChange = (e: { matches: boolean }) => {
    this.setState({ matchMedia: e.matches }, () => {
      if(this.state.matchMedia && this.state.openChatList.length >= 2) {
        this.setState({ openChatList: this.state.openChatList.splice(1)})
      }
    });
  }

  onChangeSearchHandler = (value: string) => {
    this.setState({
      chatSearchString: value
    })
  }

  toggleChatList = () => {
    this.setState({ isChatListOpen: !this.state.isChatListOpen })
  }

  openChatCreate = () => {
    this.setState({ openChatCreateForm: true })
    this.fetchPhoneBookContacts();
  }

  closeChatCreate = () => {
    this.setState({ 
      openChatCreateForm: false, 
      selectedGroupChatForEdit: null,
      editChatMode: false,
      isAddMemberToChatMode: false,
      selectedMembersForEditChat: [],
      contactList: [],
      phoneBookContactList: [],
      registeredContactsPagination: {
        per_page: 50,
        page_no: 1,
        hasMore: true
      }
    })
  }

  setOpenChat = (chatDetail: IGroupChat) => {
    const { openChatList } = this.state;
    let updatedOpenChatList = [ ...openChatList];
    const maxOpenChatLists = this.state.matchMedia ? 1 : 2;
    const findIndex = updatedOpenChatList.findIndex((item) => item.guid[0] === chatDetail.guid[0]);
    if(findIndex === -1) {
      if (openChatList.length >= maxOpenChatLists) {
        updatedOpenChatList.pop();
      } 
      updatedOpenChatList = [{ ...chatDetail}, ...updatedOpenChatList];
    }
    this.setState({ openChatList: _.uniqBy(updatedOpenChatList, "guid") })
  }

  setOpenSelectedChat = (chatGuid: string) => {
    const { openChatList } = this.state;
    let updatedOpenChatList = openChatList.map((item) => {
      if(item.guid[0] === chatGuid) {
        item.isOpen = true
      } else {
        item.isOpen = false
      }
      return item;
    })
    this.props.navigation.history.push(`${this.props.navigation.history.location.pathname}?chat_id=${chatGuid}`)
    this.setState({ openChatList: updatedOpenChatList })
  }

  setCloseSelectedChat = (chatGuid?: string) => {
    const { openChatList } = this.state;
    let updatedOpenChatList: IGroupChat[] = [];
    if(chatGuid) {
      updatedOpenChatList = openChatList.filter((item) => item.guid[0] !== chatGuid)
    } else {
      updatedOpenChatList = openChatList.filter((item) => !item.isOpen)
    }
    this.setState({ openChatList: updatedOpenChatList })
  }

  onSearchContact = async (contact: string) => {
    this.setState({ contactList: [], phoneBookContactList: [], isLoadingContacts: true })
    if (contact) {
      this.searchContactApiCallId = await onSearchContact({
        token: this.state.token,
        query: contact
      })
    } else {
      this.setState({
        registeredContactsPagination: {
          per_page: 50,
          page_no: 1,
          hasMore: true
        },
        contactList: [],
        phoneBookContactList: []
      })
      this.fetchPhoneBookContacts()
    }
  }

  searchContactApiCallHandler = (message: Message) => {
    const responseJson = searchContactApiCallHandler(message);
    this.setState({
      contactList: responseJson.data,
      phoneBookContactList: responseJson.phoneBookContactsList,
      isLoadingContacts: false,
    })
  }

  fetchContacts = async (callback?: any) => {
    this.setState({
      isLoadingContacts: true,
      callbackForContacts: callback
  })
    const {
      registeredContactsPagination,
      token
    } = this.state;

    this.fetchRegularContactsApiCallId = await fetchContacts({
      token,
      query: `?per_page=${registeredContactsPagination.per_page}&page_no=${registeredContactsPagination.page_no}`
    })
  }

  handleFetchContactsAPICall = async (message: Message) => {
    const responseJson = handleFetchContactsAPICall(message);
        this.setState(prevState => {
            return {
                contactList: [...prevState.contactList, ...responseJson.data]
            }
        })
        if(this.state.callbackForContacts){
            this.state.callbackForContacts();
        }

        this.setState(prevState => {
            return {
                isLoadingContacts: false,
                registeredContactsPagination: {
                    ...prevState.registeredContactsPagination,
                    page_no: prevState.registeredContactsPagination.page_no + 1,
                    hasMore: responseJson && responseJson.data && responseJson.data.length > 0 ? true : false
                }
            }
        })
  }

  fetchPhoneBookContacts = async () => {
    this.setState({
      isLoadingContacts: true
    })
    this.fetchPhonebookContactsApiCallId = await fetchPhoneBookContacts({
      token: this.state.token
    })
  }

  handleFetchPhoneBookContactsApiCal = (message: Message) => {
    const responseJson = handleFetchPhoneBookContactsAPICall(message);
    this.setState({
      phoneBookContactList: responseJson.data
    })
    this.fetchContacts();
  }

  onCreateAppointmentChat = async (body: any) => {
    HelperFunctions.showLoader();
    const { selectedGroupChatForEdit, editChatMode, isAddMemberToChatMode} = this.state;
    this.closeChatCreate();
    if (!editChatMode && !isAddMemberToChatMode) {
      this.createAppointmentChatApiCallId = await createChat({
        token: this.state.token,
        body
      })
    } else if (selectedGroupChatForEdit) {
      if (selectedGroupChatForEdit.group.data.name !== body.name) {
        this.editAppointmentChatApiCallId = await editGroupChat({
          token: this.state.token,
          body: {
            guid: selectedGroupChatForEdit.group.data.guid,
            name: body.name,
            type: "public"
          }
        })
      }

      if (isAddMemberToChatMode) {
        this.addMemberInAppointmentChatApiCallId = await addMembersInGroupChat({
          token: this.state.token,
          body: {
            guid: selectedGroupChatForEdit.group.data.guid,
            members: body.members.participants
          }
        })
      }
    }
  }

  onCreateChatApiHandler = (message: Message) => {
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (responseJson?.comet_response?.data) {
      const addedChatGrp: IGroupChat = {
        ...responseJson.comet_response.data,
        guid: [responseJson.comet_response.data?.guid],
        appointment: this.state.appointment,
      }
      HelperFunctions.showSuccessToast(responseJson.message)
      this.setState({ chatList: [...this.state.chatList, {...addedChatGrp}]})
    } else if (responseJson && responseJson.errors) {
      HelperFunctions.showErrorToast(responseJson.errors)
    } else {
      HelperFunctions.showErrorToast("Something went wrong please try again!")
    }
    HelperFunctions.hideLoader();
  }

  handleGetAppointmentChatsListCall = (message: Message) => {
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (responseJson?.chats) {
      const filteredData = responseJson.chats?.filter((item: any) => item.name)
      this.setState({
        chatList: filteredData
      })
    }
    this.setState({ isChatLoading: false })
  }

  getAppointmentChats = async () => {
    this.setState({ isChatLoading: true })
    this.fetchChatsListApiCallId = await apiCallFunction({
      method: "GET",
      endPoint: `${configJSON.chatsListingEndpoint}?appointment_id=${this.state.id}`,
      contentType: configJSON.appointmentApiContentType,
      token: this.state.token
    })
  }

  onClickAddMemberButton = (event: any) => {
    if (event && event.chatData) {
      this.setState({
        selectedGroupChatForEdit: event.chatData,
      })
      if (event.groupMembers) {
        const memberList: ContactList[] = [];
        event.groupMembers.forEach((groupMember: CometChat.GroupMember) => {
          if (groupMember.getScope() !== "admin") {
            const contact: ContactList = {
              id: parseInt(groupMember.getUid()),
              name: groupMember.getName(),
              email: "",
              full_phone_number: ""
            }
            memberList.push(contact);
          }
        })

        this.setState({
          selectedMembersForEditChat: memberList,
          isAddMemberToChatMode: true,
          openChatCreateForm: true,
        })
      }
      this.fetchPhoneBookContacts();
    }
  }

  onClickEditGroupChat = (event: any) => {
    if (event && event.chatData) {
      this.setState({
        selectedGroupChatForEdit: event.chatData,
        editChatMode: true,
      })
      if (event.groupMembers) {
        const contactList: ContactList[] = [];
        event.groupMembers.forEach((member: CometChat.GroupMember) => {
          if (member.getScope() !== "admin") {
            const contact: ContactList = {
              id: parseInt(member.getUid()),
              name: member.getName(),
              email: "",
              full_phone_number: ""
            }
            contactList.push(contact);
          }
        })

        this.setState({
          selectedMembersForEditChat: contactList,
          isAddMemberToChatMode: true,
          openChatCreateForm: true,
        })
      }
      this.fetchPhoneBookContacts();
    }
  }

  handleAddMemberApiCall = (message: Message) => {
    const addMemberResponse = handleAddMembersInGroupChat(message);
    if (addMemberResponse) {
      HelperFunctions.showSuccessToast(addMemberResponse.message)
      this.state.updateChildChatComponent?.()
    } else {
      HelperFunctions.showErrorToast("Something went wrong! Please try again")
    }
    this.closeChatCreate();
    HelperFunctions.hideLoader();
  }

  handleEditGroupChatApiCall = (message: Message) => {
    const response = handleEditGroupChat(message);
    if (response) {
      HelperFunctions.showSuccessToast(response.message);
      this.getAppointmentChats();
      this.state.updateChildChatComponent?.()
    } else {
      HelperFunctions.showErrorToast("Something went wrong! Please try again")
    }
    this.closeChatCreate();
    HelperFunctions.hideLoader();
  }

  onClickDeleteGroupChat = async (guid: string) => {
    HelperFunctions.showLoader();
    this.setState({ selectedDeletedChatGuid: guid }, async() => {
      this.deleteAppointmentChatApiCallId = await deleteGroupChat({
        token: this.state.token,
        body: {
          guid
        }
      })
    })
  }

  handleOnDeleteGroupChat = (message: Message) => {
    const response = handleDeleteGroupChat(message);
    if (response && response.message) {
      HelperFunctions.showSuccessToast(response.message)
      const chatList = this.state.chatList.filter((item) => item.guid[0] !== this.state.selectedDeletedChatGuid);
      const openChatList = this.state.openChatList.filter((item) => item.guid[0] !== this.state.selectedDeletedChatGuid);
      this.setState({ chatList, openChatList })
    } else {
      HelperFunctions.showErrorToast("Something went wrong! please try again")
    }
    HelperFunctions.hideLoader();
  }

  registerUpdateChatCallBack = (callback: any)=>{
    this.setState({
        updateChildChatComponent: callback
    })
}

  handleRsvpStatusUpdate = (message: Message) => {
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (responseJson && !responseJson.errors) {
      this.setState({
        isRsvpStatusUpdateMode: false
      }, () => {
        this.getAppointment(this.state.id)
      })
    } else {
      const toastMsg = responseJson.errors && responseJson.errors[0] && responseJson.errors[0].message ? responseJson.errors[0].message : "Something went wrong please try again!";
      HelperFunctions.showErrorToast(toastMsg);
    }
    HelperFunctions.hideLoader();
  }

  handleGetAppointmentCall = (message: Message) => {
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (responseJson && !responseJson.errors && responseJson.data) {
      const appointment: Appointment = responseJson.data;
      const chatAppointment: AppointmentAttributes = { ...appointment.attributes };
      if (appointment.attributes) {
        this.setState({
          appointment,
          chatAppointment
        })
      }
    } else {
      this.redirectTo('Appointments')
    }
    HelperFunctions.hideLoader();
  }

  handleDeleteAppointment = (message: Message) => {
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (responseJson && !responseJson.errors) {
      this.setState({
        isDeleteModalOpen: false
      }, () => {
        const toastMsg = "Appointment deleted successfully";
        HelperFunctions.showSuccessToast(toastMsg);
        this.redirectTo("Appointments")
      })
    } else {
      const toastMsg = responseJson.errors && responseJson.errors[0] && responseJson.errors[0].message ? responseJson.errors[0].message : "Something went wrong please try again!";
      HelperFunctions.showErrorToast(toastMsg);
    }
    HelperFunctions.hideLoader();
  }

  setToken = async () => {
    const token = await HelperFunctions.getStorageData("authToken");
    if (token) {
      this.setState({ token: token }, () => {
        const appointmentId = this.props.navigation.getParam("id");
        if (appointmentId) {
          this.setState({
            id: appointmentId
          }, () => {
            this.getAppointment(appointmentId);
            this.getAppointmentChats();
            this.fetchContacts();
          })
        }
      });
    } else {
      this.redirectTo("Appointments");
    }
  }

  setInvitedUsersForAppointmentDetails = (users:UserContact[], owner_id:number, invitation_members?:{[key: string]: string}[])=>{
    this.setState({
      invitedUserList: {
        owner_id,
        invited_users: rearrangeContacts(owner_id, users),
        invitation_members: invitation_members ? invitation_members : []
      },
      activeTab:null
    })
  }

  onUpdateRsvpStatus = async (status:string,appointmentId:string | number)=>{
    HelperFunctions.showLoader();
    const body = {
      status
    }
    this.rsvpStatusUpdateApiCallId = await apiCallFunction({
      method:"PATCH",
      endPoint:`bx_block_appointment_management/appointments/${appointmentId}/add_appointment_status`,
      contentType: configJSON.appointmentApiContentType,
      token: this.state.token,
      body
    })
  }

  onRsvpUpdateModeChange = ()=>{
    this.setState(prevState=>{
      return {
        isRsvpStatusUpdateMode: !prevState.isRsvpStatusUpdateMode
      }
    })
  }

  getAppointment = async (appointmentId:string | number) => {
    HelperFunctions.showLoader();
    this.getAppointmentApiCallId = await apiCallFunction({
      method: "GET",
      endPoint: `bx_block_appointment_management/appointments/${appointmentId}`,
      contentType: configJSON.appointmentApiContentType,
      token: this.state.token
    })
  }

  redirectTo = (endpoint: string, params?:{[key:string]:string | number}) => {
    this.props.navigation.navigate(endpoint, params)
  }

  closeUserListModal = ()=>{
    this.setState({
      invitedUserList: null,
      activeTab:null
    })
  }

  setActiveTab = (currentTab:string | null)=>{
    this.setState({
      activeTab: currentTab
    })
  }

  openOptionMenu = (event:React.MouseEvent<HTMLDivElement>)=>{
    this.setState({
      optionAnchorEle: event.currentTarget
    })
  }

  closeOptionMenu = (event:MouseEvent)=>{
    const menu = document.getElementById("options-menu");
    if (menu && !menu.contains(event.target as Node)) {
      this.setState({
        optionAnchorEle: null
      })
    }
  }

  openDeleteModal = (meetingTitle:string)=>{
    this.setState({
      isDeleteModalOpen: true,
      optionAnchorEle: null,
      meetingTitle
    })
  }

  closeDeleteModal = ()=>{
    this.setState({
      isDeleteModalOpen: false,
      meetingTitle: ""
    })
  }

  onDeleteAppointment = async ()=>{
    HelperFunctions.showLoader();
    this.deleteAppointmentApiCallId = await apiCallFunction({
      method:"DELETE",
      endPoint:`bx_block_appointment_management/appointments/${this.state.appointment?.id}`,
      contentType: configJSON.appointmentApiContentType,
      token: this.state.token
    })
  }

  goBack = ()=>{
    this.props.navigation.navigate("Appointments");
  }
  // Customizable Area End
}
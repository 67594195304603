import React from "react";
import { styled } from "@material-ui/styles";
import GroupCard from "./GroupCard.web";
import { IGroup } from "../types";
import { CardScroll } from "../../../../components/src";
import { Box } from "@material-ui/core";

interface Props {
    groups: IGroup[];
    onClick: (event: IGroup)=> void;
    selectedGroup: IGroup | null;
    isMyGroupsLoading?: boolean;
    handleFetchNextPageGroups?: () => void;
}


const GroupCardContainer: React.FC<Props> = (props: Props) => {
    const { groups, onClick, selectedGroup, isMyGroupsLoading, handleFetchNextPageGroups } = props;
    return (
        <Box id="my-groups-box" minHeight={isMyGroupsLoading ? "130px" : "fit-content"} position={"relative"}>
            {
                groups && groups.length > 0 && (
                    <StyledGroupCardContainer className="GroupCardContainer_StyledGroupCardContainer">
                        <p>My Groups</p>
                        <div className="groups">
                            <CardScroll>
                                {
                                    groups.map((group, index) => {
                                        return (
                                            <GroupCard isLastElement={index === groups.length - 1} selected={!!selectedGroup && selectedGroup.id === group.id} onClick={onClick} group={group} key={group.id} handleFetchNextPageGroups={handleFetchNextPageGroups} />
                                        )
                                    })
                                }
                            </CardScroll>
                        </div>
                    </StyledGroupCardContainer>
                )
            }
            {isMyGroupsLoading && groups.length ===0 &&
                <Box position={"absolute"} top={"15px"} display={"flex"}>
                   {
                     [...Array(2)].map((_, index) => (
                        <Box display={"flex"} flexDirection={"column"} gridGap={"8px"} padding={"10px"} id="my-groups-loader">
                            <Box height={"55px"} width={"55px"} className="shimmer shimmer-circle"></Box>
                            <Box height={"16px"} className="shimmer shimmer-text" width={"150px"}></Box>
                        </Box>))
                   }
                </Box>
            }
        </Box>
    )
}

const StyledGroupCardContainer = styled("div")({
  "&&.GroupCardContainer_StyledGroupCardContainer":{
    display: "flex",
    flexDirection: "column",
    gap: "1rem",
    "& > p":{
        fontSize: "0.75rem",
        fontWeight: 500,
        color: "rgba(255, 255, 255, 1)",
        marginLeft: "1rem"
    },
    "& .groups":{
        display: "flex",
        gap: "1rem",
        padding: "0rem 0.1rem",
    }
  }
})

export default GroupCardContainer;
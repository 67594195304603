// Customizable Area Start
import React from "react";
import {
    Grid,
    Typography,
    styled
} from "@material-ui/core";
import PostListCard from "../../../blocks/activityfeed/src/components/PostListCard.web";
import NavigationMenu from "../../navigationmenu/src/NavigationMenu.web";
import PostDetailComponentController, {
    Props
} from "./PostDetailComponentController";
import { CustomNoDataComponent } from "../../../components/src";
// Customizable Area End

export default class PostDetailComponent extends PostDetailComponentController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start

    PostDetailCard = () => {
        const { postDetail, currentPostLikedUserList, commentMentionUsersList, currentPostCommentMetaData, currentPostCommentsList, isLoadingComments, userId } = this.state;
        return (
            <StyledPostDetailComponent className="PostDetailComponent_WebContainer">
                <Grid id="post_detail_container_grid" container direction="row">
                    <Grid item xs={12}>
                        <Typography className="page-header" component={"p"}>Post Detail</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        {postDetail ?
                            <PostListCard
                                commentsMetaData={currentPostCommentMetaData}
                                post={postDetail}
                                redirectTo={this.redirectTo}
                                handleFetchNextPage={() => { }}
                                isLastElement={false}
                                fetchLikedUsers={this.getLikedUsersList}
                                likedUserList={currentPostLikedUserList}
                                setLikedUsers={this.setCurrentPostLikedUserList}
                                onLikePost={() => this.onLikeCurrentPost()}
                                onOpenCommentsModal={this.handleOpenPostCommentsSection}
                                isPostCommentSectionOpen={!!postDetail.attributes.show_comments_section}
                                onCloseCommentsModal={this.handleHidePostCommentsSection}
                                onAddComment={this.onAddPostComment}
                                onDeleteComment={(commentId: number | string) => this.onDeletePostComment(commentId)}
                                mentionesUserList={commentMentionUsersList}
                                handleFetchNextComments={() => this.handleChangePostCommentsPageNumber(postDetail.id)}
                                handleReplySectionHide={this.handleHideCommentReplySection}
                                handleReplySectionShow={this.handleShowCommentReplySection}
                                handleUpdateRepliesCount={this.handleUpdatePostCommentsReplyCount}
                                comments={currentPostCommentsList}
                                isLoadingComments={isLoadingComments}
                                userId={userId}
                                fetchLikedUsersForComment={this.getCommentLikedUsersList}
                                onLikeGroupPostComment={(commentId: number | string) => this.onLikePostComment(commentId)}
                            />
                            :
                            <CustomNoDataComponent titleMsg="No post detail." subTitleMsg="No such post detail available." />
                        }
                    </Grid>
                </Grid>
            </StyledPostDetailComponent>
        ) 
    }
    // Customizable Area End

    render() {
        // Customizable Area Start

        return (
            <NavigationMenu {...this.props} children={this.PostDetailCard()} />
        );
        // Customizable Area End
    }
}

// Customizable Area Start
const StyledPostDetailComponent = styled("div")({
    "&.PostDetailComponent_WebContainer": {
        flexDirection: "column",
        display: "flex",
        padding: "1rem 1rem",
        "& .page-header": {
            fontSize: "1.25rem",
            fontWeight: 500,
            lineHeight: "1.875rem",
            color: "rgba(255, 255, 255, 1)"
        },
        "& #post_detail_container_grid": {
            gap: "1.5rem"
        }
    }
})

// Customizable Area End

// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
export const configJSON = require("./config.js");
import { IAccount, IAddPost, IComment, IMentionInputType, IMetaPagination, IPost } from "../../../components/src/CommonTypes";
const { HelperFunctions: helper } = require("../../../components/src/HelperFunctions");
const { postListMock, profileDetailMock, commentsListMock } = require("../__tests__/testData");
// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation: any;
  id: string;
  // Customizable Area End
}

export interface S {
  // Customizable Area Start
  token: string | null;
  profileDetails: IAccount | null;
  postsList: IPost[];
  commentsList: IComment[];
  isCommentsLoading: boolean;
  mentionUsersList: IMentionInputType[];
  selectedPostDetail: IAddPost | null;
  userId: number | undefined;
  lastElementRef: React.RefObject<HTMLInputElement> | null;
  isEdit: boolean;
  confirmationCommunityPostDeletePopUpData: {
    header: string;
    message: string;
    type: string;
    eventId: string;
  } | null;
  perPage: number;
  page: number;
  isAllCommunityPostFetched: boolean;
  deletedPostId: string | number;
  currentLikedPostId: string;
  likedUserList: { id: string | number, attributes: { like_by_name: string} }[] | undefined;
  currentCommentModal: {
    id: string | number;
  } | null;
  commentsCurrentPage: number;
  isLoadingComments: boolean;
  isAllCommentsFetched: boolean;
  currentUploadingPostDetails: IAddPost | null;
  currentUploadingBlobIds: number[];
  isPostUploading: boolean;
  numberOfFilesUploaded: number;
  currentSelectedCommentId: string | number;
  searchedMentionsList: IMentionInputType[];
  postCommentsMetaData: IMetaPagination | null;
  currentLikedCommentId: string | number;
  openMoreMediaModal: boolean;
  selectedPostMedia: Array<{ id: number, url: string, blob_id?: number }>;
  selectedPostOwnerDetails: {name: string, profileUrl: string} | null;
  // Customizable Area End
}

export interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class RepliesListController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      token: "",
      profileDetails: profileDetailMock,
      postsList: postListMock,
      commentsList: commentsListMock,
      isCommentsLoading: false,
      mentionUsersList: [],
      selectedPostDetail: null,
      userId: undefined,
      lastElementRef: null,
      isEdit: false,
      confirmationCommunityPostDeletePopUpData: null,
      perPage: 3,
      page: 1,
      isAllCommunityPostFetched: false,
      deletedPostId: "",
      currentLikedPostId: "",
      likedUserList: undefined,
      currentCommentModal: null,
      commentsCurrentPage: 1,
      isLoadingComments: false,
      currentUploadingPostDetails: null,
      currentUploadingBlobIds: [],
      isPostUploading: false,
      numberOfFilesUploaded: 0,
      isAllCommentsFetched: false,
      currentSelectedCommentId: "",
      searchedMentionsList: [],
      postCommentsMetaData: null,
      currentLikedCommentId: "",
      openMoreMediaModal: false,
      selectedPostMedia: [],
      selectedPostOwnerDetails: null,
      // Customizable Area End
    };

    // Customizable Area Start

    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    await super.componentDidMount();
    this.getToken();
    // Customizable Area Start
    (async () => {
      const userData = await helper.getUserData();
      if (userData) {
        this.setState({ userId: userData.id });
      }
    })();
    // Customizable Area End
  }

  // Customizable Area Start

  getToken = () => {
    const message: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(message);
  };


  receive = async (from: string, message: Message) => {
    // Customizable Area Start
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      this.setToken();
    }

    // Customizable Area End
  };

  setToken = async () => {
    const token =  await helper.getStorageData("authToken");
    this.setState({ token });
  };

  openCommentsSection = async(postId: string) => {
    const postsList = this.state.postsList.map((item) => {
      if (item.id === postId) {
        item.attributes.show_comments_section = true
      } else {
        item.attributes.show_comments_section = false
      }
      return item;
    })    
    this.setState({
      postsList,
      currentCommentModal: {
        id: postId,
      }, commentsList: commentsListMock, commentsCurrentPage: 1, postCommentsMetaData: null
    })
  };

  closeCommentsSection = (postId: number | string) => {
    const postsList = this.state.postsList.map((item) => {
      if (item.id === postId) {
        item.attributes.show_comments_section = false
      }
      return item;
    })
    this.setState({postsList, currentCommentModal: null, commentsList: [], commentsCurrentPage: 1, postCommentsMetaData: null, isAllCommentsFetched: false, })
  };

  handleShowReplySection = (commentId: number | string) => {
    const updatedCommentsList = this.state.commentsList.map((item) => {
      if(item.attributes) {
        if (item.id === commentId) {
          item.attributes.show_replies_section = true
        } else {
          item.attributes.show_replies_section = false
        }
      }
      return item
    })
    this.setState({ commentsList: updatedCommentsList })
  }

  handleHideReplySection = (commentId: number | string) => {
    const updatedCommentsList = this.state.commentsList.map((item) => {
      if (item.id === commentId) {
        item.attributes.show_replies_section = false
      }
      return item
    })
    this.setState({ commentsList: updatedCommentsList })
  }

  setSelectedPostMedia = (postId: string) => {
    const { postsList } = this.state;
    const selectedPost = postsList.find((item) => item.id === postId);
    let media: Array<{ id: number, url: string, blob_id?: number }> = [];
    let ownerDetails: { name: string, profileUrl: string} | null = null;
    if(selectedPost) {
      media = [ ...selectedPost.attributes.images_and_videos];
      ownerDetails = {
        name: selectedPost.attributes.account_info.full_name,
        profileUrl: selectedPost.attributes.account_info.image_url || ""
      };
    } else {
      media = [];
      ownerDetails = null
    }
    
    this.setState({ selectedPostMedia: media, selectedPostOwnerDetails: ownerDetails }, () => {
      this.setState({ openMoreMediaModal: true })
    })
  }

  closeMoreMediaModal = () => {
    this.setState({ openMoreMediaModal: false, selectedPostMedia: [] })
  }

  // Customizable Area End
}

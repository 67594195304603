export const postListMock = [{
  "id": "486",
  "type": "post",
  "attributes": {
    "id": 486,
    "description": null,
    "body": "Crafted a user-centered UI/UX design for the ZetaHR dashboard, prioritizing simplicity, efficiency, and functionality. My approach focused on intuitive navigation, minimalistic design, and clean data visualization to enhance the user experience, automate payroll, and organized HR management. Each element was designed to optimize workflows and provide real-time insights, creating a smooth and engaging interface for HR professionals.",
    "account_id": 3,
    "created_at": "20 days ago",
    "updated_at": "2024-11-18T17:39:02.848+05:30",
    "event_id": null,
    "group_id": 309,
    "model_name": "BxBlockPosts::Post",
    "account_info": {
      "full_name": "Avesh Desai",
      "unique_auth_id": "KHsQisBoOHyp5HWPxkFBIwtt",
      "full_phone_number": "919090909070",
      "country_code": 91,
      "phone_number": 9090909070,
      "email": "aveshd@yopmail.com",
      "activated": true,
      "image_url": "https://images.pexels.com/photos/1097456/pexels-photo-1097456.jpeg?auto=compress&cs=tinysrgb&w=600",
      "location_access": false,
      "contact_list_access": false,
      "created_at": "2024-08-28T17:20:16.829+05:30",
      "interests": [{
        "id": "90",
        "type": "interests",
        "attributes": {
          "name": "Guitar",
          "created_at": "2024-09-10T18:02:57.511+05:30"
        }
      }, {
        "id": "91",
        "type": "interests",
        "attributes": {
          "name": "Playing",
          "created_at": "2024-09-10T18:02:57.511+05:30"
        }
      }]
    },
    "images_and_videos": [{
      "id": 520,
      "blob_id": 972,
      "url": "https://videos.pexels.com/video-files/5532772/5532772-uhd_2732_1440_25fps.mp4"
    }, {
      "id": 521,
      "blob_id": 973,
      "url": "https://images.pexels.com/photos/276267/pexels-photo-276267.jpeg?auto=compress&cs=tinysrgb&w=600"
    }, {
      "id": 522,
      "blob_id": 974,
      "url": "https://images.pexels.com/photos/276267/pexels-photo-276267.jpeg?auto=compress&cs=tinysrgb&w=600"
    }, {
      "id": 765,
      "blob_id": 1249,
      "url": "https://images.pexels.com/photos/256262/pexels-photo-256262.jpeg?auto=compress&cs=tinysrgb&w=600"
    }, {
      "id": 521,
      "blob_id": 972,
      "url": "https://images.pexels.com/photos/276267/pexels-photo-276267.jpeg?auto=compress&cs=tinysrgb&w=600"
    }],
    "total_likes": 0,
    "liked_by_me": false,
    "comments_count": 2,
    "trending_post_name": "Group pending"
  }
}];
export const profileDetailMock = {
  "id": "3",
  "type": "account",
  "attributes": {
    "activated": true,
    "country_code": "91",
    "email": "aveshd@yopmail.com",
    "first_name": null,
    "full_phone_number": "919090909070",
    "last_name": null,
    "phone_number": 9090909070,
    "type": "SmsAccount",
    "created_at": "2024-08-28T17:20:16.829+05:30",
    "updated_at": "2024-10-09T21:19:21.218+05:30",
    "device_id": null,
    "unique_auth_id": "KHsQisBoOHyp5HWPxkFBIwtt",
    "date_of_birth": "1981-08-26",
    "user_name": "aveshd2",
    "full_name": "Avesh Desai ",
    "interests": [{
      "id": 90,
      "created_at": "2024-09-10T18:02:57.511+05:30",
      "updated_at": "2024-09-10T18:02:57.511+05:30",
      "name": "Guitar",
      "role": "admin"
    }, {
      "id": 91,
      "created_at": "2024-09-10T18:02:57.511+05:30",
      "updated_at": "2024-09-10T18:02:57.511+05:30",
      "name": "Playing",
      "role": "admin"
    }],
    "image_url": "https://images.pexels.com/photos/1097456/pexels-photo-1097456.jpeg?auto=compress&cs=tinysrgb&w=600"
  }
};
export const commentsListMock = [{
  "id": "1121",
  "type": "comment",
  "attributes": {
    "id": 1121,
    "account_id": 3,
    "commentable_id": 486,
    "commentable_type": "BxBlockPosts::Post",
    "comment": "comment 2",
    "created_at": "2024-11-18T17:50:00.908+05:30",
    "updated_at": "2024-11-18T17:50:00.908+05:30",
    "account_info": {
      "full_name": "Avesh Desai ",
      "unique_auth_id": "KHsQisBoOHyp5HWPxkFBIwtt",
      "full_phone_number": "919090909070",
      "country_code": 91,
      "phone_number": 9090909070,
      "email": "aveshd@yopmail.com",
      "activated": true,
      "image_url": "",
      "location_access": false,
      "contact_list_access": false,
      "created_at": "2024-08-28T17:20:16.829+05:30",
      "interests": [{
        "id": "90",
        "type": "interests",
        "attributes": {
          "name": "Guitar",
          "created_at": "2024-09-10T18:02:57.511+05:30"
        }
      }]
    },
    "raw_created_at": "less than a minute ago",
    "reply_count": 0,
    "total_likes": 0,
    "liked_by_me": false
  }
}, {
  "id": "1120",
  "type": "comment",
  "attributes": {
    "id": 1120,
    "account_id": 3,
    "commentable_id": 486,
    "commentable_type": "BxBlockPosts::Post",
    "comment": "oo",
    "created_at": "2024-11-18T17:49:57.989+05:30",
    "updated_at": "2024-11-18T17:49:57.989+05:30",
    "account_info": {
      "full_name": "Avesh Desai ",
      "unique_auth_id": "KHsQisBoOHyp5HWPxkFBIwtt",
      "full_phone_number": "919090909070",
      "country_code": 91,
      "phone_number": 9090909070,
      "email": "aveshd@yopmail.com",
      "activated": true,
      "image_url": "",
      "location_access": false,
      "contact_list_access": false,
      "created_at": "2024-08-28T17:20:16.829+05:30",
      "interests": [{
        "id": "90",
        "type": "interests",
        "attributes": {
          "name": "Guitar",
          "created_at": "2024-09-10T18:02:57.511+05:30"
        }
      }]
    },
    "raw_created_at": "less than a minute ago",
    "reply_count": 0,
    "total_likes": 0,
    "liked_by_me": false
  }
}];
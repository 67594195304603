import React, { useCallback, useEffect, useState } from "react";
import { Modal, styled, Box, Typography, Grid } from "@material-ui/core";
import { addPhoto, closeIcon, pdfPlaceholder } from "../assets";
import { IAddPost } from "../../../../components/src/CommonTypes";
const { HelperFunctions: helper } = require("../../../../components/src/HelperFunctions");
import { ModalLoader } from "../../../../components/src";
import _ from "lodash";
import { v4 as uuidv4 } from "uuid";

interface CreatePostProps {
    postDetails: IAddPost | null,
    onCloseCreatePostModal: () => void;
    isEditMode?: boolean;
    onSubmitPostDetails: (postDetails: IAddPost) => void;
    isPostUploading?: boolean;
    handleUpdateFilesUploaded: (numberOfFiles: number) => void;
    onRemoveAttachmentFromPost?: (postId: number | string, attachmentBlobId: number) =>  void
}

const CreatePostModal: React.FC<CreatePostProps> = (props: CreatePostProps) => {
    const { postDetails, onCloseCreatePostModal, onSubmitPostDetails, isEditMode, isPostUploading, handleUpdateFilesUploaded, onRemoveAttachmentFromPost } = props;
    const [postImages, setPostImages] = useState<Array<{ selectedUrl: string, imgInvalid: boolean, type: string, blobId?: number, uniqId?: string | number | undefined }>>([]);
    const [postText, setPostText] = useState<string>("");
    const [uploadedFiles, setUploadedFiles] = useState<Array<{file: File, uniqId: string | number}>>([]);
    const [fileInvalidTextcolor, setFileInvalidTextColorColor] = useState('#ffffff7a');
    const [anyUpdateDone, setAnyUpdateDone] = useState<boolean>(false);
    const [numberOfFilesUploaded, setNumberOfFilesUploaded] = useState(0);
    const postImageInputRef = React.createRef<HTMLInputElement>();
    useEffect(() => {
        setPostText(postDetails?.postText || "")
        setPostImages(postDetails?.postImages || [])
        setNumberOfFilesUploaded(0)
    }, [postDetails])

    const onChangePostText = (value: string) => {
        setPostText(value)
        setAnyUpdateDone(true)
    }

    const changeColor = () => {
        setFileInvalidTextColorColor('red');
      };

    const onRemoveImage = (imgUniqId: number | string | undefined) => {
        const removedImg = postImages.find((item) => item.uniqId === imgUniqId)
        const updatedImageList = postImages.filter((item) => item.uniqId !== imgUniqId);
        const updatedUploadedFiles = uploadedFiles.filter((item) => item.uniqId !== imgUniqId);
        setPostImages(updatedImageList)
        setUploadedFiles(updatedUploadedFiles)
        if(numberOfFilesUploaded > 0) {
          setNumberOfFilesUploaded((number) => number - 1)
        }
        if (isEditMode && postDetails?.id) {
            if (onRemoveAttachmentFromPost && removedImg?.blobId) {
                onRemoveAttachmentFromPost(postDetails?.id, removedImg?.blobId)
            }
            setAnyUpdateDone(true)
        }
    }

    const onAddImage = useCallback(async (event: React.ChangeEvent<HTMLInputElement>) => {
        event.preventDefault();
        if (event.target.files?.length) {
            const files = event.target.files;
            const keys = Object.keys(files);
            const resultImageArr: Array<{ selectedUrl: string, imgInvalid: boolean, type: string, uniqId?: string | number | undefined }> = [...postImages];
            const filesUploaded: Array<{file: File, uniqId: string | number}> = [...uploadedFiles];
            const promises = keys?.map(async (key: any) => {
                const file = files[key];
                const uuid = uuidv4();
                const result = await helper.covertFileToObjectUrl(file);
                if (resultImageArr.length < 10) {
                    if (!result.imgInvalid) {
                        const resultObj = { ...result, uniqId: uuid}
                        resultImageArr.push(resultObj);
                        filesUploaded.push({file: file, uniqId: uuid});
                        setNumberOfFilesUploaded((number) => number + 1)
                        setFileInvalidTextColorColor('#ffffff7a');
                    } else {
                        changeColor()
                    }
                }
            })
            await Promise.allSettled(promises);
            const validImagesArr = resultImageArr.filter((item) => !item.imgInvalid).slice(0,10);
            setPostImages([...validImagesArr]);
            setUploadedFiles([...filesUploaded]);
            setAnyUpdateDone(true);
        }
    }, [postImages, uploadedFiles])

    const submitPost = () => {
        const fileList = uploadedFiles.map((item) => { return item.file })
        const post: IAddPost = {
            postImages: postImages,
            postText: postText || "",
            postImgFiles: fileList,
        };
        if (postDetails?.id) {
            post.id = postDetails.id
        }
        setAnyUpdateDone(false);
        setPostImages([]);
        setUploadedFiles([]);
        onSubmitPostDetails(post);
    }

    const isButtonDisabled = () => {
        if(isEditMode) {
            if(anyUpdateDone && postText?.trim()){
                return false;
            }
            return true;
        } else {
            if(postText?.trim()) {
                return false;
            }
            return true;
        }
    }

    useEffect(() => {
        handleUpdateFilesUploaded(numberOfFilesUploaded)
    }, [numberOfFilesUploaded])

    return (
        <Modal open={!!postDetails} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <StyledCreatePosts className="post-create-modal" id="post-create-modal">
                <Box className="post-creation-modal-header">
                    <p>{postDetails?.id ? "Edit Post" : "New Post"}</p>
                    <img src={closeIcon} alt="close.svg" onClick={()=>{
                        onCloseCreatePostModal();
                        setAnyUpdateDone(false);
                    }} />
                </Box>
                <Box className="post-creation-modal-body">
                    <Box>
                        <textarea
                            placeholder="Share a message with guests..."
                            value={postText}
                            className="post-text-area"
                            maxLength={2000}
                            onChange={(event) => {
                                const sanitizedValue = event.target.value.replace(/^\s+/, '');
                                onChangePostText(sanitizedValue)
                            }} />
                    </Box>
                    <Box marginTop={"15px"} className="flex-display">
                        <Box id="post-file" onClick={() => {
                            if (postImages.length < 10) {
                                postImageInputRef?.current?.click()
                            }
                        }} className="upload-pic-btn" width={"80%"} position={"relative"}>
                            <img src={addPhoto} alt="add photo" />
                            <Typography className="upload-pic" component={"span"}>Upload Files</Typography>
                            <input
                                className="photo-input"
                                type="file"
                                ref={postImageInputRef}
                                onChange={onAddImage}
                                multiple
                                accept="image/png, image/jpg, image/jpeg, application/pdf, video/*"
                            />
                        </Box>
                        <Box color={fileInvalidTextcolor}>
                            <Typography className="files-invalid-msg" component={"span"}>(Please ensure files are JPG, PNG, or PDF and under 2 MB, with videos up to 10 MB.)</Typography>
                        </Box>
                    </Box>
                    <Grid container spacing={3} className="flex-display post-images-grid">
                        {postImages?.length > 0 && postImages.map((item, index) => {
                            return (
                                <Grid key={item.selectedUrl} className="post-image-grid" item xs={4}>
                                    {item.type.includes("image") && <img className="post-img" src={item.selectedUrl} alt="uploaded-post" />}
                                    {item.type.includes("pdf") && <img className="post-img" src={pdfPlaceholder} alt="uploaded-post" />}
                                    {item.type.includes("video") && <video controls controlsList="nodownload" className="post-img" src={item.selectedUrl} />}
                                    <Box className="remove-post-img-icon" onClick={() => onRemoveImage(item.uniqId)} position={"absolute"} top={"15px"} right={"20px"}>
                                        <img src={closeIcon} />
                                    </Box>
                                </Grid>
                            )
                        }
                        )}
                    </Grid>
                </Box>
                <Box className="post-share-box">
                    <button disabled={isButtonDisabled()} style={{
                        opacity: isButtonDisabled() ? 0.4 : 1
                    }} className="post-share-btn" onClick={() => submitPost()}>
                        <p>Share</p>
                    </button>
                </Box>
                <ModalLoader isLoading={isPostUploading} />
            </StyledCreatePosts>
        </Modal>
    )
}

const StyledCreatePosts = styled("div")({
    "&.post-create-modal": {
        display: "flex",
        flexDirection: "column",
        maxWidth: "95%",
        width: "420px",
        height: "540px",
        backgroundColor: "#23404B",
        maxHeight: "80%",
        boxShadow: "0px 0px 20px 0px #00000040",
        borderRadius: "1rem",
        position: "relative",
        "& .post-creation-modal-header": {
            position: "relative",
            display: "flex",
            padding: "1rem",
            justifyContent: "center",
            alignItems: "center",
            "& p": {
                margin: 0,
                fontSize: "1.125rem",
                fontWeight: 500,
                color: "#ffffff"
            },
            "& img": {
                position: "absolute",
                right: "1rem",
                top: "50%",
                transform: "translate(-50%,-50%)",
                cursor: "pointer"
            },
        },
        "& .post-creation-modal-body": {
            flex: 1,
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
            overflowY: "auto",
            padding: "10px 25px",
            "& .post-text-area": {
                height: "145px !important",
                width: "100%",
                padding: "12px",
                fontSize: "14px",
                borderRadius: "12px",
                background: "rgba(0, 0, 0, 0.25)",
                border: "none",
                color: "rgb(255, 255, 255)",
                resize: "none",
                '&::placeholder': {
                    color: "rgba(255, 255, 255, 0.8)"
                },
                "&:focus": {
                    border: "none",
                    outline: 0,
                }
            },
            "& .photo-input": {
                visibility: 'hidden',
                position: "absolute",
                left: "0px",
            },
            "& .upload-pic-btn": {
                cursor: "pointer"
            },
            "& .upload-pic": {
                color: "rgba(255, 255, 255, 1)",
                fontSize: "16px",
                marginLeft: "10px",
            },
            "& .post-images-grid": {
                overflowY: "auto",
                maxHeight: "200px",
                "& .post-image-grid": {
                    position: "relative"
                },
                "& .post-img": {
                    width: "104px",
                    height: "105px",
                    objectFit: "cover",
                    borderRadius: "16px",
                },
                "& .remove-post-img-icon": {
                    background: "linear-gradient(180deg, #F37F78 0%, #F75367 100%)",
                    width: "1.25rem",
                    height: "1.25rem",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    borderRadius: "100%",
                    cursor: "pointer",
                    "& img": {
                        width: "6px",
                        height: "6px"
                    }
                }
            },
            "& .files-invalid-msg": {
                fontSize: "10px",
                fontWeight: 500,
            },
        },
        "& .post-share-box": {
            borderTop: "1px solid #2d4953",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: "15px 25px",
            "& button": {
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "3rem",
                padding: "0.75rem",
                gap: "0.25rem",
                borderRadius: "62.5rem",
                background: "transparent",
                outline: "none",
                border: "none",
                cursor: "pointer",
            },
            "& .post-share-btn": {
                background: "linear-gradient(180deg, #F37F78 0%, #F75367 100%)",
                width: "100%",
                "& p": {
                    color: "rgba(255, 255, 255, 1)",
                    fontSize: "16px",
                    fontWeight: 500
                }
            },
        },
        "& .flex-display": {
            display: "flex",
            alignItems: "center"
        },
    }
})

export default CreatePostModal;
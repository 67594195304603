// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
export const configJSON = require("./config.js");
const {
    HelperFunctions: helper,
} = require("../../../components/src/HelperFunctions");
import { CometChat } from '@cometchat-pro/chat';
import { IChatData } from "../../../components/src/CommonTypes";
// Customizable Area End

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    closeInfo: ()=> void;
    group?: CometChat.Group | null;
    groupMembers?: CometChat.GroupMember[];
    isFromAppointmentOrEvent?: boolean;
    closeChatRoom?: () => void;
    onClickAddMemberButton?: (event: any)=> void;
    onClickEditButton?: (event: any)=> void;
    chatData?: IChatData | null;
    onDeleteGroupChat?: (event: string)=> void;
    // Customizable Area End
}

export interface S {
    // Customizable Area Start
    token: string | null;
    confirmationPopUpData: {
        header: string;
        message: string;
        type: string;
        guid: string;
    } | null;
    uid: string;
    // Customizable Area End
}

export interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class GroupChatInfoController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            // Customizable Area Start
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionResponseMessage),
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            token: "",
            confirmationPopUpData: null,
            uid: ""
            // Customizable Area End
        };

        // Customizable Area Start
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async componentDidMount() {
        await super.componentDidMount();
        this.getToken();
        // Customizable Area Start
        const userData = await helper.getUserData();
        if(userData){
            this.setState({
                uid: `${userData.id}`
            })
        }
        // Customizable Area End
    }

    getToken = async () => {
        const AuthToken = await helper.getStorageData("authToken");
        this.setState({
            token: AuthToken
        })
    };

    receive = async (from: string, message: Message) => {
        // Customizable Area Start
        // Customizable Area End
    };

    // Customizable Area Start
    openDeleteModal = (title: string, guid?: string) => {
        this.setState({
            confirmationPopUpData: {
                header: "Delete Chat?",
                type: "delete",
                message: `Are you sure that you want to delete ${title}?`,
                guid: guid as string
            }
        })
    }

    onCloseConfirmationModal = () => {
        this.setState({
            confirmationPopUpData: null
        })
    }

    onAcceptConfirmation = async () => {
        if (this.state.confirmationPopUpData) {
            const { type, guid } = this.state.confirmationPopUpData;
            if (type === "delete") {
                if(this.props.onDeleteGroupChat){
                    this.props.onDeleteGroupChat(guid)
                }
                this.setState({
                    confirmationPopUpData: null
                })
            }
        }
    }
    // Customizable Area End
}
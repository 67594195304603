// Customizable Area Start
import React from "react";
import CommunityForumProfileController, { Props } from "./CommunityForumProfileController";
import { Box, styled, Grid } from "@material-ui/core";
import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonOutlineOutlined';
import NavigationMenu from "../../navigationmenu/src/NavigationMenu.web";
import { Button, CustomNoDataComponent } from "../../../components/src";
import PostListCard from "../../activityfeed/src/components/PostListCard.web";
import MorePostMediaModal from "./components/MorePostMediaModal.web";

// Customizable Area End

export default class CommunityForumProfile extends CommunityForumProfileController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    ProfileDetails = () => {
        const { profileDetails } = this.state;
        return (
            <StyledProfileDetail className="Profile_Detail">
                <Grid container direction="row">
                    <Grid item xs={12} sm={6} md={6} lg={2}>
                        <Box className="profile_image">
                            {profileDetails?.attributes.image_url ? <img src={profileDetails.attributes.image_url} alt="Profile" className="" />
                                :
                                <PersonOutlineOutlinedIcon style={{ fill: "white", width: "3rem", height: "3rem" }} />
                            }
                        </Box>
                    </Grid>
                    <Grid className="detail_main_grid" item xs={12} sm={6} md={6} lg={8}>
                        <Box className="details">
                            <Box className="detail_col">
                                <Box className="item">
                                    <label>Full Name</label>
                                    <p>{profileDetails?.attributes?.full_name}</p>
                                </Box>
                                <Box className="item">
                                    <label>Interest</label>
                                    <p>{profileDetails?.attributes.interests.map(interest => interest.name).join(", ")}</p>
                                </Box>
                            </Box>
                            <Box className="detail_col">
                                <Box className="item">
                                    <label>User Name</label>
                                    <p>{profileDetails?.attributes?.user_name}</p>
                                </Box>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid className="follow_btn_grid" item xs={12} sm={12} md={12} lg={2}>
                        <Button buttonStyles={{ width: "132px" }} text="Follow" type="default" />
                    </Grid>
                </Grid>
            </StyledProfileDetail>
        )
    };

    CommunityForumPostList = () => {
        const { postsList, commentsList, isCommentsLoading, userId } = this.state;
        return (
            <StyledCommunityForumPostList className="Community_Forum_PostList_Web">
                <Grid spacing={2} container direction="row">
                    {postsList.length > 0 ? (
                        postsList.map((post, index) => (
                            <Grid key={post.id} className="community-post-detail-card" item xs={12}>
                                <PostListCard
                                    isFromCommunityPost
                                    handleFetchNextPage={() => { }}
                                    isLastElement={index === postsList.length - 1}
                                    post={post}
                                    userId={userId}
                                    onOpenCommentsModal={this.openCommentsSection}
                                    comments={commentsList}
                                    onLikePost={() => { }}
                                    setLikedUsers={() => { }}
                                    fetchLikedUsers={() => { }}
                                    likedUserList={undefined}
                                    onAddComment={() => { }}
                                    onDeleteComment={() => { }}
                                    onCloseCommentsModal={this.closeCommentsSection}
                                    isLoadingComments={isCommentsLoading}
                                    handleFetchNextComments={() => { }}
                                    mentionesUserList={[]}
                                    fetchLikedUsersForComment={() => { }}
                                    onLikeGroupPostComment={() => { }}
                                    commentsMetaData={null}
                                    isPostCommentSectionOpen={post.attributes.show_comments_section}
                                    handleReplySectionHide={this.handleHideReplySection}
                                    handleReplySectionShow={this.handleShowReplySection}
                                    handleUpdateRepliesCount={() => { }}
                                    openMoreMediaPopup={this.setSelectedPostMedia}
                                />
                            </Grid>
                        ))
                    ) : (
                        <CustomNoDataComponent
                            titleMsg="No Posts"
                            subTitleMsg="No posts available. Stay tuned for the updates."
                        />
                    )}
                </Grid>
            </StyledCommunityForumPostList>
        )
    }

    CommunityForumProfile = () => {
        const { openMoreMediaModal, selectedPostMedia, selectedPostOwnerDetails } = this.state;
        return (
            <StyledCommunityForumProfile className="Community_Forum_Profile_Web">
                <Grid container direction="row">
                    <Grid item xs={12}>
                        <div
                            data-test-id="community-profile-back-btn"
                            className="back-btn"
                            onClick={() => this.props.navigation?.goBack()}
                        >
                            <svg
                                id="back-btn"
                                width="8"
                                height="12"
                                viewBox="0 0 8 12"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M5.99953 11.6553L0.345703 6.00148L5.99953 0.347656L7.05335 1.40148L2.45335 6.00148L7.05335 10.6015L5.99953 11.6553Z"
                                    fill="white"
                                />
                            </svg>
                            Profile
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                        {this.ProfileDetails()}
                    </Grid>
                    {this.CommunityForumPostList()}
                </Grid>
                <MorePostMediaModal
                    open={openMoreMediaModal}
                    communityPostMedia={selectedPostMedia}
                    postOwnerDetails={selectedPostOwnerDetails}
                    onClose={this.closeMoreMediaModal}
                 />
            </StyledCommunityForumProfile>
        )
    }

    // Customizable Area End

    render() {
        // Customizable Area Start
        return <NavigationMenu {...this.props} children={this.CommunityForumProfile()} />;
        // Customizable Area End
    }
}

// Customizable Area Start
const StyledCommunityForumProfile = styled("div")({
    "&.Community_Forum_Profile_Web": {
        display: "flex",
        flexDirection: "column",
        padding: "1rem 1rem",
        gap: "1.5rem",
        "& .back-btn": {
            fontSize: "1.125rem",
            fontWeight: 500,
            color: "white",
            display: "flex",
            gap: "1rem",
            alignItems: "center",
            cursor: "pointer",
            width: "fit-content",
        },
    }
})

const StyledProfileDetail = styled("div")({
    "&.Profile_Detail": {
        width: "100%",
        boxSizing: "border-box",
        rowGap: "1rem",
        columnGap: "1rem",
        background: "linear-gradient(180deg, #23404B 0%, #19282F 100%)",
        borderRadius: "1rem",
        padding: "1rem 2rem",
        marginTop: "2rem",
        "& .profile_image": {
            width: "10.25rem",
            height: "10.25rem",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            background: "linear-gradient(180deg, #2e5462 0%, #19282F 100%)",
            borderRadius: "1rem",
            "& > img": {
                width: "100%",
                height: "100%",
                objectFit: "cover",
                borderRadius: "1rem"
            }
        },
        "& .detail_main_grid": {
            display: "flex",
            alignItems: "center",
            "& .details": {
                display: "grid",
                gridTemplateColumns: "repeat(3, 1fr)",
                width: "100%",
                boxSizing: "border-box",
                columnGap: "1rem",
                rowGap: "1rem",
                padding: "1rem 2rem",
                "& > .detail_col": {
                    display: "flex",
                    flexDirection: "column",
                    gap: "1rem",
                    "& > .item": {
                        gap: "8px",
                        display: "flex",
                        flexDirection: "column",
                        "& > label": {
                            fontSize: "16px",
                            fontWeight: 500,
                            color: "rgba(255, 255, 255, 1)"
                        },
                        "& > p": {
                            fontSize: "14px",
                            fontWeight: 400,
                            color: "rgba(255, 255, 255, 0.8)",
                        }
                    }
                },
                [`@media screen and (max-width: 900px)`]: {
                    gridTemplateColumns: "repeat(1, 1fr)",
                },
            }
        },
        "& .follow_btn_grid": {
            display: "flex",
            alignItems: "flex-start",
            justifyContent: "flex-end",
            [`@media screen and (max-width: 900px)`]: {
                justifyContent: "flex-start",
            },
        }
    }
})

const StyledCommunityForumPostList = styled("div")({
    "&.Community_Forum_PostList_Web": {
        marginTop: "16px",
        width: "100%",
    }
})
// Customizable Area End
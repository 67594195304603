import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import moment, { Moment } from "moment";
import { TimeSlot } from "./types";
import { getStorageData } from "../../../framework/src/Utilities";
import MomentUtils from "@date-io/moment";
import { EmailList } from "../../../components/src/CommonTypes";
const { ApiCallFunction: apiCallFunction, rearrangeContacts } = require("./ApiCallFunction");
const { HelperFunctions } = require("../../../components/src/HelperFunctions");
export interface UserContact {
  id:number;
  name:string;
  rsvp_status:string | null;
  full_phone_number:string;
  invited_by?: string | null;
  email:string;
  image_url?: string;
}
export interface AppointmentAttributes {
  title: string;
  location: {
    [key:string]:string
  };
  appointment_date: string;
  start_time: string;
  end_time: string;
  description: string;
  meeting_type: string;
  meeting_link:string;
  reminder_frequency: string;
  account_id: number;
  status: string;
  created_by: string;
  image_url: string | null;
  assignee_email: EmailList[];
  user_contacts: UserContact[];
  rsvp_status: string | null,
  invitation_members?: {[key: string]: string}[]
}

export interface Appointment {
  id: string;
  type: string;
  attributes: AppointmentAttributes;
}
// Customizable Area End

export const configJSON = require("./config.js");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export interface S {
  // Customizable Area Start
  available_date: Moment;
  start_time: Moment | null;
  end_time: Moment | null;
  id: string | number;
  token: string;
  appointmentsList: TimeSlot[];
  upcomingAppointmentList: Appointment[],
  nextAppointment:Appointment[];
  showPicker: boolean;
  itemsPerPage:number;
  currentPage:number;
  totalPages:number;
  anchorOptionMenu: HTMLElement | null;
  currentAnchorOptionMenu: number | null;
  isRsvpStatusUpdateMode: boolean;
  invitedUserList:{
    owner_id:number,
    invited_users:UserContact[],
    invitation_members: {[key: string]: string}[]
  } | null;
  activeTab: string | null;
  anchorPagesMenu: HTMLElement | null;
  confirmationPopUpData: {
    header: string;
    message: string;
    type: string;
    appointmentId: string;
  } | null;
  userId: number | null;
  // Customizable Area End
}

export interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class AppointmentsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getAppointmentsListApiCallId?: string;
  getNextAppointmentApiCallId?: string;
  deleteAppointmentApiCallId?: string;
  rsvpStatusUpdateApiCallId?: string;

  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];

    let endTime = new Date();
    endTime.setMinutes(endTime.getMinutes() + 30);

    this.state = {
      // Customizable Area Start
      id: 0,
      start_time: moment(new Date()),
      end_time: moment(endTime),
      available_date: moment(new Date()),
      appointmentsList: [],
      token: "",
      showPicker: false,
      itemsPerPage: 4,
      currentPage: 1,
      totalPages: 30,
      upcomingAppointmentList:[],
      nextAppointment: [],
      anchorOptionMenu: null,
      currentAnchorOptionMenu: null,
      isRsvpStatusUpdateMode: false,
      invitedUserList: null,
      activeTab: null,
      anchorPagesMenu: null,
      confirmationPopUpData: null,
      userId: null
      // Customizable Area End
    };

    // Customizable Area Start
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    await super.componentDidMount();
    this.getToken();
    if (!this.isPlatformWeb()) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
    // Customizable Area Start
    document.addEventListener("click", this.closeOptionMenu, true);
    (async () => {
      const userData = await HelperFunctions.getUserData();
      if (userData) {
        this.setState({userId: userData.id});
      }
    })();
    // Customizable Area End
  }

  getToken = () => {
    const message: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(message);
  };

  receive = async (from: String, message: Message) => {
    // Customizable Area Start
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let token = message.getData(getName(MessageEnum.SessionResponseToken));
      if(!token){
        token = await getStorageData('authToken');
      }
      if(!token){
        this.redirectTo("EmailAccountLoginBlock");
      }
      this.setState({
        token
      },()=>{
        this.getAppointmentList();
        this.getNextAppointment()
      })
    }

    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getAppointmentsListApiCallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      this.handleUpcomingAppointmentCall(message)
    }else if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getNextAppointmentApiCallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      this.handleNextAppointmentCall(message)
    }else if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.deleteAppointmentApiCallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      this.handleDeleteAppointment(message)
    }else if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.rsvpStatusUpdateApiCallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      this.handleRsvpStatusUpdate(message)
    }
    // Customizable Area End
  };

  // Customizable Area Start

  async componentWillUnmount(): Promise<void> {
    document.removeEventListener("click", this.closeOptionMenu, true);
  }

  handleUpcomingAppointmentCall = (message:Message)=>{
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (responseJson && !responseJson.errors && responseJson.data) {
      this.setState({
        upcomingAppointmentList: responseJson.data,
      });
      const { total_pages } = responseJson.meta;
      this.setState({
        totalPages:total_pages,
      })
    } else {
      this.setState({ upcomingAppointmentList: [] });
    }
    HelperFunctions.hideLoader();
  }

  handleNextAppointmentCall = (message:Message)=>{
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    
    if (responseJson && !responseJson.errors && responseJson.data && responseJson.data.length > 0) {
      this.setState({
        nextAppointment: responseJson.data,
      });
    } else {
      this.setState({ nextAppointment: [] });
    }
    HelperFunctions.hideLoader();
  }

  handleDeleteAppointment = (message:Message)=>{
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    
    if (responseJson && !responseJson.errors) {
      this.getAppointmentList()
      this.getNextAppointment()
      this.setState({
        anchorOptionMenu: null,
        currentAnchorOptionMenu: null,
        confirmationPopUpData: null
      })
      const toastMsg = "Appointment deleted successfully";
      HelperFunctions.showSuccessToast(toastMsg);
    }else{
      const toastMsg = responseJson.errors && responseJson.errors[0] && responseJson.errors[0].message ? responseJson.errors[0].message : "Something went wrong please try again!";
      HelperFunctions.showErrorToast(toastMsg);
    }
    HelperFunctions.hideLoader();
  }

  handleRsvpStatusUpdate= (message:Message)=>{
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    
    if (responseJson && !responseJson.errors) {
      this.setState({
        isRsvpStatusUpdateMode: false
      },()=>{
        this.getAppointmentList()
        this.getNextAppointment()
      })
    }else{
      const toastMsg = responseJson.errors && responseJson.errors[0] && responseJson.errors[0].message ? responseJson.errors[0].message : "Something went wrong please try again!";
      HelperFunctions.showErrorToast(toastMsg);
    }
    HelperFunctions.hideLoader();
  }

  getAppointmentList = async () => {
    HelperFunctions.showLoader();
    this.getAppointmentsListApiCallId = await apiCallFunction({
      method:"GET",
      endPoint:`bx_block_appointment_management/upcoming_appointments_list?page_no=${this.state.currentPage}&per_page=${this.state.itemsPerPage}`,
      contentType: configJSON.appointmentApiContentType,
      token:this.state.token
    })
  };

  getNextAppointment = async () => {
    HelperFunctions.showLoader();
    this.getNextAppointmentApiCallId = await apiCallFunction({
      method:"GET",
      endPoint:`bx_block_appointment_management/appointments/ongoing_appointment`,
      contentType: configJSON.appointmentApiContentType,
      token: this.state.token
    })
  };

  update(value: Partial<{ [K in keyof S]: S[K] }>) {
    this.setState((state) => ({ ...state, ...value, showPicker: false }));
  }

  getDate(value: string, format: string) {
    return moment(value).format(format);
  }

  toMomentDate(value?: string | Date | null, format?: string) {
    return moment(value, format);
  }

  toDateFromMoment(value: Moment) {
    return value.toDate();
  }

  calculatePages = ()=>{
    const pages:number[] = [];
    const { currentPage, totalPages } = this.state;
    pages.push(currentPage);
    for(let pageNo=currentPage+1;pageNo <= totalPages && pages.length < 4; pageNo++){
      pages.push(pageNo)
    }
    if(pages.length < 4){
      for(let pageNo=currentPage-1;pageNo >= 1 && pages.length < 4; pageNo--){
        pages.unshift(pageNo)
      }
    }
    return pages;
  }

  setCurrentPage = (currentPage: number)=>{
    this.setState({
      currentPage
    },()=>{
      this.getAppointmentList()
    })
  }

  nextPage = ()=>{
    const { currentPage, totalPages } = this.state;
    if(currentPage < totalPages){
      this.setCurrentPage(currentPage+1)
    }
  }

  prevPage = ()=>{
    const { currentPage } = this.state;
    if(currentPage - 1 >= 1){
      this.setCurrentPage(currentPage-1)
    }
  }

  formateDate = (value:string, format:string)=>{
    const moment = new MomentUtils();
    const formattedDate = moment.date(value).format(format)
    return formattedDate;
  }

  openOptionMenu = (event:React.MouseEvent<HTMLDivElement>, currentIndex: number | null)=>{
    this.setState({
      anchorOptionMenu: event.currentTarget,
      currentAnchorOptionMenu: currentIndex
    })
  }

  openPagesMenu = (event:React.MouseEvent<HTMLDivElement>)=>{
    this.setState({
      anchorPagesMenu: event.currentTarget
    })
  }

  closeOptionMenu = (event:MouseEvent)=>{
    const menu = document.getElementById("options-menu");
    if (menu && !menu.contains(event.target as Node)) {
      this.setState({
        anchorOptionMenu:null,
        currentAnchorOptionMenu: null
      })
    }

    const pagesMenu = document.getElementById("pages-menu");
    if (pagesMenu && !pagesMenu.contains(event.target as Node)) {
      this.setState({
        anchorPagesMenu: null
      })
    }
  }

  onDeleteAppointment = async (title:string, appointmentId: string) => {
    this.setState({
      anchorOptionMenu: null,
      currentAnchorOptionMenu: null
    })
    this.openDeleteModal(title, appointmentId)
  }

  onEditAppointment = async (appointmentId: string) =>{
    this.props.navigation.navigate("EditAppointment",{id:appointmentId})
  }
  
  onRsvpUpdateModeChange = ()=>{
    this.setState(prevState=>{
      return {
        isRsvpStatusUpdateMode: !prevState.isRsvpStatusUpdateMode
      }
    })
  }

  onUpdateRsvpStatus = async (status:string,appointmentId:string | number)=>{
    const body = {
      status
    }
    HelperFunctions.showLoader();
    this.rsvpStatusUpdateApiCallId = await apiCallFunction({
      method:"PATCH",
      endPoint:`bx_block_appointment_management/appointments/${appointmentId}/add_appointment_status`,
      contentType: configJSON.appointmentApiContentType,
      token: this.state.token,
      body
    })
  }

  setInvitedUsers = (users:UserContact[], owner_id:number, invitation_members?:{[key: string]: string}[])=>{
    this.setState({
      invitedUserList: {
        owner_id,
        invited_users: rearrangeContacts(owner_id, users),
        invitation_members: invitation_members as {[key: string]: string}[]
      },
      activeTab:null
    })
  }

  closeUserListModal = ()=>{
    this.setState({
      invitedUserList: null,
      activeTab:null
    })
  }

  setActiveTab = (currentTab:string | null)=>{
    this.setState({
      activeTab: currentTab
    })
  }

  setPages = (pageItems:number)=>{
    this.setState({
      itemsPerPage: pageItems,
      currentPage: 1,
      anchorPagesMenu: null
    },()=>{
      this.getAppointmentList()
    })
  }

  goToAppointmentDetails = (appointmentId:string | number)=>{
    this.props.navigation.navigate("AppointmentDetails",{id:appointmentId})
  }

  redirectTo = (endpoint:string, params?:{[key:string]:string | number})=>{
    this.props.navigation.navigate(endpoint, params)
  }
  navigateToAddAppointment = ()=>{
    return "AddAppointments"
  }

  openDeleteModal = (title: string, appointmentId?: string) => {
    this.setState({
      confirmationPopUpData: {
        header: "Delete Appointment?",
        message: `Are you sure that you want to delete ${title}?`,
        type: "delete",
        appointmentId: appointmentId as string
      }
    })
  }

  onCloseConfirmationModal = () => {
    this.setState({
      confirmationPopUpData: null
    })
  }

  onAcceptConfirmation = async () => {
    if (this.state.confirmationPopUpData) {
      const { type, appointmentId } = this.state.confirmationPopUpData;
      if (type === "delete") {
        HelperFunctions.showLoader();
        this.deleteAppointmentApiCallId = await apiCallFunction({
          method:"DELETE",
          endPoint:`bx_block_appointment_management/appointments/${appointmentId}`,
          contentType: configJSON.appointmentApiContentType,
          token: this.state.token
        })
      }
    }
  }
  // Customizable Area End
}

import React from "react";
import { styled, Modal, Box, Typography } from "@material-ui/core";
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { closeIcon } from "../../../appointmentmanagement/src/assets";
import Avatar from "../../../activityfeed/src/components/Avatar.web";
import { pdfIconSvg } from "../../../activityfeed/src/assets";
const { HelperFunctions: helper } = require("../../../../components/src/HelperFunctions");

interface MorePostMediaModalProps {
    open: boolean;
    onClose: () => void;
    postOwnerDetails: {
        profileUrl: string,
        name: string,
    } | null;
    communityPostMedia: Array<{ id: number, url: string, blob_id?: number }>
}


const MorePostMediaModal: React.FC<MorePostMediaModalProps> = (props: MorePostMediaModalProps) => {
    const { open, onClose, communityPostMedia, postOwnerDetails } = props;

    const pauseVideoOnSliderChange = () => {
        const videos = document.querySelectorAll("video");
        if (videos.length) {
            videos.forEach((video) => video.pause());
        }
    }

    return (
        <Modal
            open={open}
            onClose={onClose}
            style={{ display: "flex", justifyContent: "center", alignItems: "center" }}
        >
            <StyledMorePostMediaModal className="MediaPopup_StyledMorePostMediaModal">
                <Box className="header">
                    <Box className="post-owner-details">
                        <Avatar
                            text={postOwnerDetails?.name}
                            image_url={postOwnerDetails?.profileUrl}
                        />
                        <Box display={"flex"} flexDirection={"column"}>
                            <Typography className="post-owner-name" component={"span"}>{postOwnerDetails?.name}</Typography>
                        </Box>
                    </Box>
                    <img className="close" src={closeIcon} alt="close.svg" onClick={onClose} />
                </Box>
                <Box className="body">
                    <Box className="more-media-slider">
                        <Slider beforeChange={pauseVideoOnSliderChange} initialSlide={0} draggable={false} infinite={false} touchMove={true} touchThreshold={5} slidesToScroll={1} slidesToShow={1} arrows={true} swipeToSlide prevArrow={<helper.customPrevArrow />}
                            nextArrow={<helper.customNextArrow />}>
                            {communityPostMedia.map((fileItem) =>
                                <Box className="community-post-more-media-files" key={fileItem.id}>
                                    {fileItem.url.includes("image") && <img className="community-post-more-media" src={fileItem.url} alt="uploaded-post" />}
                                    {fileItem.url.includes("type=application") && <Box bgcolor={"rgba(0, 0, 0, 0.2)"} borderRadius={"12px"} display="flex" alignItems="center" justifyContent={"center"} className="community-post-more-media"><img className="community-post-more-media" id="pdf-placeholder" src={pdfIconSvg} alt="uploaded-post" /></Box>}
                                    {fileItem.url.includes("video") && <video controls controlsList="nodownload" className="community-post-more-media" src={fileItem.url} />}
                                </Box>
                            )}
                        </Slider>
                    </Box>
                </Box>
            </StyledMorePostMediaModal>
        </Modal>
    )
}

const StyledMorePostMediaModal = styled("div")({
    "&.MediaPopup_StyledMorePostMediaModal": {
        display: "flex",
        flexDirection: "column",
        width: "50.25rem",
        height: "35.3125rem",
        backgroundColor: "#23404B",
        boxShadow: "0px 0px 20px 0px #00000040",
        borderRadius: "1rem",
        position: "relative",
        "& .header": {
            position: "relative",
            padding: "1rem 2rem",
            borderBottom: "1px solid #2d4953",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            "& > img.close": {
                position: "absolute",
                right: "2rem",
                top: "50%",
                transform: "translate(-50%,-50%)",
                cursor: "pointer"
            },
            "& .post-owner-details": {
                display: "flex",
                color: "rgba(255, 255, 255, 1)",
                alignItems: "center",
                gap: "0.5rem",
                "& .avatar": {
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    color: "rgba(37, 236, 242, 1)",
                    width: "36px",
                    height: "36px",
                    border: "1px solid rgba(37, 236, 242, 1)",
                    borderRadius: "100%"
                },
                "& .post-owner-name": {
                    fontSize: "14px",
                },
            },
        },
        "& .body": {
            display: "flex",
            flex: 1,
            flexDirection: "column",
            overflowX: "auto",
            gap: "1rem",
            padding: "1rem 2rem",
            "& .more-media-slider": {
                height: "100%",
                "& img, video": {
                    width: "100%",
                    height: "95%",
                    objectFit: "cover",
                    display: "block",
                    borderRadius: "12px",
                }
            },
            "& .slick-next:before": {
                display: "none",
            },
            "& .slick-prev:before": {
                display: "none",
            }
        },
        "& .custom-prev-arrow, .custom-next-arrow": {
            zIndex: 10,
            cursor: "pointer",
            height: "60px",
            width: "60px",
            display: "flex !important",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "rgba(255, 255, 255, 0.6)"
        },
        [`@media screen and (max-width: 600px)`]: {
            width: "80%",
            height: "70%"
        },
    }
})

export default MorePostMediaModal;
import React, { useState } from "react";
import { styled, IconButton, Menu } from "@material-ui/core";

interface MorePopUpProps {
    menuItems: { 
        label: string, 
        icon: any, 
        onClick: () => void,
        hide?: boolean,
        stayOpenAfterItemClicked?: boolean
    }[];
    onClose?: ()=> void;
    open?: boolean;
    icon?: string;
    hideIcon?: boolean;
    anchorElement?: any;
    anchorOrigin?:{
        vertical: 'bottom' | 'top' | 'center',
        horizontal: 'right' | 'left' | 'center',
    }
    transformOrigin?:{
        vertical: 'bottom' | 'top' | 'center',
        horizontal: 'right' | 'left' | 'center',
    }
}


const MorePopUp: React.FC<MorePopUpProps> = (props: MorePopUpProps) => {
    const { 
        menuItems, 
        onClose, 
        open, 
        icon, 
        hideIcon, 
        anchorElement,
        anchorOrigin = {
            vertical: 'bottom',
            horizontal: 'right',
        },
        transformOrigin = {
            vertical: 'top',
            horizontal: 'right',
        }
     } = props;
    const [anchorEl, setAnchorELe] = useState<HTMLDivElement | null>(null);

    const onClickHandler = (event: React.MouseEvent<HTMLDivElement>) => {
        setAnchorELe(event.currentTarget);
    }

    const onCloseHandler = ()=>{
        setAnchorELe(null)
        onClose?.();
    }

    return (
        <>
            {
                !hideIcon && (
                    <StyledMorePopUp onClick={onClickHandler}>
                        <IconButton>
                            {
                                icon && (
                                    <img src={icon} />
                                )
                            }
                            {
                                !icon && (
                                    <svg width="16" height="4" viewBox="0 0 16 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M2.23042 3.49995C1.81794 3.49995 1.46482 3.35308 1.17107 3.05933C0.877335 2.76558 0.730469 2.41246 0.730469 1.99998C0.730469 1.58749 0.877335 1.23438 1.17107 0.940625C1.46482 0.646875 1.81794 0.5 2.23042 0.5C2.64292 0.5 2.99604 0.646875 3.28977 0.940625C3.58352 1.23438 3.73039 1.58749 3.73039 1.99998C3.73039 2.41246 3.58352 2.76558 3.28977 3.05933C2.99604 3.35308 2.64292 3.49995 2.23042 3.49995ZM7.99964 3.49995C7.58716 3.49995 7.23404 3.35308 6.94029 3.05933C6.64654 2.76558 6.49967 2.41246 6.49967 1.99998C6.49967 1.58749 6.64654 1.23438 6.94029 0.940625C7.23404 0.646875 7.58716 0.5 7.99964 0.5C8.41213 0.5 8.76524 0.646875 9.05899 0.940625C9.35274 1.23438 9.49962 1.58749 9.49962 1.99998C9.49962 2.41246 9.35274 2.76558 9.05899 3.05933C8.76524 3.35308 8.41213 3.49995 7.99964 3.49995ZM13.7689 3.49995C13.3564 3.49995 13.0033 3.35308 12.7095 3.05933C12.4158 2.76558 12.2689 2.41246 12.2689 1.99998C12.2689 1.58749 12.4158 1.23438 12.7095 0.940625C13.0033 0.646875 13.3564 0.5 13.7689 0.5C14.1814 0.5 14.5345 0.646875 14.8282 0.940625C15.122 1.23438 15.2688 1.58749 15.2688 1.99998C15.2688 2.41246 15.122 2.76558 14.8282 3.05933C14.5345 3.35308 14.1814 3.49995 13.7689 3.49995Z" fill="white" />
                                    </svg>
                                )
                            }
                        </IconButton>
                    </StyledMorePopUp>
                )
            }
            <CustomMenu
                open={Boolean(anchorEl) || Boolean(open) || Boolean(anchorElement)}
                anchorEl={anchorEl || anchorElement}
                MenuListProps={{
                    "aria-labelledby": "basic-button",
                }}
                onClose={onCloseHandler}
                anchorOrigin={anchorOrigin}
                transformOrigin={transformOrigin}
                getContentAnchorEl={null}
            >
             <StyledMenuItems className="MorePopUp_StyledMenuItems">
                {
                    menuItems.filter(item => !item.hide).map((item, menuIndex)=>{
                        return (
                            <div key={menuIndex} className="menu-item" onClick={()=>{
                                item.onClick();
                                if(!item.stayOpenAfterItemClicked){
                                    onCloseHandler();
                                }
                            }}>
                                <img src={item.icon} alt="" />
                                <p>{item.label}</p>
                            </div>
                        )
                    })
                }
             </StyledMenuItems>
            </CustomMenu>
        </>
    )
}

const StyledMorePopUp = styled("div")({

})

const CustomMenu = styled(Menu)({
    "& .MuiList-padding": {
        padding: 0,
    },
    "& .MuiPaper-root": {
        background: "transparent"
    }
})

const StyledMenuItems = styled("div")({
    "&.MorePopUp_StyledMenuItems": {
        display: "flex",
        flexDirection: "column",
        minWidth: "9.8125rem",
        border: "1px solid #19B5B945",
        borderRadius: "0.5rem",
        background: "linear-gradient(180deg, #23404B 0%, #19282F 100%)",
        padding: "0.25rem 0rem",
        "& .menu-item": {
            display: "flex",
            alignItems: "center",
            color: "#ffffff",
            fontSize: "1rem",
            fontWeight: 400,
            gap: "0.5rem",
            cursor: "pointer",
            padding: "0.25rem 0.7rem",
            backgroundColor: "transparent",
            transition: "background-color 0.3s ease-in-out",
            "&:hover": {
                backgroundColor: "#0000001A"
            }
        }
    }
})

export default MorePopUp;
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
export const configJSON = require("./config");

// Customizable Area Start
import React from "react";
import { Alert } from "react-native";
import { DateTimePickerEvent } from "@react-native-community/datetimepicker";
import { EventLocation, EventType } from "./types";
import { EmailList, ContactList, SearchLocation, IEventData } from "../../../components/src/CommonTypes";
const { HelperFunctions: helper } = require("../../../components/src/HelperFunctions")
import { Moment } from "moment/moment";
import { getStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export interface S {
  // Customizable Area Start
  title: string;
  notes: string;
  date: Date;
  time: Date;
  showTimePickerModal: boolean;
  showDatePickerModal: boolean;
  assignToList: Array<SelectItem>;
  visibilityList: Array<SelectItem>;
  notifyList: Array<SelectItem>;
  repeatList: Array<SelectItem>;
  showAssignToList: boolean;
  showVisibilityList: boolean;
  showNotifyList: boolean;
  showRepeatList: boolean;
  event_type: string;
  loading: boolean;
  showRepeatValue: boolean;
  showNotifyValue: boolean;
  showVisiblityValue: boolean;
  showAssignToValue: boolean;
  repeatValue: SelectItem;
  notifyValue: SelectItem;
  visiblityValue: SelectItem;
  assignToValue: SelectItem;
  partnerList: Array<SelectItem>;
  lastAssigned: string;
  lastVisible: string;
  selectedEventId: string;
  showClashModal: boolean;
  customRepeatFlag: boolean;
  customRepeat: string;
  role: string;
  isOwner: boolean;
  customRepeatDay: string;
  customRepeatDayValue: string;
  searchLocation: string;
  lat: string;
  lng: string;
  showCustomRepeatList: boolean;
  showCustomRepeatValue: boolean;
  customRepeatValue: SelectItem;
  customRepeatList: Array<SelectItem>;
  token: string;
  imageInputRef: React.RefObject<HTMLInputElement> | null;
  selectedImage: string | null;
  imageError: string | null;
  eventDate: string;
  location: EventLocation | null;
  venueDetails: string;
  startTime: string;
  endTime: string;
  description: string;
  isDatePickerOpen: boolean;
  eventType: string;
  email: string;
  emailList: EmailList[];
  selectedEmailList: EmailList[];
  contactList: ContactList[];
  phoneBookContactList: ContactList[];
  selectedContactList: ContactList[];
  eventCapacity: string;
  openEmailDropdown: boolean;
  openContactList: boolean;
  currentFocusIndex: number | null;
  mapMenuAnchorEle: HTMLDivElement | null;
  selectedLocation: SearchLocation | null;
  viewEmailAndContactData: {
    value: EmailList[] | ContactList[];
    type: "email" | "contact";
  } | null;
  predictions: SearchLocation[];
  recentLocations: SearchLocation[];
  base64Image: string | null;
  editMode: boolean;
  eventId: string;
  isFormUpdated: boolean;
  emailErrorMessage: string;
  userId: number | null;
  isLoadingContacts: boolean;
  registeredContactsPagination:{
    per_page: number,
    page_no: number,
    hasMore: boolean
  }
  callback?: any;
  // Customizable Area End
}

interface SS {
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

// Customizable Area Start

export interface SelectItem {
  id: number;
  name: string;
  value?: string | undefined;
  isSelected: boolean;
  counter?: boolean;
  full_name?: string;
  nick_name?: string;
  profile_image?: string | undefined;
}

// Customizable Area End

export default class AddEventDetailController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiAcceptRequestCallId: string = "";
  apiCreateEventCallId: string = "";
  apiDeleteEventCallId: string = "";
  emailSuggestionAPICallId: string = "";
  fetchContactsApiCallId: string = "";
  fetchRegisteredContactsApiCallId: string = "";
  searchContactApiCallId: string = "";
  searchLocationApiCallId: string = "";
  recentSearchLocationApiCallId: string = "";
  createEventApiCallId: string = "";
  getEventApiCallId: string = "";
  private readonly errorTitle = "Error";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    runEngine.attachBuildingBlock(this as IBlock, [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ]);
    this.receive = this.receive.bind(this);

    this.state = {
      // Customizable Area Start
      title: "",
      notes: "",
      loading: false,
      token: "",
      showTimePickerModal: false,
      showDatePickerModal: false,
      date: new Date(Date.now() + 6 * 1000),
      time: new Date(Date.now() + 6 * 1000),
      showAssignToList: false,
      showVisibilityList: false,
      showNotifyList: false,
      showRepeatList: false,
      repeatValue: { id: -1, name: "None", isSelected: false },
      notifyValue: { id: -1, name: "None", isSelected: false },
      visiblityValue: { id: -1, name: "None", isSelected: false },
      assignToValue: { id: -1, name: "None", isSelected: false },
      showRepeatValue: false,
      showNotifyValue: false,
      showVisiblityValue: false,
      showAssignToValue: false,
      event_type: "",
      lastAssigned: "Assign To",
      lastVisible: "Visibility",
      assignToList: [],
      visibilityList: [],
      selectedEventId: "",
      searchLocation: "Location",
      lat: "",
      lng: "",
      notifyList: [
        {
          id: 0,
          name: "One Day Before",
          isSelected: false,
        },
        {
          id: 1,
          name: "One Hour Before",
          isSelected: false,
        },
        {
          id: 2,
          name: "30 Minutes Before",
          isSelected: false,
        },
      ],

      repeatList: [
        {
          id: 0,
          name: "Never",
          isSelected: false,
        },
        {
          id: 1,
          name: "Every Day",
          isSelected: false,
        },
        {
          id: 2,
          name: "Every Week",
          isSelected: false,
        },
        {
          id: 3,
          name: "Every Month",
          isSelected: false,
        },
        {
          id: 4,
          name: "Every 2 Months",
          isSelected: false,
        },
        {
          id: 5,
          name: "Every Year",
          isSelected: false,
        },
        {
          id: 6,
          name: "Custom",
          isSelected: false,
        },
      ],
      partnerList: [],
      showClashModal: false,
      customRepeatFlag: false,
      customRepeat: "",
      role: "",
      isOwner: true,
      customRepeatDay: "",
      customRepeatDayValue: "1",
      showCustomRepeatList: false,
      showCustomRepeatValue: false,
      customRepeatValue: {
        id: -1,
        name: "",
        value: "",
        isSelected: false,
      },
      customRepeatList: [
        {
          id: 0,
          name: "Day",
          value: "day",
          isSelected: false,
        },
        {
          id: 1,
          name: "Week",
          value: "week",
          isSelected: true,
        },
        {
          id: 2,
          name: "Month",
          value: "month",
          isSelected: false,
        },
        {
          id: 3,
          name: "Year",
          value: "year",
          isSelected: false,
        },
      ],
      imageInputRef: React.createRef<HTMLInputElement>(),
      selectedImage: null,
      imageError: null,
      eventDate: "",
      location: null,
      startTime: "",
      endTime: "",
      venueDetails: "",
      description: "",
      isDatePickerOpen: false,
      eventType: "",
      email: "",
      emailList: [],
      selectedEmailList: [],
      contactList: [],
      phoneBookContactList: [],
      selectedContactList: [],
      eventCapacity: "",
      openEmailDropdown: false,
      openContactList: false,
      currentFocusIndex: null,
      mapMenuAnchorEle: null,
      selectedLocation: null,
      viewEmailAndContactData: null,
      predictions: [],
      recentLocations: [],
      base64Image: null,
      editMode: false,
      eventId: "",
      isFormUpdated: false,
      emailErrorMessage: "",
      userId: null,
      isLoadingContacts: false,
      registeredContactsPagination:{
        per_page: 50,
        page_no: 1,
        hasMore: true
      }
      // Customizable Area End
    };
  }

  // Customizable Area Start
  _reloadEventData = () => {
    const calendarSelectedDate = new Date();
    this.setState({
      event_type: this.props.navigation.state.params.event,
      date: new Date(calendarSelectedDate),
    });
    const selectedEvent = this.props.navigation.state.params.event;

    if (selectedEvent.id) {
      // Assigned To Start
      const { assign_to } = selectedEvent;
      const assignedList = assign_to?.map((item: SelectItem) => `${item.id}`);
      let lastAssigned;
      if (assign_to?.length > 0) {
        lastAssigned = assign_to[assign_to.length - 1].nick_name
          ? assign_to[assign_to.length - 1].nick_name
          : assign_to[assign_to.length - 1].full_name;
      } else {
        lastAssigned = "Assign To";
      }
      // Assigned To End

      // Visible To Start
      const { visibility } = selectedEvent;
      const visibilityList = visibility?.map(
        (item: SelectItem) => `${item.id}`
      );
      let lastVisible;
      if (visibility?.length > 0) {
        lastVisible = visibility[visibility.length - 1].nick_name
          ? visibility[visibility.length - 1].nick_name
          : visibility[visibility.length - 1].full_name;
      } else {
        lastVisible = "Visibility";
      }
      // Visible To End

      // Notify Start
      let notifyList: Array<SelectItem> = [];
      let notifyData: SelectItem = { id: -1, name: "None", isSelected: false };
      this.state.notifyList.filter((item: SelectItem) => {
        if (item.name === selectedEvent?.notify) {
          notifyData = {
            id: item.id,
            name: item.name,
            isSelected: true,
          };
          notifyList.push(notifyData);
        } else {
          notifyList.push(item);
        }
      });
      // Notify End

      // Repeat Start
      let repeatList: { id: number; name: string; isSelected: boolean }[] = [];
      let repeatData: SelectItem = { id: -1, name: "None", isSelected: false };
      let customRepeatList: Array<SelectItem> = [];
      let customRepeatData: SelectItem = {
        id: -1,
        name: "None",
        value: "None",
        isSelected: false,
      };
      let customRepeatData1: SelectItem = {
        id: -1,
        name: "None",
        value: "None",
        isSelected: false,
      };
      this.state.repeatList.filter((item: SelectItem) => {
        if (item.name === selectedEvent?.repeat) {
          repeatData = {
            id: item.id,
            name: item.name,
            isSelected: true,
          };
          repeatList.push(repeatData);
        } else {
          repeatList.push(item);
        }
      });
      if (selectedEvent?.repeat === "Custom") {
        let repeatDay = selectedEvent?.custom_repeat_in_number;
        this.setState({
          showCustomRepeatValue: true,
          customRepeatFlag: true,
          customRepeatDayValue: repeatDay.toString(),
        });
        this.state.customRepeatList.filter((item: SelectItem) => {
          if (item.value === selectedEvent?.custom_repeat_every) {
            customRepeatData = {
              id: item.id,
              name: item.name,
              value: item.value,
              isSelected: true,
            };
            customRepeatList.push(customRepeatData);
          } else {
            customRepeatData1 = {
              id: item.id,
              name: item.name,
              value: item.value,
              isSelected: false,
            };
            customRepeatList.push(customRepeatData1);
          }
        });
      }
      // Repeat End
      this.setState({
        selectedEventId: selectedEvent.id,
        event_type: selectedEvent.event_type,
        title: selectedEvent.title,
        notes: selectedEvent.notes,
        time: new Date(selectedEvent.time),
        date: new Date(selectedEvent.date),
        showNotifyValue: true,
        notifyValue: notifyData,
        notifyList: notifyList,
        showRepeatValue: true,
        repeatValue: repeatData,
        repeatList: repeatList,
        customRepeatValue: customRepeatData,
        customRepeatList: customRepeatList,
        assignToList: assignedList,
        lastAssigned: lastAssigned,
        visibilityList: visibilityList,
        lastVisible: lastVisible,
        role: selectedEvent.role,
        isOwner: selectedEvent.role === "Owner" ? true : false,
        lat: selectedEvent.latitude,
        lng: selectedEvent.longitude,
        searchLocation: selectedEvent.address
          ? selectedEvent.address
          : "Location",
      });
    }
  };

  async componentDidMount() {
    this.getToken();
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
        this._refreshLocationData();
        this._reloadEventData();
      });
    }
    // Customizable Area Start
    document.addEventListener("click", this.handleClickOutside, true);
    const userData = await helper.getUserData();
    if(userData){
      this.setState({
        userId: userData.id
      })
    }
    // Customizable Area End
  }

  // Customizable Area End
  getToken = () => {
    const message: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(message);
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let token = message.getData(getName(MessageEnum.SessionResponseToken));
      this.setToken(token);
    }

    const apiCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    if(getName(MessageEnum.RestAPIResponceMessage) === message.id ){
      switch (apiCallId) {
        case this.emailSuggestionAPICallId:
          this.handleEmailSuggestionAPICall(message)
          break;
        case this.fetchContactsApiCallId:
          this.handleFetchContactsAPICall(message)
          break;
        case this.fetchRegisteredContactsApiCallId:
          this.handleFetchRegisteredContactsAPICall(message)
          break;
        case this.searchContactApiCallId:
          this.searchContactApiCallHandler(message)
          break;
        case this.searchLocationApiCallId:
          this.handleSearchLocationCall(message)
          break;
        case this.recentSearchLocationApiCallId:
          this.handleRecentSearchLocationCall(message)
          break;
        case this.createEventApiCallId:
          this.handleCreateEventCall(message)
          break;
        case this.getEventApiCallId:
          this.handleGetEventDetailApiCall(message)
          break;
        default:
          break;
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  createEventSuccessCallBack = async () => {
    this.setState({ loading: false });
    this.props.navigation.navigate("AllEvent");
  };

  createEventFailureCallBack = () => {
    this.setState({ loading: false });
  };

  _refreshLocationData = () => {
    const { params } = this.props.navigation.state;
    if (params.location != null) {
      this.setState({
        lat: params.lat,
        lng: params.lng,
        searchLocation: params.location,
      });
    }
  };

  onPressSaveButton = async () => {
    if (!this.state.title) {
      this.showAlert("", configJSON.noTitleMessage);
      return;
    }

    let token = this.state.token;
    this.setState({ loading: true });

    const header = {
      "Content-Type": "application/json",
      token: token,
    };

    const attrs = {
      title: this.state.title,
      time: this.state.time,
      date: this.state.date,
      latitude: this.state.lat,
      longitude: this.state.lng,
      address:
        this.state.searchLocation === "Location"
          ? ""
          : this.state.searchLocation,
      assign_to: this.state.assignToList,
      visibility: this.state.visibilityList,
      notify: this.state.notifyValue.id,
      repeat: this.state.repeatValue.id ? this.state.repeatValue.id : 0,
      custom_repeat_in_number:
        this.state.repeatValue.id === 6 ? this.state.customRepeatDayValue : "",
      custom_repeat_every:
        this.state.repeatValue.id === 6
          ? this.state.customRepeatValue.value
            ? this.state.customRepeatValue.value
            : "week"
          : "",
      notes: this.state.notes,
      event_type: this.state.event_type,
    };

    const data = {
      attributes: attrs,
    };

    const httpBody = {
      data: data,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiCreateEventCallId = requestMessage.messageId;

    if (this.state.selectedEventId) {
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `/events/events/${this.state.selectedEventId}`
      );
    } else {
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        "/events/events"
      );
    }

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    if (this.state.selectedEventId) {
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        "PUT"
      );
    } else {
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        "POST"
      );
    }

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  deleteEvent = async () => {
    let token = await this.state.token;
    this.setState({ loading: true });

    const header = {
      "Content-Type": "application/json",
      token: token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiDeleteEventCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/events/events/${this.state.selectedEventId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "DELETE"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  addZero = (idx: number) => {
    let newIdx = idx.toString();
    if (idx < 10) {
      newIdx = "0" + idx;
    }
    return newIdx;
  };

  convertTime = (str: Date) => {
    let date = new Date(str);
    let h = this.addZero(date.getHours());
    let m = this.addZero(date.getMinutes());

    return [h + ":" + m];
  };

  convertDate = (str: Date) => {
    let date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [day, mnth, date.getFullYear()].join("/");
  };

  onPressAssignBtn = (item: SelectItem, type: string) => {
    let tempList =
      type === "assignToList"
        ? this.state.assignToList
        : this.state.visibilityList;

    const newTempList = tempList.filter((listItem) => item.id === listItem.id);
    const index =
      newTempList.length === 1 ? tempList.indexOf(newTempList[0]) : -1;

    const noneIndex = tempList.indexOf({
      id: -1,
      name: "None",
      isSelected: false,
    });
    if (noneIndex > -1) {
      tempList.splice(noneIndex, 1);
    }
    if (index > -1) {
      tempList.splice(index, 1);
    } else {
      tempList.push({ id: item.id, name: "", isSelected: false });
    }

    if (type === "assignToList") {
      this.setState({
        ...this.state,
        assignToList: tempList,
        lastAssigned: item.name,
        showAssignToList: false,
      });
    } else {
      this.setState({
        ...this.state,
        visibilityList: tempList,
        lastVisible: item.name,
        showVisibilityList: false,
      });
    }
  };

  onPressAll = (type: string) => {
    if (type === "assignToList") {
      this.setState({
        ...this.state,
        lastAssigned: "All",
        showAssignToList: false,
      });
    } else {
      this.setState({
        ...this.state,
        lastVisible: "All",
        showVisibilityList: false,
      });
    }
  };

  onPressNone = (type: string) => {
    if (type === "assignToList") {
      this.setState({
        ...this.state,
        lastAssigned: "None",
        showAssignToList: false,
      });
    } else {
      this.setState({
        ...this.state,
        lastVisible: "None",
        showVisibilityList: false,
      });
    }
  };

  onPressVisibilityRadioBtn = (item: SelectItem) => {
    let tempList = this.state.visibilityList;
    for (let i = 0; i < tempList.length; i++) {
      if (tempList[i].id == item.id) {
        tempList[i].isSelected = true;
        this.setState({
          visiblityValue: tempList[i],
          showVisiblityValue: true,
        });
      } else {
        tempList[i].isSelected = false;
      }
    }
    this.setState({ visibilityList: tempList, showVisibilityList: false });
  };

  visibilityCheck = (item: SelectItem) => {
    let localRepeatList = this.state.visibilityList;
    let selectIndex = localRepeatList.findIndex(
      (itemFilter: SelectItem) => itemFilter.id === item.id
    );
    if (!localRepeatList[selectIndex]) {
      return;
    }
    (localRepeatList[selectIndex].isSelected =
      !localRepeatList[selectIndex].isSelected),
      this.setState({
        visibilityList: localRepeatList,
        showVisibilityList: false,
        showVisiblityValue: true,
      });
  };

  onPressVisibilityCheckBox = (item: SelectItem) => {
    !item.counter && item.isSelected
      ? this.onPressVisibilityRadioBtn(item)
      : this.visibilityCheck(item);
  };

  onPressNotifyRadioBtn = (item: SelectItem) => {
    let tempList = this.state.notifyList;
    for (let i = 0; i < tempList.length; i++) {
      if (tempList[i].id == item.id) {
        tempList[i].isSelected = true;
        this.setState({ notifyValue: tempList[i], showNotifyValue: true });
      } else {
        tempList[i].isSelected = false;
      }
    }
    this.setState({ notifyList: tempList, showNotifyList: false });
  };

  onPressRepeatRadioBtn = (item: SelectItem) => {
    let tempList = this.state.repeatList;
    for (let i = 0; i < tempList.length; i++) {
      if (tempList[i].id == item.id) {
        tempList[i].isSelected = true;
        this.setState({ repeatValue: tempList[i], showRepeatValue: true });
      } else {
        tempList[i].isSelected = false;
      }
    }
    this.setState({ repeatList: tempList, showRepeatList: false });
    if (item.name === "Custom") {
      this.setState({ customRepeatFlag: true });
    } else {
      this.setState({ customRepeatFlag: false });
    }
  };

  handleTimePickerChangeIos = (dateStr: string, value: Date) => {
    this.setState({ time: value, showTimePickerModal: false });
  };

  handleDatePickerChangeIos = (dateStr: string, value: Date) => {
    this.setState({ date: value as Date, showDatePickerModal: false });
  };

  handleTimePickerChange = (
    event: DateTimePickerEvent,
    value: Date | undefined
  ) => {
    this.setState({ time: value as Date, showTimePickerModal: false });
  };

  handleDatePickerChange = (
    event: DateTimePickerEvent,
    value: Date | undefined
  ) => {
    this.setState({ date: value as Date, showDatePickerModal: false });
  };

  handleTitleInputChange = (text: string) => {
    this.setState({ title: text });
  };

  handleTimePickerModalOpen = () => {
    this.setState({ showTimePickerModal: true });
  };

  handleDatePickerModalOpen = () => {
    this.setState({ showDatePickerModal: true });
  };

  navigateToAddEventLocation = () => {
    this.props.navigation.navigate("AddEventLocation", {
      from: "event",
    });
  };

  handleVisibilityButtonPress = () => {
    this.setState({
      showVisibilityList: !this.state.showVisibilityList,
    });
  };

  handleRepeatInputChange = (text: string) => {
    this.setState({ customRepeatDayValue: text });
  };

  handleCustomRepeatListButtonPress = () => {
    this.setState({
      showCustomRepeatList: !this.state.showCustomRepeatList,
    });
  };

  onPressCustomRepeatRadioBtn = (item: SelectItem) => {
    let tempList = this.state.customRepeatList;
    for (let i = 0; i < tempList.length; i++) {
      if (tempList[i].id == item.id) {
        tempList[i].isSelected = true;
        this.setState({
          customRepeatValue: tempList[i],
          showCustomRepeatValue: true,
        });
      } else {
        tempList[i].isSelected = false;
      }
    }
    this.setState({ customRepeatList: tempList, showCustomRepeatList: false });
  };

  handleNotesInputChange = (text: string) => {
    this.setState({ notes: text });
  };

  onPressDelete = () => {
    Alert.alert("", configJSON.deleteMessage, [
      {
        text: configJSON.cancel,
        style: "cancel",
      },
      {
        text: configJSON.ok,
        onPress: () => {
          this.deleteEvent();
        },
      },
    ]);
  };

  handleShowClashModalClose = () => {
    this.setState({ showClashModal: false });
  };

  handleAssignButtonPress = (typeView: string) => {
    if (typeView === "showAssignToList") {
      this.setState((prevState) => ({
        showAssignToList: !prevState.showAssignToList,
      }));
    } else {
      this.setState((prevState) => ({
        showVisibilityList: !prevState.showVisibilityList,
      }));
    }
  };

  checkShowList = (typeView: string) => {
    if (typeView === "showAssignToList") {
      return this.state.showAssignToList;
    } else {
      return this.state.showVisibilityList;
    }
  };

  handleShowNotify = () => {
    this.setState({ showNotifyList: !this.state.showNotifyList });
  };

  handleShowRepeat = () => {
    this.setState({ showRepeatList: !this.state.showRepeatList });
  };

  // Functions for web start
  async componentWillUnmount(): Promise<void> {
    document.removeEventListener("click", this.handleClickOutside, true);
  }

  setToken = async (token: string | null)=>{
    if(!token){
      token = await getStorageData("authToken");
    }
    this.setState({ token: token as string },()=>{
      this.getRecentLocations()
      const eventId = this.props.navigation.getParam("id");
      if(eventId){
        this.setState({
          eventId: eventId,
          editMode: true
        },()=>{
          this.getEventDetail();
        })
      }
    });
  }

  handleEmailSuggestionAPICall = (message: Message) => {
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (responseJson && responseJson.suggestion_list) {
      this.setState({
        emailList: responseJson.suggestion_list
      })
    } else {
      this.setState({
        emailList: []
      })
    }
  }

  handleFetchContactsAPICall = (message: Message) => {
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (responseJson && responseJson.data) {
      const phonebookContactsList = responseJson.data.map((phonebookContact: { data: { id: string, type: string, attributes: { name: string, phone_number: string }} }) => {
        return {
          name: phonebookContact.data.attributes.name,
          id: phonebookContact.data.id,
          isNonRegistered: true,
          full_phone_number: phonebookContact.data.attributes.phone_number
        }
      })
      this.setState({
        phoneBookContactList: phonebookContactsList
      }, () =>  {
        this.fetchRegisteredContacts();
      })
    } else {
      this.setState({
        phoneBookContactList: []
      }, () => {
        this.fetchRegisteredContacts();
      })
    }
  }

  handleFetchRegisteredContactsAPICall = (message: Message) => {
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (responseJson?.data?.users) {
      this.setState(prevState=>{
        return {
          contactList: [...prevState.contactList, ...responseJson.data.users]
        }
      })
    } 

    this.state.callback?.();
    this.setState(prevState => {
      return {
        isLoadingContacts: false,
        registeredContactsPagination: {
          ...prevState.registeredContactsPagination,
          page_no: prevState.registeredContactsPagination.page_no + 1,
          hasMore: responseJson && responseJson.data && responseJson.data.users ? true : false
        }
      }
    })
  }

  fetchContacts = async () => {
    this.setState({
      isLoadingContacts: true
    })
    this.searchContactApiCallId = "";
    this.fetchContactsApiCallId = await helper.apiCall({
      method: "GET",
      endPoint: configJSON.phonebookContacts,
      contentType: configJSON.validationApiContentType,
      token: this.state.token
    })
  }

  fetchRegisteredContacts = async (callback?: any) => {
    this.setState({
      isLoadingContacts: true,
      callback
    })
    this.searchContactApiCallId = "";
    const {
      registeredContactsPagination,
      token
    } = this.state;

    this.fetchRegisteredContactsApiCallId = await helper.apiCall({
      method: "GET",
      endPoint: `account_block/accounts/registered_users?per_page=${registeredContactsPagination.per_page}&page_no=${registeredContactsPagination.page_no}`,
      contentType: configJSON.validationApiContentType,
      token: token
    })
  }

  onSelectImage = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const result = await helper.covertToObjectUrl(event);
    
    if (!result.imgSizeExceed) {
      this.setState({
        selectedImage: result.selectedImage,
        imageError: null,
        isFormUpdated: true
      })
      const base64Image = await helper.convertToBase64(event);
      this.setState({
        base64Image: base64Image
      })
    } else {
      this.setState({
        imageError: "Please upload JPG and PNG, up to 10 MB."
      })
    }
  }
  onChangeInputs = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const name = event.target.name;
    const sanitizedValue = event.target.value.replace(/^\s+/, '');
    this.setState(prevState => ({ ...prevState, [name]: sanitizedValue, isFormUpdated: true }))
  }
  onChangeDateAndTime = (value: string, name: string) => {
    this.setState(prevState => ({ ...prevState, [name]: value, isFormUpdated: true }))
  }
  openDatePicker = () => {
    const datePickerDialog = document.getElementById("mui-date-picker");
    datePickerDialog?.click();
  }
  onDateChangeHandler = (value: Date | Moment | null) => {
    const formatDate = helper.formatDate(value, "YYYY-MM-DD");
    this.setState({
      eventDate: formatDate,
      isFormUpdated: true
    })
  }
  onChangeEventType = (value: string) => {
    this.setState({
      eventType: value,
      isFormUpdated: true
    })
  }
  onEmailChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value.trim();
    this.setState({ email: value, emailErrorMessage: "" })
    this.emailSuggestionAPICallId = await helper.apiCall({
      method: "GET",
      endPoint: `account_block/accounts/auto_suggestions?email=${value}`,
      contentType: configJSON.validationApiContentType,
      token: this.state.token
    })
  }
  setCapacity = (newCapacity: string) => {
    if (parseInt(newCapacity) < 0 || parseInt(newCapacity) > 5000) return;
    this.setState({
      eventCapacity: newCapacity,
      isFormUpdated: true
    })
  }
  openSelectEmailDropdown = () => {
    this.setState(()=>({openEmailDropdown: true}))
  }
  handleClickOutside = (event: MouseEvent) => {
    const menu = document.getElementById("email-menu-container");
    if (menu && !menu.contains(event.target as Node)) {
      this.setState({
        openEmailDropdown: false
      })
    }
  }
  openContactList = ()=>{
    this.setState({
      openContactList: true,
      registeredContactsPagination:{
        per_page: 50,
        page_no: 1,
        hasMore: true
      },
    })
    this.fetchContacts()
  }
  closeContactList = ()=>{
    this.setState({
      openContactList: false,
      registeredContactsPagination:{
        per_page: 50,
        page_no: 1,
        hasMore: true
      },
      contactList: [],
      phoneBookContactList: []
    })
  }
  onAddContacts = (contacts: ContactList[])=>{
    this.setState(prevState=>{
      return {
        selectedContactList: [...contacts],
        openContactList: false,
        isFormUpdated: true
      }
    })
  }
  onRemoveContact = (contactId: number)=>{
    const newContactList = this.state.selectedContactList.filter(contact=> contact.id !== contactId);
    this.setState({
      selectedContactList: newContactList,
      isFormUpdated: true
    })
  }
  onSelectEmail = (email: EmailList) => {
    if (!this.state.selectedEmailList.find(item => item.email === email.email)) {
      this.setState(prevState => {
        return {
          selectedEmailList: [...prevState.selectedEmailList, email],
          email: "",
          openEmailDropdown: false,
          isFormUpdated: true
        }
      })
    } else {
      this.setState({
        email: "",
        openEmailDropdown: false
      })
    }
  }
  onRemoveEmail = (emailId: string)=>{
    const newEmailList = this.state.selectedEmailList.filter(email=> email.email !== emailId);
    this.setState({
      selectedEmailList: newEmailList,
      isFormUpdated: true
    })
  }
  isSubmitButtonDisable = () => {
    const { title, eventDate, venueDetails, startTime, eventType, isFormUpdated, editMode } = this.state;
    if (!title || !eventDate || !venueDetails || !startTime || !eventType) {
      return true;
    }

    if(editMode && !isFormUpdated){
      return true;
    }
    return false;
  }
  redirectTo = (destination: string) => {
    this.props.navigation.navigate(destination);
  }
  validateFormData = () => {
    const errors: { [key: string]: {} } = {};
    const { startTime, endTime, eventDate, eventType, eventCapacity, selectedEmailList, selectedContactList } = this.state;
    if (startTime && eventDate && new Date(eventDate).toDateString() === new Date().toDateString() && helper.isPastTime(startTime)) {
      errors.eventTime = {
        message: 'Please select the valid time'
      }
    } else if (startTime && endTime && helper.isStartTimeLessThanEndTime(startTime, endTime)) {
      errors.eventTime = {
        message: 'End time cannot be earlier than start time. Please select a valid time.'
      }
    } else if (startTime && endTime && startTime === endTime) {
      errors.eventTime = {
        message: 'Start and end time must be different. Please choose a valid time range.'
      }
    }

    if(eventType === EventType.PUBLIC && ((!eventCapacity && (selectedEmailList.length > 0 || selectedContactList.length > 0)) || (eventCapacity && parseInt(eventCapacity) < (selectedEmailList.length + selectedContactList.length))) ){
      errors['eventCapacity'] = {
        message: `Please expand the event capacity to accommodate the selected emails and contacts.`
      }
    }
    return errors;
  }
  checkIsNumber = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = (event.target as HTMLInputElement).value;
    const sanitizedValue = helper.numberSanitization(value, true);
    if(parseInt(sanitizedValue) > 5000) return;
    this.setState({
      eventCapacity: sanitizedValue,
      isFormUpdated: true
    })
  }

  onFocusChange = (currFocus: number | null)=>{
    this.setState({
      currentFocusIndex: currFocus
    })
  }

  openMapMenu = (event: React.MouseEvent<HTMLDivElement>)=>{
    this.setState({
      mapMenuAnchorEle: event.currentTarget
    })
  }
  onCloseMapMenu = ()=>{
    this.setState({
      mapMenuAnchorEle: null
    })
  }
  onSelectLocation = (location: SearchLocation | null)=>{
    this.setState({
      selectedLocation: location,
      mapMenuAnchorEle: null,
      isFormUpdated: true
    })
  }
  viewEmailAndContact = (type: "email" | "contact")=>{
    if(type === "contact"){
      this.setState({
        viewEmailAndContactData:{ type, value: this.state.selectedContactList }
      })
    }else if(type === "email"){
      this.setState({
        viewEmailAndContactData:{ type, value: this.state.selectedEmailList }
      })
    }
  }
  closeViewEmailAndContactModal = ()=>{
    this.setState({
      viewEmailAndContactData: null
    })
  }
  
  onDoneViewEmailAndContact = (value: EmailList[] | ContactList[])=>{
    const { viewEmailAndContactData } = this.state;

    if(viewEmailAndContactData?.type === "contact"){
      this.setState({
        selectedContactList: value as ContactList[],
        viewEmailAndContactData: null,
        isFormUpdated: true
      })
    }else if(viewEmailAndContactData?.type === "email"){
      this.setState({
        selectedEmailList: value as EmailList[],
        viewEmailAndContactData: null,
        isFormUpdated: true
      })
    }
  }
  onSubmit = async ()=>{
    const errors = this.validateFormData();
    if(Object.keys(errors).length === 0){
      const { editMode, eventId } = this.state;
      helper.showLoader();
      if(editMode){
        this.createEventApiCallId = await helper.apiCall({
          method: "PUT",
          endPoint: `bx_block_events/events/${eventId}`,
          contentType: configJSON.validationApiContentType,
          token: this.state.token,
          body: await this.getDataForPost()
        })
      }else{
        this.createEventApiCallId = await helper.apiCall({
          method: "POST",
          endPoint: `bx_block_events/events`,
          contentType: configJSON.validationApiContentType,
          token: this.state.token,
          body: await this.getDataForPost()
        })
      }
    }
  }
  findItemPresentInEmailAndContactList = (accountId: number | string) => {
    const { selectedEmailList } = this.state;
    const selected = !!(selectedEmailList.find(email=> email.id === accountId));
    return selected;
  }

  findItemPresentInContactList = (accountId: number | string) => {
    const { selectedEmailList, selectedContactList } = this.state;
    const selected = !!(selectedEmailList.find(email=> email.id === accountId) || selectedContactList.find(contact=> contact.id === accountId) || selectedContactList.find(contact=> contact.full_phone_number === accountId));
    return selected;
  }

  getRecentLocations = async ()=>{
    this.recentSearchLocationApiCallId = await helper.apiCall({
      method: "GET",
      endPoint: `bx_block_maps3/locations/recent_location?type=event`,
      contentType: configJSON.validationApiContentType,
      token: this.state.token
    })
  }
  onSearchLocation = async (location: string) => {
    this.setState({
      searchLocation: location
    })
    this.searchLocationApiCallId = await helper.apiCall({
      method: "GET",
      endPoint: `bx_block_maps3/locations?query=${location}`,
      contentType: configJSON.validationApiContentType,
      token: this.state.token
    })
  }
  onSearchContact = async (contact: string) => {
    this.setState({ contactList: [], phoneBookContactList: [], isLoadingContacts: true}, async() => {
      if(contact){
        this.searchContactApiCallId = await helper.apiCall({
          method: "GET",
          endPoint: `${configJSON.searchContactsApiEndPoint}?query=${contact}`,
          contentType: configJSON.validationApiContentType,
          token: this.state.token
        })
      }else{
        this.setState({
          registeredContactsPagination:{
            per_page: 50,
            page_no: 1,
            hasMore: true
          },
        })
        this.fetchContacts()
      }
    })
  }
  searchContactApiCallHandler = (message: Message) => {
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (responseJson && (responseJson?.accounts?.data || responseJson?.phonebook_contacts?.data)) {
      const accountContacts = responseJson.accounts.data.map((contact: { type: string, id: number, attributes: { email: string, full_phone_number:string, full_name:string, id: number } }) => {
        return {
          id: contact.attributes.id,
          full_phone_number: contact.attributes.full_phone_number,
          name: contact.attributes.full_name,
          email: contact.attributes.email,
        }
      })
      const phonebookContacts = responseJson.phonebook_contacts.data.map((contact: { type: string, id: number, attributes: { phone_number:string, name:string, id: number } }) => {
        return {
          id: contact.id,
          full_phone_number: contact.attributes.phone_number,
          name: contact.attributes.name,
          email: "",
          isNonRegistered: true,
        }
      })
      this.setState({
        contactList: accountContacts,
        phoneBookContactList: phonebookContacts,
        isLoadingContacts: false,
      })
    } else {
      this.setState({
        contactList: [],
        phoneBookContactList: [],
        isLoadingContacts: false,
      })
    }
  }
  handleSearchLocationCall = (message: Message) => {
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (responseJson && !responseJson.errors && responseJson.predictions) {
      const locations:SearchLocation[] = [];
      responseJson.predictions.map((prediction:SearchLocation)=>{
        const location:SearchLocation = {
          description: prediction.description,
          place_id: prediction.place_id,
          structured_formatting: prediction.structured_formatting
        };
        locations.push(location);
      })
      this.setState({
        predictions: locations
      })
    }else{
      this.setState({
        predictions: []
      })
    }
  }

  handleRecentSearchLocationCall = (message: Message) => {
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (responseJson && responseJson.data && responseJson.data.length) {
      const locations:SearchLocation[] = [];
      responseJson.data.map((prediction:{id:string,attributes:{[key:string]:string}})=>{
        const location:SearchLocation = {
          description: "",
          place_id: prediction.attributes.place_id,
          structured_formatting: {
            main_text: prediction.attributes.city,
            secondary_text: `${prediction.attributes.state}, ${prediction.attributes.country}`
          }
        };
        locations.push(location);
      })
      this.setState({
        recentLocations: locations
      })
    }else{
      this.setState({
        recentLocations: []
      })
    }
  }

  getDataForPost = async ()=>{
    const { 
      title, 
      eventDate, 
      selectedLocation, 
      venueDetails, 
      startTime, 
      endTime, 
      description, 
      eventType, 
      selectedEmailList, 
      eventCapacity,
      base64Image
    } = this.state;
    // const base64Image = helper.
    return {
      title: title.trim(),
      event_date: eventDate,
      start_event_time: startTime,
      end_event_time: endTime,
      place_id: selectedLocation?.place_id,
      venue_details: venueDetails,
      description: description,
      event_type: eventType,
      assignee_email: selectedEmailList.filter(email=> email.id !== -1 && email.full_name).map(email=> email.id),
      non_registered_users: [ ...selectedEmailList.filter(email=> email.id === -1).map(email=> email.email), ...this.state.selectedContactList.filter(contact => contact.isNonRegistered).map(contact => contact.full_phone_number)],
      contacts: this.state.selectedContactList.filter(contact => !contact.isNonRegistered).map((contact:ContactList)=> contact.id),
      event_capacity: eventType === EventType.PUBLIC && eventCapacity ? eventCapacity : undefined,
      event_image: base64Image ? base64Image : undefined
    }
  }

  handleCreateEventCall = (message: Message) => {
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    helper.hideLoader();
    if(responseJson && responseJson.data){
      const successToast = this.state.editMode ? "Event updated successfully!" : "Event created successfully!";
      helper.showSuccessToast(successToast);
      this.redirectTo("Events");
    } else {
      const createEventErrMsg = responseJson?.errors?.[0]?.message ? responseJson.errors[0].message : "Something went wrong, please try again!";
      helper.showErrorToast(createEventErrMsg);
    }
  }

  getEventDetail = async ()=>{
    helper.showLoader();
    this.getEventApiCallId = await helper.apiCall({
      method: "GET",
      endPoint: `bx_block_events/events/${this.state.eventId}`,
      contentType: configJSON.validationApiContentType,
      token: this.state.token
    })
  }

  getInvitedEventsNonRegisteredMembers = (event: IEventData) => {
    const { invitation_members } = event.attributes
    if (invitation_members) {
      const invitedEventsMembersContact: ContactList[] = [];
      invitation_members.forEach((member: { [key: string]: string }) => {
        if(!helper.isValidEmail(member.invite_record)){
          invitedEventsMembersContact.push({
            name: member.contact_name || "Unknown",
            full_phone_number: member.invite_record,
            id: parseInt(member.id),
            email: "",
            isNonRegistered: true,
          })
        }
      })
      return invitedEventsMembersContact;
    }
    return []
  }

  handleGetEventDetailApiCall = (message: Message)=>{
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    helper.hideLoader();
    if(responseJson && responseJson.data){
      let emailSelectedInvites: Array<{id: number, full_name: string, email: string}> = [];
      const responseData = responseJson.data.attributes;
      responseData.invitation_members.map((member: { [key: string]: string }) => {
        if (helper.isValidEmail(member.invite_record)) {
          emailSelectedInvites.push({
            id: -1,
            full_name: "",
            email: member.invite_record
          })
        }
      })
      this.setState({
        selectedImage: responseData.image_url,
        title: responseData.title,
        eventDate: responseData.date_time_event,
        selectedLocation: responseData.location ? {
          description: "",
          place_id: responseData.location.place_id,
          structured_formatting: {
            main_text: responseData.location.city,
            secondary_text: responseData.location.country
          }
        } : null,
        venueDetails: responseData.venue_details,
        startTime: responseData.start_time_event,
        endTime: responseData.end_time_event,
        description: responseData.description,
        eventType: responseData.event_type,
        selectedEmailList: [
          ...responseData.assignee_email,
          ...emailSelectedInvites
        ],
        selectedContactList: [ ...responseData.user_contacts.filter((data:any)=>!data?.organizer).filter((contact: ContactList) => !contact.invited_by || contact.invited_by !== "email"), ...this.getInvitedEventsNonRegisteredMembers(responseJson.data)],
        eventCapacity: responseData.event_capacity ? responseData.event_capacity : ""
      })
    }else{
      this.redirectTo("Events")
    }
    
  }

  onEnterEmail = (event: React.KeyboardEvent<HTMLInputElement>)=>{
    const value = (event.target as HTMLInputElement).value;
    event.currentTarget.blur()
    const {
       selectedEmailList,
       selectedContactList,
       emailList
    } = this.state;
    //If user enter a invalid email
    if (!helper.isValidEmail(value)) {
      this.setState({
        emailErrorMessage: "Please enter a valid email",
        openEmailDropdown: false
      })
      return;
    }

    //If user enter a already selected email
    if (selectedEmailList.find(email => email.email === value) || selectedContactList.find(contact=> contact.email === value)) {
      this.setState({
        emailErrorMessage: "This email is already selected",
        openEmailDropdown: false
      })
      return;
    }

    // If user enters email which is already registered
    const registeredEmail = emailList.find((item) => item.email === value);
    let addedEmail = { email: value, id: -1, full_name: "" } ;
    if(registeredEmail) {
      addedEmail.email = registeredEmail.email;
      addedEmail.id = registeredEmail.id;
      addedEmail.full_name = registeredEmail.full_name;
    }

    this.setState(prevState => {
      return {
        selectedEmailList: [...prevState.selectedEmailList, { ...addedEmail }],
        openEmailDropdown: false,
        email: "",
        isFormUpdated: true,
        emailErrorMessage: ""
      }
    })
  }
  // Functions for web end

  // Customizable Area End
}

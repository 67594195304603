import React from "react";
import { styled } from "@material-ui/styles";
import { EditIcon, DeleteIcon } from "../assets";
import { IconButton } from "@material-ui/core";
import { IReminderItem, IReminders } from "../../../tasks/src/types";

interface DashboardReminderCardProps {
    reminder: IReminders;
    redirectTo: (endpoint: string, params?: {[key:string]: string})=> void;
    onDelete: (id?: string, title?: string)=> void;
    openReminderDetailsPopup?: (details: IReminderItem)=> void; 
}

const DashboardReminderCard: React.FC<DashboardReminderCardProps> = (props: DashboardReminderCardProps) => {
    const { reminder, redirectTo, onDelete, openReminderDetailsPopup } = props;
    const goToEditPage = (reminderId: string)=>{
        redirectTo("EditReminder", { id: reminderId })
    }
    return (
        <StyledDashboardReminderCard className="DashboardReminderCard_StyledDashboardReminderCard_Container">
           <header>{reminder.isToday ? `Today / ${reminder.desktop_date.split(' ')[1]}` : reminder.formatted_date}</header>
           <div className="reminders-for-particular-date">
              {
                reminder.reminders.map((reminderData, remInd)=> {
                    return (
                        <>
                            <div className={`reminder-card ${reminderData.attributes.reminder_status}`} key={remInd}>
                                <div className="details">
                                    <p className="title capital-text" onClick={()=> {
                                        if(reminderData.attributes.reminder_status !== "completed" && openReminderDetailsPopup){
                                            openReminderDetailsPopup(reminderData)
                                        }
                                    }}>{reminderData.attributes.title}</p>
                                    <p className="description">{reminderData.attributes.description}</p>
                                    <p className="time">
                                        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M9.31473 10.0153L10.0173 9.31278L7.49932 6.79466V3.66405H6.49935V7.19995L9.31473 10.0153ZM7.00045 13.3307C6.12449 13.3307 5.30114 13.1645 4.53038 12.832C3.75962 12.4996 3.08916 12.0484 2.51902 11.4785C1.94886 10.9086 1.49749 10.2385 1.1649 9.46805C0.83231 8.69763 0.666016 7.87445 0.666016 6.9985C0.666016 6.12254 0.832238 5.29918 1.16468 4.52843C1.49713 3.75766 1.94829 3.08721 2.51818 2.51706C3.08808 1.94691 3.75824 1.49554 4.52867 1.16295C5.29908 0.830358 6.12226 0.664062 6.99822 0.664062C7.87417 0.664062 8.69753 0.830285 9.46828 1.16273C10.239 1.49517 10.9095 1.94634 11.4796 2.51623C12.0498 3.08613 12.5012 3.75629 12.8338 4.52671C13.1664 5.29712 13.3327 6.12031 13.3327 6.99626C13.3327 7.87222 13.1664 8.69557 12.834 9.46633C12.5015 10.2371 12.0504 10.9076 11.4805 11.4777C10.9106 12.0479 10.2404 12.4992 9.47 12.8318C8.69959 13.1644 7.8764 13.3307 7.00045 13.3307ZM6.99933 12.3307C8.47711 12.3307 9.73544 11.8113 10.7743 10.7724C11.8132 9.73349 12.3327 8.47516 12.3327 6.99738C12.3327 5.5196 11.8132 4.26127 10.7743 3.22238C9.73544 2.18349 8.47711 1.66405 6.99933 1.66405C5.52155 1.66405 4.26322 2.18349 3.22433 3.22238C2.18544 4.26127 1.666 5.5196 1.666 6.99738C1.666 8.47516 2.18544 9.73349 3.22433 10.7724C4.26322 11.8113 5.52155 12.3307 6.99933 12.3307Z" fill="white" fill-opacity="0.5" />
                                        </svg>
                                        {reminderData.attributes.time_reminder}
                                    </p>
                                </div>
                                <div className="actions">
                                    <div>
                                        {
                                            !reminderData.attributes.lapsed_status && (
                                                <IconButton style={{ padding: "6px" }} onClick={(event) => {
                                                    event.stopPropagation();
                                                    if (reminderData.attributes.reminder_status !== "completed") {
                                                        goToEditPage(`${reminderData.id}`)
                                                    }
                                                }}>
                                                    <img src={EditIcon} />
                                                </IconButton>
                                            )
                                        }
                                    </div>
                                    <div>
                                        {
                                            !reminderData.attributes.lapsed_status && (
                                                <IconButton style={{ padding: "6px" }} onClick={(event) => {
                                                    event.stopPropagation();
                                                    if (reminderData.attributes.reminder_status !== "completed") {
                                                        onDelete(`${reminderData.id}`, reminderData.attributes.title)
                                                    }
                                                }}>
                                                    <img src={DeleteIcon} />
                                                </IconButton>
                                            )
                                        }
                                    </div>
                                </div>
                            </div>
                        </>
                    )
                })
              }
           </div>
        </StyledDashboardReminderCard>
    )
}

const StyledDashboardReminderCard = styled("div")({
    "&.DashboardReminderCard_StyledDashboardReminderCard_Container": {
        display: "flex",
        flexDirection: "column",
        gap: "0.5rem",
        "& > header": {
            color: "rgba(255, 255, 255, 0.8)",
            fontSize: "0.875rem",
            fontWeight: 400
        },
        "& > .reminders-for-particular-date": {
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
            "& > .reminder-card":{
                background: "linear-gradient(180deg, #23404B 0%, #19282F 100%)",
                border: "1px solid rgba(25, 181, 185, 0.27)",
                height: "5.5625rem",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                borderRadius: "1rem",
                width: "100%",
                padding: "1rem",
                boxSizing: "border-box",
                "& > .details":{
                    "& > .title":{
                        fontSize: "1rem",
                        color: "rgba(255, 255, 255, 1)",
                        fontWeight: 500,
                        cursor: "pointer",
                    },
                    "& > .description":{
                        fontSize: "0.75rem",
                        fontWeight: 400,
                        color: "rgba(255, 255, 255, 0.8)"
                    },
                    "& > .time":{
                        fontSize: "0.875rem",
                        fontWeight: 400,
                        color: "rgba(255, 255, 255, 0.8)",
                        display: "flex",
                        alignItems: "center",
                        gap: "0.3rem"
                    }
                },
                "& > .actions":{
                    height: "100%",
                    display: "flex",
                    gap: "0.5rem"
                },
                "&.completed":{
                    opacity: 0.5,
                    "& > .details":{
                        "& > .title":{
                            cursor: "not-allowed"
                        }
                    },
                    "& > .actions button":{
                        cursor: "not-allowed"
                    }
                }
            }
        }
    }
})


export default DashboardReminderCard;
// Customizable Area Start
export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const form = require("../assets/clarity_form-line.png");
export const GroupIcon = require("../assets/group-icon.svg");
export const CloseIcon = require("../assets/close.svg");
export const GroupFilledIcon = require("../assets/groupFilled.svg");
export const Group3Icon = require("../assets/group3.svg");
export const AddIcon = require("../assets/add.svg");
export const InvalidImg = require("../assets/invalid_image.png");
export const GreyClock = require("../assets/image_grey_clock.png");
export const RedCheck = require("../assets/check_1.5.png");
export const RedClose = require("../assets/close_1.5.png");
export const pendingUser = require("../assets/pending_user.png");
export const trendingGroupIcon = require("../assets/trending.png");
// Customizable Area End

import React, { useEffect } from "react";
import { styled } from "@material-ui/styles";
import GroupImage from "./GroupImage.web"
import { IGroup, ReverseGroupType } from "../types";
const { useInView } = require("react-intersection-observer");

interface Props {
    group: IGroup;
    onClick: (event: IGroup)=> void;
    selected?: boolean;
    isLastElement: boolean;
    handleFetchNextPageGroups?: () => void;
}


const GroupCard: React.FC<Props> = (props: Props) => {
    const { group, onClick, selected, isLastElement, handleFetchNextPageGroups } = props;
    const [endOfGroup, isEndOfGroupVisible] = useInView({
        threshold: 0,
    });

    useEffect(() => {
        if(isEndOfGroupVisible){
            handleFetchNextPageGroups?.()
        }
    }, [isEndOfGroupVisible])
    
    return (
        <StyledGroupCard ref={isLastElement ? endOfGroup : null} className={`GroupCard_StyledGroupCard ${selected ? "selected" : ""}`} onClick={()=> onClick(group)}>
            <GroupImage image_url={group.image_url} imageContainerStyles={{borderRadius: "100%"}} />
            <p className="name ellipsis">{group.name}</p>
            <p className="type">{ReverseGroupType[group.group_type]}</p>
        </StyledGroupCard>
    )
}

const StyledGroupCard = styled("div")({
  "&.GroupCard_StyledGroupCard":{
    width: "5.25rem",
    minWidth: "5.25rem",
    height: "5.875rem",
    borderRadius: "0.5rem",
    display: "flex",
    flexDirection: "column",
    gap: "0.3rem",
    justifyContent: "center",
    alignItems: "center",
    boxSizing: "border-box",
    cursor: "pointer",
    transition: "background-color 0.3s ease-in-out",
    userSelect: "none",
    "&.selected":{
        backgroundColor: "rgba(0, 0, 0, 0.15)"
    },
    "& .name":{
       fontSize: "0.75rem",
       fontWeight: 400,
       color: "rgba(255, 255, 255, 1)",
       maxWidth: "4rem",
       textTransform: "capitalize",
    },
    "& .type":{
       fontSize: "0.5rem",
       fontWeight: 500,
       color: "rgba(247, 83, 103, 1)"
    },
    "&:hover":{
        backgroundColor: "rgba(0, 0, 0, 0.15)"
    }
  }
})

export default GroupCard;
const {
    HelperFunctions: helper,
} = require("../../../components/src/HelperFunctions");
const configJSON = require("./config.js");
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { Message } from "../../../framework/src/Message";


interface IApiCallData {
    token: string | null,
    page_no?: number;
    per_page?: number;
    query?: string;
    body?: any;
    endPoint?: string;
}

export const actions = {};

export const fetchInterests = async (apiCallData: IApiCallData) => {
    const {
      token
    } = apiCallData;
    return await helper.apiCall({
        method: "GET",
        contentType: configJSON.validationApiContentType,
        token: token,
        endPoint: configJSON.interestApiEndPoint
    })
}

export const handleFetchInterestsAPICall = (message: Message) => {
    const responseData = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (responseData && responseData.data) {
        return {
            data: responseData.data
        }
    } else {
        return {
            data: []
        }
    }
}

export const onSubmitNewInterest = async (apiCallData: IApiCallData) => {
    const {
      body
    } = apiCallData;
    return await helper.apiCall({
        contentType: configJSON.validationApiContentType,
        method: "POST",
        endPoint: configJSON.addNewInterestApiEndPoint,
        body
    })
}

export const handleAddNewInterestApiCall = (message: Message) => {
    const response = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (response && response.data) {
        const modifiedResponseData = response.data;
        modifiedResponseData.attributes.manual = true;
        return {
            data: modifiedResponseData
        }
    }

    return null;
}

export const getFilteredRoutineList = async (apiCallData: IApiCallData) => {
    const {
      token,
      query
    } = apiCallData;
    return await helper.apiCall({
        method: "GET",
        contentType: configJSON.validationApiContentType,
        token: token,
        endPoint: `bx_block_custom_form/tasks/filter_by_date?${query}`
    })
}

export const handleGetRoutineListApiCall = (message: Message) => {
    const responseData = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if(responseData && responseData.tasks && responseData.tasks.data){
        return {
            data: responseData.tasks.data
        }
    }else{
        return {
            data: []
        }
    }
}

export const deleteRoutine = async (apiCallData: IApiCallData) => {
    const {
      token,
      query
    } = apiCallData;
    return await helper.apiCall({
        method: "DELETE",
        endPoint: `bx_block_custom_form/tasks/${query}`,
        contentType: configJSON.validationApiContentType,
        token: token
    })
}

export const handleDeleteRoutine = (message: Message) => {
    const responseData = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if(responseData && responseData.message){
        return {
            message: responseData.message
        }
    }else{
        return {
            error: "Something went wrong please try again"
        }
    }
}
// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { v4 as uuidv4 } from "uuid";
import _ from "lodash";
export const configJSON = require("./config.js");
import { getStorageData } from "../../../framework/src/Utilities";
import { IAddPost, IComment, IEventData, IMentionInputType, IMetaPagination, IPost } from "../../../components/src/CommonTypes";
const {
  HelperFunctions: helper,
} = require("../../../components/src/HelperFunctions");
import { getEventDetail, handleEventDetailCall } from "../../events/src/actions";
// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export interface S {
  // Customizable Area Start
  token: string | null;
  postsList: Array<IPost>;
  eventId: string;
  selectedPostDetail: IAddPost | null;
  userId: number | undefined;
  lastElementRef: React.RefObject<HTMLInputElement> | null;
  isEdit: boolean;
  confirmationPostDeletePopUpData: {
    header: string;
    message: string;
    type: string;
    eventId: string;
  } | null;
  perPage: number;
  page: number;
  isAllPostFetched: boolean;
  deletedPostId: string | number;
  currentLikedPostId: string;
  likedUserList: { id: string | number, attributes: { like_by_name: string} }[] | undefined;
  currentCommentModal: {
    id: string | number;
  } | null;
  commentsCurrentPage: number;
  currentCommentList: IComment[];
  isLoadingComments: boolean;
  isAllCommentsFetched: boolean;
  currentUploadingPostDetails: IAddPost | null;
  currentUploadingBlobIds: number[];
  isPostUploading: boolean;
  numberOfFilesUploaded: number;
  currentSelectedCommentId: string | number;
  searchedMentionsList: IMentionInputType[];
  eventDetails: IEventData | null;
  postCommentsMetaData: IMetaPagination | null;
  currentLikedCommentId: string | number;
  // Customizable Area End
}

export interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class AllPostsListController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getAllPostsListApiId: string = "";
  createPostApiId: string = "";
  editPostApiId: string = "";
  deletePostApiCallId: string = "";
  likePostApiCallId: string = "";
  getCommentsApiCallId: string = "";
  createCommentApiCallId: string = "";
  addCommentApiCallId: string = "";
  deleteCommentApiCallId: string = "";
  uploadFileApiCallId: string[] = [];
  removeAttachmentFromPostApiCallId: string = "";
  likedUsersListApiCallId: string = "";
  searchMentionsApiCallId: string = "";
  apiGetEventDetails: string = "";
  likeDislikeCommentApiCallId: string = "";
  commentLikedUsersListApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      token: "",
      postsList: [],
      eventId: "",
      selectedPostDetail: null,
      userId: undefined,
      lastElementRef: null,
      isEdit: false,
      confirmationPostDeletePopUpData: null,
      perPage: 3,
      page: 1,
      isAllPostFetched: false,
      deletedPostId: "",
      currentLikedPostId: "",
      likedUserList: undefined,
      currentCommentModal: null,
      commentsCurrentPage: 1,
      currentCommentList: [],
      isLoadingComments: false,
      currentUploadingPostDetails: null,
      currentUploadingBlobIds: [],
      isPostUploading: false,
      numberOfFilesUploaded: 0,
      isAllCommentsFetched: false,
      currentSelectedCommentId: "",
      searchedMentionsList: [],
      eventDetails: null,
      postCommentsMetaData: null,
      currentLikedCommentId: "",
      // Customizable Area End
    };

    // Customizable Area Start
    this.handleChangePageNumber = this.handleChangePageNumber.bind(this);

    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    await super.componentDidMount();
    this.setToken();
    // Customizable Area Start
    this.setEventId();
    (async () => {
      const userData = await helper.getUserData();
      if (userData) {
        this.setState({ userId: userData.id });
      }
    })();
    // Customizable Area End
  }

  // Customizable Area Start

  setEventId = () => {
    const eventId = this.props.navigation?.getParam("id");
    this.setState({ eventId });
  };

  receive = async (from: string, message: Message) => {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const webApiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      switch (webApiRequestCallId) {
        case this.getAllPostsListApiId:
          this.handleGetPostListCall(message);
          break;
        case this.createPostApiId:
          this.handleCreatePostCall(message);
          break;
        case this.editPostApiId:
          this.handleEditPostCall(message);
          break;
        case this.deletePostApiCallId:
          this.handleDeletePostCall(message);
          break;
        case this.likePostApiCallId:
          this.likePostApiCallHandler(message);
          break;
        case this.getCommentsApiCallId:
          this.handleGetCommentsApiCall(message);
          break;
        case this.addCommentApiCallId:
          this.handleAddCommentApiCall(message);
          break;
        case this.deleteCommentApiCallId:
          this.handleDeleteCommentApiCall(message);
          break;
        case this.likedUsersListApiCallId:
          this.handleGetPostLikedUsersListCall(message);
          break;
        case this.removeAttachmentFromPostApiCallId:
          this.handleDeleteAttachmentFromPostCall(message);
          break;
        case this.searchMentionsApiCallId:
          this.handleGetMentionsListCall(message);
          break;
        case this.apiGetEventDetails:
          this.handleEventDetailCall(message);
          break;
        case this.likeDislikeCommentApiCallId:
          this.likeCommentApiCallHandler(message);
          break;
        case this.commentLikedUsersListApiCallId:
          this.handleGetCommentLikedUsersListCall(message);
          break;
      }

      if (this.uploadFileApiCallId.includes(webApiRequestCallId)) {
        this.handlerUploadFileApiCall(message);
      }
    }

    // Customizable Area End
  };

  setToken = async () => {
    const token = await getStorageData("authToken");
    this.setState({ token }, () => {
      this.getPostsList();
      this.getEventDetail();
    });
  };

  openPostDeleteModal = (title: string, eventId: string | number) => {
    this.setState({
      confirmationPostDeletePopUpData: {
        header: "Delete Post?",
        message: `Are you sure that you want to delete this ${title}?`,
        type: "delete",
        eventId: eventId as string,
      },
      deletedPostId: eventId,
    });
  };

  onCloseDeleteConfirmationModal = () => {
    this.setState({
      confirmationPostDeletePopUpData: null,
    });
  };

  onAcceptPostDeleteConfirmation = async () => {
    if (this.state.confirmationPostDeletePopUpData) {
      const { type, eventId } = this.state.confirmationPostDeletePopUpData;
      if (type === "delete") {
        this.onDeletePost(eventId);
      }
    }
  };

  handleDeletePostCall = (message: Message) => {
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (!responseJson?.errors) {
      helper.showSuccessToast("Post deleted successfully!");
      const { postsList, deletedPostId } = this.state;
      const updatedPostsList = postsList.filter(
        (item) => item.id !== deletedPostId
      );
      this.setState({ postsList: _.uniqBy(updatedPostsList, 'id') });
    } else {
      const errorDelToastMsg = responseJson?.errors?.[0]?.message
        ? responseJson.errors[0].message
        : "Something went wrong, please try again!";
      helper.showErrorToast(errorDelToastMsg);
    }
    this.setState({ deletedPostId: "" });
    helper.hideLoader();
  };

  onDeletePost = async (postId: string | number) => {
    this.setState({
      confirmationPostDeletePopUpData: null,
    });
    const { eventId } = this.state;
    helper.showLoader();
    this.deletePostApiCallId = await helper.apiCall({
      method: configJSON.deleteApiMethod,
      endPoint: `${configJSON.postsEndpoint}/${postId}?event_id=${eventId}`,
      contentType: configJSON.apiContentType,
      token: this.state.token,
    });
  };

  handleDeleteAttachmentFromPostCall = (message: Message) => {
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (responseJson?.errors) {
      const errorDelToastMsg = responseJson?.errors?.[0]?.message
        ? responseJson.errors[0].message
        : "Something went wrong while removing the file, please try again!";
      helper.showErrorToast(errorDelToastMsg);
    } 
  };

  onRemoveAttachmentFromPost = async (postId: string | number, attachmentBlobId: number) => {
    const { eventId } = this.state;
    this.removeAttachmentFromPostApiCallId = await helper.apiCall({
      method: configJSON.deleteApiMethod,
      endPoint: `${configJSON.removeAttachmentEndpoint}?event_id=${eventId}&blob_id=${attachmentBlobId}&id=${postId}`,
      contentType: configJSON.apiContentType,
      token: this.state.token,
    });
  };

  handleChangePageNumber = () => {
    if (!this.state.isAllPostFetched) {
      this.setState({ page: this.state.page + 1 }, () => this.getPostsList());
    }
  };

  handleChangeCommentsPageNumber = (postId: string) => {
      this.setState({ commentsCurrentPage: this.state.commentsCurrentPage + 1}, () => this.getComments(postId))
  }

  handleGetPostListCall = (message: Message) => {
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (!responseJson?.meta?.next_page) {
      this.setState({ isAllPostFetched: true });
    }
    if (responseJson?.data) {
      this.setState({
        postsList: _.uniqBy([...this.state.postsList, ...responseJson.data], "id"),
      });
    } else {
      this.setState({ postsList: [] });
    }
    helper.hideLoader();
  };

  getPostsList = async () => {
    helper.showLoader();
    const eventId = this.props.navigation?.getParam("id");
    this.getAllPostsListApiId = await helper.apiCall({
      method: configJSON.apiMethod,
      contentType: configJSON.apiContentType,
      token: this.state.token,
      endPoint: `${configJSON.postsEndpoint}?event_id=${eventId}&per_page=15&page_no=${this.state.page}`,
    });
  };

  getFileType = (url: string) => {
    if (url.includes("type=image")) {
      return "image";
    } else if (url.includes("type=application")) {
      return "pdf";
    }
    return "video";
  };

  onOpenEditPostModal = (post: IPost) => {
    const files: Array<{
      selectedUrl: string;
      imgInvalid: boolean;
      type: string;
      blobId?: number;
    }> = post.attributes.images_and_videos.map((item) => {
      return {
        selectedUrl: item.url,
        type: this.getFileType(item.url),
        imgInvalid: false,
        blobId: item.blob_id,
        uniqId: uuidv4(),
      };
    });
    this.setState({
      selectedPostDetail: {
        postText: post.attributes.body,
        postImages: files,
        id: post.id,
      },
      isEdit: true,
    });
  };

  onOpenCreatePostModal = () => {
    this.setState({
      selectedPostDetail: {
        postText: "",
        postImages: [],
        postImgFiles: [],
      },
      isPostUploading: false,
    });
  };

  onCloseCreatePostModal = () => {
    this.setState({
      selectedPostDetail: null,
      isEdit: false,
      isPostUploading: false,
      numberOfFilesUploaded: 0,
    });
  };

  handleEditPostCall = (message: Message) => {
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (!responseJson?.errors && responseJson?.data) {
      const { postsList } = this.state;
      const updatedPosts = postsList?.map((item) => {
        if (item.id === responseJson.data?.id) {
          item = { ...responseJson.data };
        }
        return item;
      });
      this.setState({ postsList: updatedPosts });
    } else {
      const editErrorMsg = responseJson?.errors?.[0]?.message
        ? responseJson.errors[0].message
        : "Something went wrong, please try again!";
      helper.showErrorToast(editErrorMsg);
    }
    helper.hideLoader();
  };

  handleCreatePostCall = (message: Message) => {
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (!responseJson?.errors && responseJson?.data) {
      const { postsList } = this.state;
      this.setState({ postsList:  _.uniqBy([{ ...responseJson.data }, ...postsList], "id") });
    } else {
      const errorMsg = responseJson?.errors?.[0]?.message
        ? responseJson.errors[0].message
        : "Something went wrong, please try again!";
      helper.showErrorToast(errorMsg);
    }
    helper.hideLoader();
  };

  onCreatePost = async () => {
    const {
      eventId,
      isEdit,
      currentUploadingPostDetails,
      currentUploadingBlobIds,
    } = this.state;
    this.onCloseCreatePostModal();
    helper.showLoader();
    const body: { body?: string; event_id?: string; blob_ids?: string[] } = {};
    const formData = new FormData();
    if(currentUploadingPostDetails){
      formData.append("body", currentUploadingPostDetails.postText)
    }
    formData.append("event_id", eventId)
    if(currentUploadingBlobIds.length) {
      formData.append("blob_ids", `[${JSON.stringify(currentUploadingBlobIds.join(","))}]`)
    }
    if (currentUploadingPostDetails) {
      body.body = currentUploadingPostDetails.postText;
    }
    body.event_id = eventId;
    if (currentUploadingBlobIds.length) {
      const blobIds = currentUploadingBlobIds.join(",");
      body.blob_ids = [blobIds];
    }

    if (!isEdit) {
      this.createPostApiId = await helper.apiCall({
        method: configJSON.postApiMethod,
        contentType: configJSON.apiContentType,
        token: this.state.token,
        endPoint: `${configJSON.postsEndpoint}`,
        body,
      });
    } else {
      this.editPostApiId = await helper.apiCall({
        method: configJSON.putApiMethod,
        token: this.state.token,
        type: "formData",
        endPoint: `${configJSON.postsEndpoint}/${currentUploadingPostDetails?.id}`,
        body: formData,
      });
    }
    this.setState({
      isEdit: false,
      selectedPostDetail: null,
      currentUploadingPostDetails: null,
      currentUploadingBlobIds: [],
      numberOfFilesUploaded: 0,
    });
    this.uploadFileApiCallId = [];
  };

  onClickSharePost = async (postDetails: IAddPost) => {
    this.setState(
      {
        currentUploadingPostDetails: postDetails,
        isPostUploading: true,
      },
      () => {
        if (postDetails?.postImgFiles?.length) {
          postDetails.postImgFiles.forEach(async (file) => {
            const formData = new FormData();
            formData.append("media", file);

            const apiId = await helper.uploadFilesToServer({
              token: this.state.token,
              body: formData,
              type: "formData",
            });
            this.uploadFileApiCallId.push(apiId);
          });
        } else {
          this.onCreatePost();
        }
      }
    );
  };

  handlerUploadFileApiCall = (message: Message) => {
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (responseJson && responseJson.blob_id) {
      this.setState(
        (prevState) => {
          return {
            currentUploadingBlobIds: [
              ...prevState.currentUploadingBlobIds,
              responseJson.blob_id,
            ],
          };
        },
        () => {
          const { currentUploadingBlobIds, currentUploadingPostDetails } =
            this.state;
          if (
            currentUploadingPostDetails &&
            this.state.numberOfFilesUploaded ===
              currentUploadingBlobIds.length
          ) {
            this.onCreatePost();
          }
        }
      );
    }
  };

  onLikePost = async (postId: string) => {
    const body = {
      likeable_id: postId,
      like_type: "post",
    };
    this.likePostApiCallId = await helper.apiCall({
      method: configJSON.postApiMethod,
      contentType: configJSON.apiContentType,
      token: this.state.token,
      endPoint: `${configJSON.likePostEndPoint}`,
      body,
    });
    this.setState({
      currentLikedPostId: postId,
    });
  };

  likePostApiCallHandler = (message: Message) => {
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (responseJson && (responseJson.message || responseJson.data)) {
      const { currentLikedPostId, postsList } = this.state;
      const updatedPostList = postsList.map((post) => {
        if (post.id === currentLikedPostId) {
          if (post.attributes.liked_by_me) {
            post.attributes.total_likes -= 1;
          } else {
            post.attributes.total_likes += 1;
          }
          post.attributes.liked_by_me = !post.attributes.liked_by_me;
        }
        return post;
      });
      this.setState({
        postsList: updatedPostList,
      });
    } else {
      const likeCommentErrMsg = responseJson?.errors?.[0]?.message
        ? responseJson.errors[0].message
        : "Something went wrong, please try again!";
      helper.showErrorToast(likeCommentErrMsg);
    }
    helper.hideLoader();
  };

  handleGetPostLikedUsersListCall = (message: Message) => {
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (responseJson?.data?.data) {
      this.setState({
        likedUserList: [...responseJson.data.data],
      });
    } else {
      this.setState({ likedUserList: [] });
    }
    helper.hideLoader();
  };

  getLikedUsersList = async(postId: number | string) => {
    helper.showLoader();
    this.likedUsersListApiCallId = await helper.apiCall({
      method: configJSON.apiMethod,
      contentType: configJSON.apiContentType,
      token: this.state.token,
      endPoint: `${configJSON.likedUsersListApiEndpoint}?post_id=${postId}`,
    });
  }

  setLikedUserList = () => {
    this.setState({
      likedUserList: undefined,
    });
  };

  onLikeComment = async (commentId: string | number) => {
    const body = {
      likeable_id: commentId,
      like_type: "comment",
    };
    this.likeDislikeCommentApiCallId = await helper.apiCall({
      method: configJSON.postApiMethod,
      contentType: configJSON.apiContentType,
      token: this.state.token,
      endPoint: `${configJSON.likePostEndPoint}`,
      body,
    });
    this.setState({
      currentLikedCommentId: commentId,
    });
  };

  likeCommentApiCallHandler = (message: Message) => {
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (responseJson && (responseJson.message || responseJson.data)) {
      const { currentLikedCommentId, currentCommentList } = this.state;
      const updatedCommentsList = currentCommentList.map((comment) => {
        if (comment.id === currentLikedCommentId) {
          if (comment.attributes.liked_by_me) {
            comment.attributes.total_likes -= 1;
          } else {
            comment.attributes.total_likes += 1;
          }
          comment.attributes.liked_by_me = !comment.attributes.liked_by_me;
        }
        return comment;
      });
      this.setState({
        currentCommentList: updatedCommentsList,
      });
    }
    helper.hideLoader();
  };

  handleGetCommentLikedUsersListCall = (message: Message) => {
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (responseJson?.data?.data) {
      this.setState({
        likedUserList: [...responseJson.data.data],
      });
    } else {
      this.setState({ likedUserList: [] });
    }
    helper.hideLoader();
  };

  getCommentLikedUsersList = async(commentId: number | string) => {
    helper.showLoader();
    this.commentLikedUsersListApiCallId = await helper.apiCall({
      contentType: configJSON.apiContentType,
      method: 'GET',
      token: this.state.token,
      endPoint: `${configJSON.commentsLikedListUsers}?likeable_id=${commentId}`,
    });
  }

  getComments = async (postId: string | number) => {
    this.setState({
      isLoadingComments: true,
    });
    this.getCommentsApiCallId = await helper.apiCall({
      method: configJSON.apiMethod,
      contentType: configJSON.apiContentType,
      token: this.state.token,
      endPoint: `${configJSON.commentsApiEndpoint}?page_no=${this.state.commentsCurrentPage}&per_page=15&commentable_id=${postId}&order=desc`,
    });
  };

  handleGetCommentsApiCall = (message: Message) => {
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (responseJson?.meta) {
      this.setState({ postCommentsMetaData: responseJson?.meta })
    }
    if (responseJson && responseJson.data) {
      this.setState({
        currentCommentList: _.uniqBy([ ...this.state.currentCommentList, ...responseJson.data], "id")
      })
    }
    this.setState({
      isLoadingComments: false,
    });
  };

  handleGetMentionsListCall = (message: Message) => {
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (responseJson?.data) {
      const mentionsList: Array<IMentionInputType> = responseJson.data?.map((item: { attributes: { account_info: { id: number, user_name: string, name: string}}}) => {
        return {
          id: item.attributes.account_info.id.toString(),
          display: item.attributes.account_info.user_name,
        }
      })
      this.setState({
        searchedMentionsList: [ ...mentionsList],
      });
    } else {
      this.setState({ searchedMentionsList: [] });
    }
  };

  getMentionsList = async() => {
    const { eventId } = this.state;
    this.searchMentionsApiCallId = await helper.apiCall({
      method: configJSON.apiMethod,
      contentType: configJSON.apiContentType,
      token: this.state.token,
      endPoint: `${configJSON.searchMention}?event_id=${eventId}`,
    });
  }

  openCommentsModal = async(postId: string) => {
    const postsList = this.state.postsList.map((item) => {
      if (item.id === postId) {
        item.attributes.show_comments_section = true
      } else {
        item.attributes.show_comments_section = false
      }
      return item;
    })    
    this.setState({
      postsList,
      currentCommentModal: {
        id: postId,
      }, currentCommentList: [], commentsCurrentPage: 1, postCommentsMetaData: null
    }, async () => {
      await this.getComments(postId);
      this.getMentionsList();
    })
  };

  closeCommentsModal = (postId: number | string) => {
    const postsList = this.state.postsList.map((item) => {
      if (item.id === postId) {
        item.attributes.show_comments_section = false
      }
      return item;
    })
    this.setState({postsList, currentCommentModal: null, currentCommentList: [], commentsCurrentPage: 1, postCommentsMetaData: null, isAllCommentsFetched: false, })
  };

  handleUpdatePostCommentsReplyCount = (commentId: number | string, action?: string) => {
    const updatedCommentsList = this.state.currentCommentList.map((item) => {
      if (item.id === commentId) {
        if (action === "decrease") {
          item.attributes.reply_count -= 1
        } else {
          item.attributes.reply_count += 1
        }
      }
      return item
    })
    this.setState({ currentCommentList: updatedCommentsList })
  }

  handleShowReplySection = (commentId: number | string) => {
    const updatedCommentsList = this.state.currentCommentList.map((item) => {
      if(item.attributes) {
        if (item.id === commentId) {
          item.attributes.show_replies_section = true
        } else {
          item.attributes.show_replies_section = false
        }
      }
      return item
    })
    this.setState({ currentCommentList: updatedCommentsList })
  }

  handleHideReplySection = (commentId: number | string) => {
    const updatedCommentsList = this.state.currentCommentList.map((item) => {
      if (item.id === commentId) {
        item.attributes.show_replies_section = false
      }
      return item
    })
    this.setState({ currentCommentList: updatedCommentsList })
  }

  scrollToTopOnAddComment = () => {
    const topDiv = document.getElementById("top_comment_div");
    if (topDiv) {
      topDiv.scrollTo({
        top: 0,
        behavior: "smooth"
      })
    }
  }

  onAddComment = async (comment: { text: string; postId: string }) => {
    this.setState({
      isLoadingComments: true,
    });
    const body = {
      comment: {
        commentable_id: comment.postId,
        commentable_type: "BxBlockPosts::Post",
        comment: comment.text,
      },
    };
    this.addCommentApiCallId = await helper.apiCall({
      method: configJSON.postApiMethod,
      contentType: configJSON.apiContentType,
      token: this.state.token,
      endPoint: `${configJSON.commentsApiEndpoint}`,
      body,
    });
  };

  handleAddCommentApiCall = (message: Message) => {
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (responseJson && responseJson.data) {
      const { currentCommentModal, postsList, currentCommentList } = this.state;
      let updatedCommentsList = [{ ...responseJson.data}, ...currentCommentList];
      const updatedPostList = postsList.map((post) => {
        if (post.id === currentCommentModal?.id) {
          post.attributes.comments_count += 1;
        }
        return post;
      });
      this.setState({
        currentCommentList: _.uniqBy(updatedCommentsList, "id"),
        postsList: updatedPostList,
      });
      this.scrollToTopOnAddComment();
    } else {
      helper.showErrorToast("Something went wrong! please try again");
    }
    this.setState({
      isLoadingComments: false,
    });
  };

  onDeleteComment = async (commentId: string | number) => {
    this.setState({
      isLoadingComments: true,
      currentSelectedCommentId: commentId,
    });
    const { eventId, token, currentCommentModal } = this.state;
    this.deleteCommentApiCallId = await helper.apiCall({
      method: configJSON.deleteApiMethod,
      endPoint: `${configJSON.commentsApiEndpoint}/${commentId}?event_id=${eventId}&commentable_id=${currentCommentModal?.id}`,
      contentType: configJSON.apiContentType,
      token: token,
    });
  };

  handleDeleteCommentApiCall = (message: Message) => {
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (responseJson && responseJson.message) {
      const { currentCommentModal, postsList, currentCommentList, currentSelectedCommentId } = this.state;
      let updatedCommentsList = currentCommentList.filter((item) => item.id !== currentSelectedCommentId);
      const updatedPostList = postsList.map((post) => {
        if (post.id === currentCommentModal?.id) {
          post.attributes.comments_count -= 1;
        }
        return post;
      });

      this.setState({
        currentCommentList: _.uniqBy(updatedCommentsList, "id"),
        postsList: updatedPostList,
      });
    } else {
      helper.showErrorToast("Something went wrong! please try again");
    }
    this.setState({
      isLoadingComments: false,
      currentSelectedCommentId: "",
    });
  };

  handleEventDetailCall = (message:Message) => {
    const responseJson = handleEventDetailCall(message);
    this.setState({
      eventDetails: responseJson.data
    })
    helper.hideLoader();
  }

  getEventDetail = async ()=> {
    helper.showLoader();
    if(this.props.navigation){
      const eventID = this.props.navigation.getParam("id");
      this.apiGetEventDetails = await getEventDetail({
        token: this.state.token,
        endPoint: eventID
      })
    }
  }

  showAddPostButton = () => {
    const {
      eventDetails
    } = this.state;

    if(eventDetails){
      const isPresent = this.isUserPresentInContactList();
      const result = isPresent && eventDetails.attributes.rsvp_status !== "rejected";
      return result;
    }
    
    return false;
  }

  isUserPresentInContactList = () => {
    let present = false;
    
    const {
      eventDetails,
      userId
    } = this.state;
    if(eventDetails && userId){
      eventDetails.attributes.user_contacts.forEach(contact => {
        if (contact.id === userId) {
          present = true;
        }
      })
    }

    return present;
  }

  redirectTo = (endpoint: string, params?: { [key: string]: string | number }) => {
    this.props.navigation.navigate(endpoint, params)
  }

  // Customizable Area End
}

import React, { useState, useEffect } from "react";
import { styled } from "@material-ui/styles";
import { IGroup } from "../types";
import { Button } from "../../../../components/src";
import GroupImage from "./GroupImage.web";
import GroupPostCreation from "../../../postcreation/src/GroupPostCreation.web";
import GroupInfo from "../GroupInfo.web";
import { Box, CircularProgress, Grid, IconButton } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { CloseIcon } from "../assets";
const { HelperFunctions: helper } = require("../../../../components/src/HelperFunctions");

interface Props {
    selectedGroup: IGroup | null;
    redirectTo: (endpoint: string, params?:{[key:string]:string | number})=> void;
    onDeleteGroup?: ()=> void;
    handleJoinGroup?: (groupId: number | undefined) => void;
    matchesMediumScreenSizeQuery: boolean;
    handleUnSelectGroup?: () =>  void;
    onAcceptRejectPendingRequest?: () => void;
    handleFetchPendingRequestCount?: () => void;
}


const GroupDetail: React.FC<Props> = (props: Props) => {
    const { selectedGroup, redirectTo, onDeleteGroup, handleJoinGroup, matchesMediumScreenSizeQuery, handleUnSelectGroup, onAcceptRejectPendingRequest, handleFetchPendingRequestCount } = props;
    const [openGroupInfo, setOpenGroupInfo] = useState(false);
    const theme = useTheme();
    const [isDetailLoading, setIsDetailLoading] = useState(false);
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

    const NoGroupSelectedMessage = () => {
        return (
            <StyledNoGroupSelectedMessage className="Groups_NoGroupSelectedMessage_StyledNoGroupSelectedMessage">
                <div className="message">
                    <p>Send messages to the friend or group</p>
                    <Button
                        text="Add New Group"
                        type="outlined"
                        buttonId="add-new-group"
                        buttonStyles={{ height: "2.25rem", fontSize: "1rem" }}
                        onClick={()=> redirectTo("AddGroup")}
                    />
                </div>
            </StyledNoGroupSelectedMessage>
        )
    }
    const GroupDetailComponent = () => {
        return (
            <StyledGroupDetailComponent className="Groups_GroupDetailComponent_StyledGroupDetailComponent">
                <Grid container direction="row">
                    {!(isSmallScreen && openGroupInfo) && <Grid item xs={12} sm={12} md={12} lg={(openGroupInfo && selectedGroup) ? 6 : 12}>
                        <div className={`group-details ${openGroupInfo ? "info-open" : ""}`}>
                            <div className="header">
                                <Box className="cursor-pointer" id="group-detail-name" onClick={() => {
                                    setOpenGroupInfo(true);
                                    handleFetchPendingRequestCount?.()
                                }} display={"flex"} alignItems={"center"}>
                                    {matchesMediumScreenSizeQuery && <IconButton onClick={handleUnSelectGroup}>
                                        <img src={CloseIcon} />
                                    </IconButton>}
                                    <GroupImage image_url={selectedGroup?.image_url} />
                                    <div className="name ellipsis cursor-pointer">{openGroupInfo ? helper.truncateText(selectedGroup?.name, 20) : helper.truncateText(selectedGroup?.name, 42)}</div>
                                </Box>
                               {!selectedGroup?.is_admin && !selectedGroup?.is_current_user_member && selectedGroup?.group_type === "public_group" && <Button type="outlined" buttonId="group-pending-request" onClick={() => handleJoinGroup?.(selectedGroup?.id)} text="Join Group" />}
                            </div>
                            <div className="details">
                                <GroupPostCreation redirectTo={redirectTo} handleSetIsDetailLoading={(value: boolean) => setIsDetailLoading(value)} groupDetail={selectedGroup} />
                                {isDetailLoading && <Box id="group-post-loader" className="common-circular-progress-loader">
                                    <CircularProgress />
                                </Box>}
                            </div>
                        </div>
                    </Grid>}
                    {
                        openGroupInfo && selectedGroup && (
                            <Grid item xs={12} sm={12} md={12} lg={6}>
                                <div className="group-info">
                                    <GroupInfo navigateTo={redirectTo} onAcceptRejectPendingRequest={onAcceptRejectPendingRequest} groupDetail={selectedGroup} onClose={() => setOpenGroupInfo(false)} onDeleteGroup={onDeleteGroup} />
                                </div>
                            </Grid>
                        )
                    }
                </Grid>
            </StyledGroupDetailComponent>
        )
    }
    useEffect(()=>{
      setOpenGroupInfo(false)
    },[selectedGroup])
    return (
        <StyledGroupDetail className="Groups_GroupDetail_StyledGroupDetail">
            {
                !(selectedGroup || matchesMediumScreenSizeQuery) && (
                    NoGroupSelectedMessage()
                )
            }
            {
                selectedGroup && (
                    GroupDetailComponent()
                )
            }
        </StyledGroupDetail>
    )
}

const StyledGroupDetail = styled("div")({
    "&.Groups_GroupDetail_StyledGroupDetail": {
        display: "flex",
        height: "100%",
        boxSizing: "border-box"
    }
})

const StyledNoGroupSelectedMessage = styled("div")({
    "&.Groups_NoGroupSelectedMessage_StyledNoGroupSelectedMessage": {
        display: "flex",
        height: "100%",
        background: "rgba(35, 64, 75, 1)",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        boxSizing: "border-box",
        borderRadius: "0.5rem",
        "& .message":{
            display: "flex",
            flexDirection: "column",
            gap: "0.5em",
            justifyContent: "center",
            alignItems: "center",
            flex: 1,
            "& > p":{
                fontSize: "1rem",
                fontWeight: 400,
                color: "rgba(255, 255, 255, 0.8)"
            }
        }
    }
})

const StyledGroupDetailComponent = styled("div")({
    "&.Groups_GroupDetailComponent_StyledGroupDetailComponent": {
        display: "flex",
        height: "100%",
        width: "100%",
        boxSizing: "border-box",
        borderRadius: "0.5rem",
        background: "rgba(35, 64, 75, 1)",
        "& .group-details": {
            display: "flex",
            height: "100%",
            width: "100%",
            boxSizing: "border-box",
            flexDirection: "column",
            "& .header": {
                display: "flex",
                alignItems: "center",
                width: "100%",
                height: "4.75rem",
                gap: "0.5rem",
                padding: "0rem 1rem",
                borderBottom: "1px solid rgba(255, 255, 255, 0.1)",
                boxSizing: "border-box",
                justifyContent: "space-between",
                "& .name": {
                    fontWeight: 500,
                    color: "rgba(255, 255, 255, 1)",
                    fontSize: "1.125rem",
                    boxSizing: "border-box",
                    textTransform: "capitalize",
                    marginLeft: "5px",
                }
            },
            "& .details": {
                display: "flex",
                flex: 1,
                position: "relative"
                // width: "100%",
                // boxSizing: "border-box",
            },
            "&.info-open":{
                // width: "50%",
                borderRight: "1px solid rgba(255, 255, 255, 0.1)"
            }
        },
        "& .group-info":{
            display: "flex",
            height: "100%",
            // width: "50%",
            boxSizing: "border-box",
            flexDirection: "column",
        }
       
    }
})

export default GroupDetail;
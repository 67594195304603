import React, { useRef, useState } from "react";
import { styled } from "@material-ui/styles";
import { EditIcon } from "../../blocks/events/src/assets";
const { HelperFunctions: helper } = require("./HelperFunctions");
interface Props {
   onSelectImage: (imageUrl: string, imageFile?: File)=> void;
   selectedImage?: string;
}


const ImagePicker: React.FC<Props> = (props: Props) => {
    const { selectedImage, onSelectImage } = props;
    const imageInputRef = useRef<HTMLInputElement>(null);
    const [error, setError] = useState("");
    
    const onSelectImageHandler = async (event: React.ChangeEvent<HTMLInputElement>)=>{
        const result = await helper.covertToObjectUrl(event);
        const base64Image = await helper.convertToBase64(event);
        if (!result.imgSizeExceed) {
            let file;
            if(event.target.files){
                file = event.target.files[0];
            }

            onSelectImage(base64Image, file);
            setError("")
            
        } else {
            setError("Please upload jpg and png up to 10 MB.")
        }
    }
    return (
        <StyledImagePicker className="ImagePicker_StyledImagePicker">
            <input
                type="file"
                ref={imageInputRef}
                style={{ display: 'none' }}
                onChange={onSelectImageHandler}
                accept="image/png, image/jpeg"
            />
            {
                selectedImage && (
                    <div className="image" id="event-image" style={{ backgroundImage: `url(${selectedImage})` }}>
                        <div className="icon" onClick={() => imageInputRef?.current?.click()}>
                            <img src={EditIcon} alt="" />
                        </div>
                    </div>
                )
            }
            {
                !selectedImage && (
                    <div className="input" id="image-picker" onClick={() => imageInputRef?.current?.click()}>
                        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.45224 29.8844C2.61036 29.8844 1.89774 29.5928 1.31441 29.0094C0.731106 28.4261 0.439453 27.7135 0.439453 26.8716V4.56403C0.439453 3.72214 0.731106 3.00953 1.31441 2.4262C1.89774 1.84286 2.61036 1.5512 3.45224 1.5512H17.9394V9.05115H21.2727V12.3845H28.7727V26.8716C28.7727 27.7135 28.481 28.4261 27.8977 29.0094C27.3143 29.5928 26.6017 29.8844 25.7599 29.8844H3.45224ZM5.85612 23.6344H23.4842L18.0035 16.3268L13.324 22.4165L9.9907 18.1538L5.85612 23.6344ZM23.5805 10.0767V6.74341H20.2471V4.24349H23.5805V0.910156H26.0804V4.24349H29.4137V6.74341H26.0804V10.0767H23.5805Z" fill="#26EFF5" />
                        </svg>
                        <p>Add Image</p>
                    </div>
                )
            }

            {
                error && (
                    <div className="error">{error}</div>
                )
            }
        </StyledImagePicker>
    )
}


const StyledImagePicker = styled("div")({
    "&.ImagePicker_StyledImagePicker": {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        margin: "auto",
        "& .input": {
            width: "8.5rem",
            height: "8.6rem",
            background: "linear-gradient(180deg, #23404B 0%, #19282F 100%)",
            cursor: "pointer",
            border: "0.51px solid rgba(25, 181, 185, 0.27)",
            display: "flex",
            gap: "0.8rem",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: "2.04875rem",
            flexDirection: "column",
            "& p": {
                color: "rgba(255, 255, 255, 1)",
                fontSize: "1rem",
                fontWeight: 500
            }
        },
        "& .image": {
            width: "8.5rem",
            backgroundSize: "cover",
            backgroundPosition: "center",
            position: "relative",
            height: "8.6rem",
            borderRadius: "2.04875rem",
            "& > .icon": {
                height: "1.25rem",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                background: "linear-gradient(180deg, #F37F78 0%, #F75367 100%)",
                width: "1.25rem",
                borderRadius: "100%",
                position: "absolute",
                bottom: 0,
                right: 0,
                cursor: "pointer"
            }
        },
        "& .error": {
            color: "rgba(255, 47, 63, 1)",
            fontSize: "0.75rem",
            fontWeight: 400,
        }
    }
})

export default ImagePicker;
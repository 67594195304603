import React from "react";
import { styled } from "@material-ui/styles";
import { Box, Grid, Typography } from "@material-ui/core";
import { pdfPlaceholder } from "../../../postcreation/src/assets";
const { HelperFunctions: helper } = require("../../../../components/src/HelperFunctions");

interface Props {
    communityPostMedia: Array<{ id: number, url: string, blob_id?: number }>;
    onClickMoreIcon: () => void;
}


const CommunityPostMedia: React.FC<Props> = (props: Props) => {
    const { communityPostMedia, onClickMoreIcon } = props;
    const extraCount = communityPostMedia.length - 4;
    const returnGridSize = () => {
        if (visibleMedia.length >= 4) return 6;
        return 12;
    }
    const visibleMedia = communityPostMedia.slice(0, 4);

    return (
        <StyledCommunityPostMedia className="CommunityPostMedia_Web">
            <Grid spacing={2} container direction="row">
                <Grid item xs={6}>
                    <Box className="first-media-item image-cover" key={visibleMedia[0].id}>
                        {visibleMedia[0].url.includes("image") && <img className="community-post-img border-radius" src={visibleMedia[0].url} alt="uploaded-post" />}
                        {visibleMedia[0].url.includes("application") && <img className="community-post-img border-radius" src={pdfPlaceholder} alt="uploaded-post" />}
                        {visibleMedia[0].url.includes("video") && <video controls controlsList="nodownload" className="community-post-video border-radius" src={visibleMedia[0].url} />}
                    </Box>
                </Grid>
                {visibleMedia.length > 1 &&
                    <Grid item xs={6}>
                        <Grid container direction="row">
                            <Grid item xs={12}>
                                <Box marginBottom={"5px"} className={visibleMedia.length > 2 ? "second-media-item first-media-item image-fill" : "first-media-item image-cover"} key={visibleMedia[1].id}>
                                    {visibleMedia[1].url.includes("image") && <img className="community-post-img border-radius" src={visibleMedia[1].url} alt="uploaded-post" />}
                                    {visibleMedia[1].url.includes("application") && <img className="border-radius community-post-img" src={pdfPlaceholder} alt="uploaded-post" />}
                                    {visibleMedia[1].url.includes("video") && <video controls controlsList="nodownload" className="border-radius community-post-video" src={visibleMedia[1].url} />}
                                </Box>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container direction="row">
                                    {visibleMedia.slice(2).map((media, index) =>
                                        <Grid style={{ position: index === visibleMedia.slice(2).length -1 ? "relative" : "unset"}} key={media.id} item xs={returnGridSize()}>
                                            <Box paddingRight={index === 0 ? "5px" : "0px"} className="second-media-item first-media-item image-fill">
                                                {media.url.includes("image") && <img className="community-post-img" src={media.url} alt="uploaded-post" />}
                                                {media.url.includes("application") && <img className="community-post-img" src={pdfPlaceholder} alt="uploaded-post" />}
                                                {media.url.includes("video") && <video controls controlsList="nodownload" className="community-post-img" src={media.url} />}
                                            </Box>
                                            {(extraCount > 0 && index === visibleMedia.slice(2).length -1) && 
                                            <Box onClick={onClickMoreIcon} className="overlay second-media-item">
                                                <Typography component={"span"}>{`+${extraCount}`}</Typography>
                                            </Box>
                                            }
                                        </Grid>
                                    )}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                }
            </Grid>

        </StyledCommunityPostMedia>
    )
}

const StyledCommunityPostMedia = styled("div")({
    "&.CommunityPostMedia_Web": {
        "& .first-media-item": {
            "& img": {
                width: "100%",
                height: "307px",
                display: "block",
            },
            "& video": {
                height: "307px",
                width: "100%",
                display: "block",
                objectFit: "cover",
            },
        },
        "& .second-media-item": {
            "& img": {
                height: "150px !important",
            },
            "& video": {
                height: "150px !important",
                objectFit: "cover",
            },
            "& span": {
                fontWeight: 700,
                fontSize: "18px",
            },
        },
        "& .image-fill": {
            objectFit: "fill",
        },
        "& .image-cover": {
            objectFit: "cover",
        },
        "& .border-radius": {
            borderRadius: "12px",
        },
        "& .overlay": {
            width: "100%",
            display: "flex",
            position: "absolute",
            top: 0,
            bottom: 0,
            right: 0,
            left: 0,
            justifyContent: "center",
            alignItems: "center",
            fontSize: "16px",
            color: "#ffffff",
            zIndex: 2,
            borderRadius: "12px",
            cursor: "pointer",
        }
    }
});

export default CommunityPostMedia;
import React from "react";
// Customizable Area Start
import { Grid, styled, Box, CircularProgress, Typography, Tooltip } from "@material-ui/core";
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ClearSharpIcon from "@material-ui/icons/ClearSharp";
import { StylesProvider, createGenerateClassName, ThemeProvider, createTheme } from '@material-ui/core/styles';
import NavigationMenu from "../../navigationmenu/src/NavigationMenu.web";
import EventDetail from "./components/EventDetail.web";
import ConfirmationPopUp from "../../../components/src/ConfirmationPopUp.web";
import CreatePostModal from "../../postcreation/src/components/CreatePostModal.web";
import RecentPostCard from "../../activityfeed/src/components/RecentPostCard.web";
import RecentPostCardCommentOnly from "../../activityfeed/src/components/RecentPostCardCommentOnly.web";
const { HelperFunctions } = require("../../../components/src/HelperFunctions");
import { chatEditIcon } from "./assets";
import SearchField from "../../groups/src/components/SearchField.web";
import { Button } from "../../../components/src";
import ViewGroupChat from "../../chat/src/ViewGroupChat.web";
import StartNewChatForm from "../../chat/src/components/StartNewChatForm.web";

const generateClassName = createGenerateClassName({
  productionPrefix: 'EventDetail',  // Customize the prefix (use meaningful names)
});

const generateClassNameOpenChat = createGenerateClassName({
  productionPrefix: 'EventOpenChatRoom',  // Customize the prefix (use meaningful names)
});

const theme = createTheme(); 
// Customizable Area End

import AllEventDetailController, {
  Props
} from "./AllEventDetailController";

export default class AllEvent extends AllEventDetailController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  ChatsList = () => {
    const {
      eventChatSearchString,
      eventChatList,
      isChatListOpen,
      isChatLoading
    } = this.state;
    return (
      <StyledEventBottomChat className={`EventChat_StyledEventBottomChat ${!isChatListOpen ? "isMinimized" : ""} ${eventChatList.length > 0 ? "eventChatPresent" : "noEventChat"}`}>
        <Box className="header" padding={"0rem 1.5rem"}>
          <Box className="event-header-chats" display={"flex"} alignItems={"center"} gridGap={"8px"}>
            <p>Chats</p>
            {!isChatListOpen && <Typography component={"span"}>
              {`(${eventChatList.length})`}
            </Typography>}
          </Box>
          <Box gridGap={"8px"} display={"flex"} alignItems={"center"}>
            {isChatListOpen && <img src={chatEditIcon} onClick={() => this.openEventChatCreate()} id="chat-create-event" alt="edit-chat" className="cursor-pointer" />}
            {isChatListOpen ? <ExpandMoreIcon id="toggle-event-chatlist" onClick={() => this.toggleChatList()} /> : <ExpandLessIcon id="toggle-event-chatlist" onClick={this.toggleChatList} />}
          </Box>
        </Box>
        {isChatListOpen &&
          <>
            {isChatLoading && <Box id="event-chat-loader" className="common-circular-progress-loader">
              <CircularProgress />
            </Box>}
            {eventChatList.length > 0 ? <>
              <Box className="event-chat-search-field">
                <SearchField
                  onChange={this.onChangeSearchHandler}
                  value={eventChatSearchString}
                  placeholder="Search group name"
                />
              </Box>
              <Box className="chats">
                {
                  eventChatList.map(chat => {
                    return (
                      <Box id={`event-chat-${chat.guid[0]}`} key={chat.guid[0]} className={`chat`} onClick={() => this.setOpenChat(chat)}>
                        <Box className="chat-name">
                          <Box className="chat-image">
                            {chat.name && HelperFunctions.getInitials(chat.name)}
                          </Box>
                          <Tooltip id="chat-name-tooltip" placement="top-start" title={HelperFunctions.toPascalCase(chat.name)}>
                            <p className="capital-text">{chat.name?.length > 20 ? `${chat.name?.slice(0, 20)}...` : chat.name}</p>
                          </Tooltip>
                        </Box>
                      </Box>
                    )
                  })}
              </Box>
            </>
              :
              <>
                {!isChatLoading && <div className="no-chats">
                  <p>Send messages to the friend or group</p>
                  <Button
                    text="Start New Chat"
                    type="outlined"
                    buttonStyles={{
                      height: "2.25rem",
                    }}
                    onClick={this.openEventChatCreate}
                  />
                </div>}
              </>
            }
          </>
        }
      </StyledEventBottomChat>
    )
  }

  OpenChatsList = () => {
    const {
      openEventChatRooms,
    } = this.state;
    return (
      <StyledOpenChatContainer className="EventOpenChats_WebContainer">
        {openEventChatRooms.length > 0 && openEventChatRooms.map((openChat) =>
          <StyledEventBottomChat key={openChat.guid[0]} style={{ position: "relative", padding: openChat.isOpen ? "0px" : "" }} className={`EventChat_StyledEventBottomChat chatRoom ${!openChat.isOpen ? "isMinimized" : ""}`}>
            {!openChat.isOpen ? 
            <Box className="header" padding={"0px 10px"}>
              <Box className="chat-name-header">
                <Box className="chat-header-image">
                  {HelperFunctions.getInitials(openChat.name)}
                </Box>
                  <Tooltip id="openChat-name-tooltip" placement="top-start" title={HelperFunctions.toPascalCase(openChat.name)}>
                    <p className="capital-text ellipsis event-chat-open-name">{openChat.name}</p>
                  </Tooltip>
              </Box>
              <Box>
                <ExpandLessIcon id="open_event_chat_room" onClick={() => this.setOpenSelectedChat(openChat.guid[0])} />
              </Box>
            </Box> :
              <ViewGroupChat
                id="View Chat Event"
                isFromAppointmentOrEvent={true}
                closeGroupChatPopup={this.setCloseSelectedChat}
                navigation={this.props.navigation}
                onClickAddMemberButton={this.onClickAddMemberButton}
                onClickEditButton={this.onClickEditGroupChat}
                onDeleteGroupChat={this.onClickDeleteGroupEventChat}
                registerUpdateCallBack={this.registerUpdateEventChatCallBack}
              />
            }
          </StyledEventBottomChat>
        )}
      </StyledOpenChatContainer>

    )
  }

  EventDetailComponent = () => {
    const { eventDetail, userId, isEventRsvpStatusUpdateMode, eventDeleteConfirmationPopup, postDetails, recentPostDetails, isLoadingCommentsList, commentsList, isPostUploading, postLikedUserList, searchedPostCommentMentionsList } = this.state;
    return (
      <StyledEventDetailContainer className="EventDetail_StyledEventDetailContainer">
        <div className="event-detail-back-btn" onClick={() => this.handleGoBackFromScreen()}>
          <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.99953 11.6553L0.345703 6.00148L5.99953 0.347656L7.05335 1.40148L2.45335 6.00148L7.05335 10.6015L5.99953 11.6553Z" fill="white" />
          </svg>
          Details
        </div>
        <Grid container direction="row" spacing={2}>
          {eventDetail &&
            <Grid item xs={12} sm={12} md={recentPostDetails ? 8 : 12}>
              <EventDetail
                event={eventDetail}
                isRsvpStatusUpdateMode={isEventRsvpStatusUpdateMode}
                onUpdateRsvpStatus={this.onUpdateEventRsvpStatus}
                onRsvpUpdateModeChange={this.onRsvpUpdateModeChange}
                onDeleteEvent={(title: string, eventId: string | number) => this.openEventDeleteModal(title, eventId)}
                userId={userId}
                showRsvpOption={userId !== eventDetail.attributes.account_id}
                isNextEvent={false}
                redirectTo={this.redirectTo}
                onPostCreate={this.openCreatePostModal}
              />
            </Grid>}
            {recentPostDetails &&
            <Grid item xs={12} sm={12} md={recentPostDetails ? 4 : 12}>
              {recentPostDetails.attributes.images_and_videos?.length > 0 ? 
              <RecentPostCard
                recentPost={recentPostDetails}
                onClickComment={this.openPostCommentsModal}
                userId={userId}
                handleMentionClick={(id: string) => this.redirectTo("CommunityForumProfile", { userId: id})}
                onLikePost={(postId: string) => this.likePost(postId)}
                onDisLikePost={(postId: string) => this.disLikePost(postId)}
                onViewFeed={() => this.redirectTo("AllPosts", { type: "events", id: eventDetail?.id || "" })}
                recentPostLikedUserList={postLikedUserList}
                setLikedUsers={this.setLikedUserList}
                fetchLikedUsers={this.getLikedUsersList}
                isLoadingCommentsList={isLoadingCommentsList}
                onAddComment={this.onAddPostComment}
                onCloseCommentModal={this.closePostCommentsModal}
                onDeleteComment={this.onDeletePostComment}
                commentsList={commentsList}
                handleFetchNextComments={this.handleChangeCommentsPageNumber}
                commentMentionesUserList={searchedPostCommentMentionsList}
              /> : 
              <RecentPostCardCommentOnly
                recentPostCommentOnly={recentPostDetails}
                onClickComment={this.openPostCommentsModal}
                fetchLikedUsers={this.getLikedUsersList}
                handleMentionClick={(id: string) => this.redirectTo("CommunityForumProfile", { userId: id})}
                setLikedUsers={this.setLikedUserList}
                recentPostComentOnlylikedUserList={postLikedUserList}
                onLikePostCommentOnly={(postId: string) => this.likePost(postId)}
                onDisLikePostCommentOnly={(postId: string) => this.disLikePost(postId)}
                onViewFeed={() => this.redirectTo("AllPosts", { type: "events", id: eventDetail?.id || "" })}
                commentMentionesUserList={searchedPostCommentMentionsList}
                isLoadingComments={isLoadingCommentsList}
                onAddCommentInPost={this.onAddPostComment}
                onCloseCommentModal={this.closePostCommentsModal}
                onDeleteCommentFromPost={this.onDeletePostComment}
                commentsList={commentsList}
                handleFetchNextComments={this.handleChangeCommentsPageNumber}
                userId={userId}
              />}
            </Grid>
            }
        </Grid>
        
        <ConfirmationPopUp
          open={Boolean(eventDeleteConfirmationPopup)}
          header={eventDeleteConfirmationPopup?.header as string}
          message={eventDeleteConfirmationPopup?.message as string}
          onAccept={this.onAcceptEventDeleteConfirmation}
          onReject={this.onCloseEventDeleteConfirmationModal}
          onClose={this.onCloseEventDeleteConfirmationModal}
        />
        <CreatePostModal
          postDetails={postDetails}
          isEditMode={false}
          isPostUploading={isPostUploading}
          onCloseCreatePostModal={() => this.closeCreatePostModal()}
          onSubmitPostDetails={this.onSubmitPostDetails}
          handleUpdateFilesUploaded={(number) => this.setState({ numberOfPostFilesUploaded: number })}
        />
      </StyledEventDetailContainer>
    )
  }

  EventDetailMainContainer = () => {
    const { openEventChatCreateForm, eventDetail, contactList, phoneBookContactList, selectedGroupChatForEdit, editChatMode, isAddMemberToChatMode, selectedMembersForEditEventChat, isLoadingContacts, registeredContactsPagination } = this.state;
    return (
      <StylesProvider generateClassName={generateClassName}>
         <ThemeProvider theme={theme}>
          <StyledDetailMainContainer className="DetailEvent_WebContainer">
            {this.EventDetailComponent()}
            {this.ChatsList()}
            {this.OpenChatsList()}
            {
              openEventChatCreateForm && (
                <StartNewChatForm
                  onClose={this.closeChatCreate}
                  appointments={[]}
                  isFromAppointmentOrEvent
                  appointmentOrEventDetail={eventDetail}
                  onSearchContact={this.onSearchContact}
                  contactList={contactList}
                  phoneBookContactList={phoneBookContactList}
                  onSearch={() => {}}
                  onCreateChat={this.onCreateEventChat}
                  isEditMode={editChatMode || isAddMemberToChatMode}
                  chatDetails={selectedGroupChatForEdit}
                  defaultStep={editChatMode ? 3 : 2}
                  type="events"
                  selectedMembers={selectedMembersForEditEventChat}
                  isLoadingContacts={isLoadingContacts}
                  registeredContactsPagination={registeredContactsPagination}
                  fetchRegisteredContacts={this.fetchContacts}
                />
              )
            }
          </StyledDetailMainContainer>
         </ThemeProvider>
       </StylesProvider>
    )
  }
  // Customizable Area End

  render() {
    // Customizable Area Start

    return (
      <NavigationMenu {...this.props} children={this.EventDetailMainContainer()} />
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const StyledEventDetailContainer = styled("div")({
  "&.EventDetail_StyledEventDetailContainer": {
    flexDirection: "column",
    display: "flex",
    padding: "1rem 1rem",
    gap: "1.5rem",
    "& > .event-detail-back-btn": {
      fontSize: "1.125rem",
      fontWeight: 500,
      display: "flex",
      gap: "0.5rem",
      alignItems: "center",
      color: "white",
      cursor: "pointer",
      width: "fit-content"
    },
  }
})
const StyledEventBottomChat = styled("div")({
  "&.EventChat_StyledEventBottomChat": {
    display: "flex",
    boxShadow: "0px 0px 28px 0px rgba(0, 0, 0, 0.35)",
    flexDirection: "column",
    gap: "1rem",
    boxSizing: "border-box",
    background: "rgba(35, 64, 75, 1)",
    padding: "12px 0px 0px",
    position: "absolute",
    width: "350px",
    borderRadius: "0.5rem",
    bottom: 0,
    zIndex: 10,
    transitionDuration: "167ms",
    animation: "fade-in 167ms cubic-bezier(.4,0,1,1)",
    "&.eventChatPresent": {
      height: "calc(100vh - 164px)",
      right: "15px",
      [`@media screen and (max-width: 450px)`]: {
        right: "0px",
      },
    },
    "&.chatRoom": {
      height: "calc(100vh - 255px)",
      width: "400px",
      [`@media (min-width: 600px) and (min-width: 1200px)`]: {
        width: "310px !important",
      },
      [`@media screen and (min-width: 1200px)`]: {
        width: "400px !important",
      },
    },
    "&.noEventChat": {
      height: "calc(100vh - 320px)",
      right: "15px",
      [`@media screen and (max-width: 450px)`]: {
        right: "0px",
      },
    },
    [`@media screen and (max-width: 450px)`]: {
      right: "0px",
      width: "99% !important",
    },
    [`@media screen and (min-width: 768px)`]: {
      width: "310px !important",
    },
    [`@media screen and (min-width: 960px)`]: {
      width: "350px !important",
    },
    "&.isMinimized": {
      transform: "translateY(100%) translateY(-48px) !important",
      borderTopRightRadius: "0.5rem",
      borderBottomLeftRadius: "0px !important",
      borderTopLeftRadius: "0.5rem",
      borderBottomRightRadius: "0px !important",
      height: "48px !important",
    },
    "& > .header": {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      "& > .event-header-chats": {
        "& > p": {
          fontSize: "1.25rem",
          fontWeight: 500,
          color: "rgba(255, 255, 255, 1)",
        },
        "& > span": {
          fontSize: "1.25rem",
          fontWeight: 500,
          color: "rgba(255, 255, 255, 1)",
        },
      },
      "& > .chat-name-header": {
        display: "flex",
        alignItems: "center",
        gap: "0.5rem",
        "& > .chat-header-image": {
          background: "rgba(0, 0, 0, 0.2)",
          justifyContent: "center",
          height: "2rem",
          borderRadius: "0.5rem",
          alignItems: "center",
          textTransform: "uppercase",
          fontSize: "15px",
          width: "2rem",
          display: "flex",
          fontWeight: 400,
          color: "rgba(255, 255, 255, 0.8)"
        },
        "& p": {
          fontSize: "1rem",
          color: "rgba(255, 255, 255, 1)",
          fontWeight: 500,
        },
        "& .event-chat-open-name": {
          width: "15rem",
          [`@media screen and (max-width: 960px)`]: {
            width: "12rem",
          },
          [`@media screen and (max-width: 450px)`]: {
            width: "9rem !important",
          },
        }
      },
      "& svg": {
        color: "#fff",
        cursor: "pointer",
      },
      boxSizing: "border-box",
    },
    "& > .event-chat-search-field": {
      boxSizing: "border-box",
      padding: "0rem 1.5rem",
    },
    "& > .chats": {
      display: "flex",
      overflow: "auto",
      flexDirection: "column",
      "& > .chat": {
        justifyContent: "space-between",
        boxSizing: "border-box",
        display: "flex",
        padding: "0.75rem 1.5rem",
        alignItems: "center",
        cursor: "pointer",
        "& > .chat-name": {
          display: "flex",
          alignItems: "center",
          gap: "0.5rem",
          "& > .chat-image": {
            width: "3.3125rem",
            background: "rgba(0, 0, 0, 0.2)",
            height: "3.3125rem",
            fontSize: "1.5rem",
            borderRadius: "0.5rem",
            justifyContent: "center",
            alignItems: "center",
            fontWeight: 400,
            textTransform: "uppercase",
            display: "flex",
            color: "rgba(255, 255, 255, 0.8)"
          },
          "& > p": {
            fontWeight: 500,
            fontSize: "1rem",
            color: "rgba(255, 255, 255, 1)"
          }
        },
        "&:hover": {
          background: "rgba(0, 0, 0, 0.15)"
        },
      },
    },
    "& > .no-chats": {
      width: "100%",
      display: "flex",
      alignItems: "center",
      height: "100%",
      justifyContent: "center",
      flexDirection: "column",
      textAlign: "center",
      gap: "0.5rem",
      "& > p": {
        fontWeight: 400,
        fontSize: "1rem",
        color: "rgba(255, 255, 255, 0.8)"
      }
    }
  }
})
const StyledDetailMainContainer  = styled("div")({
  "&.DetailEvent_WebContainer": {
    height: "100%",
    [`@media screen and (max-width: 600px)`]: {
      height: "max-content",
    },
  }
})
const StyledOpenChatContainer = styled("div")({
  "&&.EventOpenChats_WebContainer": {
    gap: "16px",
    overflow: "scroll",
    position: "absolute",
    right: "381px",
    bottom: 0,
    alignItems: "end",
    maxWidth: "calc(100vw - 41rem)",
    [`@media screen and (max-width: 450px)`]: {
      right: "0px !important",
      maxWidth: "100% !important",
    },
    [`@media (min-width: 600px) and (max-width: 959px)`]: {
      right: "340px",
      maxWidth: "100%",
    },
    [`@media screen and (min-width: 960px)`]: {
      right: "340px !important"
    },
    [`@media screen and (min-width: 1200px)`]: {
      right: "381px !important"
    },
    display: "flex",
  }
})
// Customizable Area End

Object.defineProperty(exports, "__esModule", {
  value: true,
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.patchApiMethodType = "PATCH";
exports.postApiMethodType = "POST";
exports.deleteApiMethodType = "DELETE";
exports.updateApiMethodType = "PUT";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "Events";
exports.labelBodyText = "Events Body";
exports.acceptRequestEndPoint = "/events/requests/accepted";
exports.createEventEndPoint = "/events/events";
exports.GOOGLE_API_KEY = "AIzaSyAQu429K52drmir9450TvUYcy82ZL3QQ9I";
exports.GOOGLE_PACES_API_BASE_URL =
  "https://maps.googleapis.com/maps/api/place";

exports.time = "Time";
exports.date = "Date";
exports.assignedTo = "Assigned to";
exports.visibility = "Visibility";
exports.notify = "Notify";
exports.repeat = "Repeat";
exports.repeatEvery = "Repeat every";
exports.day = "day";
exports.week = "week";
exports.notes = "Notes";
exports.save = "SAVE";
exports.delete = "DELETE";
exports.ok = "OK";
exports.cancel = "Cancel";
exports.deleteMessage = "Are you sure you want to delete this Event?";
exports.eventOverlapMessage = "This Event overlaps with another Event!";
exports.changeTime = "CHANGE TIME";
exports.noTitleMessage = "Please Enter Title";
exports.search = "Search";
exports.noEvent = "No Upcoming Event!";
exports.birthday = "Birthday";
exports.schollDropOff = "School Drop Off";
exports.schoolPickUp = "School Pick Up";
exports.getToghether = "Get Together";
exports.meeting = "Meeting";
exports.outForCoffee = "Out for Coffee";
exports.gym = "Gym";
exports.flight = "Flight";
exports.doctor = "Doctor";
exports.vet = "Vet";
exports.rent = "Rent";
exports.fees = "Fees";
exports.activity = "Activity";
exports.custom = "Custom";
exports.specifyLocation = "Please specify location";
exports.unableToOpenSettings = "Unable to open settings";
exports.locationPermissionDenied =
  "Location permission denied, Please enable location from settings.";
exports.turnOnLocation =
  "Turn on Location Services to allow Printsec to determine your location.";
exports.goToSettings = "Go to Settings";
exports.dontUseLocation = "Don't Use Location";
exports.title = "Title";
exports.confirm = "Confirm";
exports.nextEventAPIEndpoint = "bx_block_events/events/ongoing_event";
exports.eventsEndpoint = "bx_block_events/events";
exports.upcomingEventsAPIEndpoint = "bx_block_events/events/future_events";
exports.bestEventsAPIEndpoint = "bx_block_events/best_events";
exports.searchContactsApiEndPoint = "bx_block_appointment_management/appointments/search_contacts"
exports.detectCoordinatesApiEndpoint = "bx_block_maps3/locations/detect_coordinates";
exports.recentPostsEndpoint = "bx_block_posts/posts";
exports.likeRecentPostApiEndpoint = "bx_block_upvotedownvote/upvotedownvotes/upvote";
exports.dislikeRecentPostApiEndpoint = "bx_block_like/likes";
exports.commentsApiEndpoint = "bx_block_comments/comments";
exports.phonebookContacts = "bx_block_contactslist2/phonebook_contacts";
exports.postLikedUsersListApiEndpoint = "bx_block_upvotedownvote/upvotedownvotes/likes_list";
exports.searchMentionsApiEndpoint = "bx_block_mentions_and_tagging/search_menstions/search_user";
exports.chatsListingEndpoint = "bx_block_chat/chats/list_chat_group";
// Customizable Area End

import React from "react";
// Customizable Area Start
import { Box, Slider, styled } from "@material-ui/core";
import GoBack from "../../../components/src/GoBack.web";
import NavigationMenu from "../../navigationmenu/src/NavigationMenu.web";
import InputText from "../../../components/src/TextInput.web";
import TextArea from "../../../components/src/TextArea.web";
import { EditIcon } from "../../events/src/assets";
const { HelperFunctions: helper } = require("../../../components/src/HelperFunctions")
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker
} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import MapMenu from "../../../components/src/MapMenu.web";
// Customizable Area End

import AddGoalController, {
    Props
} from "./AddGoalController";

export default class AllEvent extends AddGoalController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    ImageInput = () => {
        const { selectedImage, goalImageError, imageInputRef } = this.state;
        return (
            <>
                <StyledGoalImageInput className="AddGoal_StyledGoalImageInput">
                    {
                        !selectedImage && (
                            <Box className="input" data-test-id="goal-image-picker" id="image-picker" onClick={() => imageInputRef?.current?.click()}>
                                <svg id="image-placeholder" width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M3.45224 29.8844C2.61036 29.8844 1.89774 29.5928 1.31441 29.0094C0.731106 28.4261 0.439453 27.7135 0.439453 26.8716V4.56403C0.439453 3.72214 0.731106 3.00953 1.31441 2.4262C1.89774 1.84286 2.61036 1.5512 3.45224 1.5512H17.9394V9.05115H21.2727V12.3845H28.7727V26.8716C28.7727 27.7135 28.481 28.4261 27.8977 29.0094C27.3143 29.5928 26.6017 29.8844 25.7599 29.8844H3.45224ZM5.85612 23.6344H23.4842L18.0035 16.3268L13.324 22.4165L9.9907 18.1538L5.85612 23.6344ZM23.5805 10.0767V6.74341H20.2471V4.24349H23.5805V0.910156H26.0804V4.24349H29.4137V6.74341H26.0804V10.0767H23.5805Z" fill="#26EFF5" />
                                </svg>
                                <p>Add Image</p>
                            </Box>
                        )
                    }
                    {
                        selectedImage && (
                            <Box className="image" id="goal-image" style={{ backgroundImage: `url(${selectedImage})` }}>
                                <Box id="add-goal-image" className="icon" onClick={() => imageInputRef?.current?.click()}>
                                    <img src={EditIcon} alt="" />
                                </Box>
                            </Box>
                        )
                    }
                    <input
                        type="file"
                        id="goal-image-input"
                        ref={imageInputRef}
                        style={{ display: 'none' }}
                        onChange={this.onSelectImage}
                    />
                    {
                        goalImageError && (
                            <Box className="error">{goalImageError}</Box>
                        )
                    }
                </StyledGoalImageInput>
            </>
        )
    }

    ReminderRadiusSlider = () => {
        const { reminderRadius } = this.state;
        return (
            <Box>
                <label className="custom-label">Select Reminder Radius*</label>
                <StyledReminderRadiusSlider
                    className="ReminderRadius_Slider"
                    value={reminderRadius}
                    valueLabelDisplay="on"
                    min={0}
                    max={100}
                    valueLabelFormat={(value) => `${value}km`}
                    onChange={(_, value) => this.onRadiusChange(value as number)}
                />
            </Box>
        )
    }

    AddGoalContainer = () => {
        const {
            title,
            goalDate,
            selectedLocation,
            description,
            mapMenuAnchorEle,
            predictions,
            recentLocations,
            editMode,
        } = this.state;
        return (
            <StyledAddBucketListGoal className="AddGoal_StyledAddBucketListGoal">
                <GoBack displayText={editMode ? "Edit Goal" : "Add Goal"} goBack={() => this.redirectTo("BucketList")} />
                <form autoComplete="off">
                    <Box className="form-row" style={{ marginBottom: "1.25rem" }}>
                        {this.ImageInput()}
                    </Box>
                    <Box className="form-row">
                        <Box className="form-col">
                            <InputText onChange={this.onChangeInputs} value={title} maxLength={42} label="Title*" type="text" name="title" isContainerFocused={true} />
                        </Box>
                        <Box id="add_goal_location" className="form-col">
                            <InputText
                                onChange={this.onChangeInputs}
                                value={selectedLocation ? selectedLocation.structured_formatting.main_text : ""}
                                label="Choose Location*"
                                type="text"
                                isContainerFocused={true}
                                readonly={true}
                                onClick={(event) => this.openMapMenu(event as React.MouseEvent<HTMLDivElement>)}
                                style={{ cursor: "pointer" }}
                                onClearInput={() => this.onSelectLocation(null)}
                                inputStyles={{ cursor: "pointer" }}
                            />
                            <MapMenu
                                anchorEle={mapMenuAnchorEle}
                                predictions={predictions}
                                recentLocations={recentLocations}
                                onClose={this.onCloseMapMenu}
                                onSelect={this.onSelectLocation}
                                onSearch={this.onSearchLocation}
                            />
                        </Box>
                    </Box>
                    <Box className="form-row">
                        <Box style={{ width: "100%" }} className="form-col">
                            <TextArea
                                onChange={this.onChangeInputs}
                                value={description}
                                label="Description"
                                isContainerFocused={true}
                                name="description"
                                maxLength={1024}
                            />
                        </Box>
                    </Box>
                    <Box className="form-row">
                        <Box className="form-col">
                            <InputText
                                value={goalDate ? helper.formatDate(goalDate, "DD MMMM YYYY") : goalDate}
                                label="Target Date*"
                                type="text"
                                isContainerFocused={true}
                                readonly={true}
                                onChange={this.onChangeInputs}
                                onClick={this.openDatePicker}
                            />
                            <MuiPickersUtilsProvider utils={MomentUtils}>
                                <KeyboardDatePicker
                                    variant="inline"
                                    format="DD MMMM YYYY"
                                    margin="normal"
                                    id="add_goal_datepicker"
                                    value={goalDate}
                                    onChange={this.onGoalDateChangeHandler}
                                    KeyboardButtonProps={{
                                        "aria-label": "change date",
                                        id: "mui-date-picker"
                                    }}
                                    className="AddGoal_customdatepicker customDatePicker"
                                    style={{ display: "none" }}
                                    DialogProps={{
                                        className: "datePickerDialog",
                                    }}
                                    PopoverProps={{
                                        className: "add-appointment-date-picker"
                                    }}
                                    autoOk={true}
                                    disablePast
                                />
                            </MuiPickersUtilsProvider>
                        </Box>
                        <Box className="form-col">
                            {this.ReminderRadiusSlider()}
                        </Box>
                    </Box>
                    <Box className="form-row" style={{ justifyContent: "center", alignItems: "center" }}>
                        <button type="button" disabled={this.isGoalAddButtonDisabled()} className="submit-btn" onClick={this.onSubmitGoal}>
                            {
                                editMode ? "Update" : "Add Goal"
                            }
                        </button>
                    </Box>
                </form>
            </StyledAddBucketListGoal>
        )
    }
    // Customizable Area End

    render() {
        // Customizable Area Start

        return (
            <NavigationMenu {...this.props} children={this.AddGoalContainer()} />
        );
        // Customizable Area End
    }
}

// Customizable Area Start
const StyledAddBucketListGoal = styled("div")({
    "&.AddGoal_StyledAddBucketListGoal": {
        display: "flex",
        flexDirection: "column",
        gap: "1.5rem",
        padding: "1rem 1.5rem",
        "& > form": {
            userSelect: "none",
            flexDirection: "column",
            display: "flex",
            gap: "1rem",
            padding: "1rem 3rem",
            "& > .form-row": {
                alignItems: "flex-end",
                display: "flex",
                justifyContent: "space-between",
                "& > .form-col": {
                    width: "49%"
                },
            },
            [`@media screen and (max-width: 1050px)`]: {
                padding: "1rem 0rem",
                "& > .form-row": {
                    alignItems: "flex-start",
                    flexDirection: "column",
                    gap: "1rem",
                    "& > .form-col": {
                        width: "100% !important"
                    },
                    "&:nth-child(6)": {
                        flexDirection: "column-reverse"
                    }
                }
            },
        },
        "& .submit-btn": {
            border: "none",
            outline: "none",
            background: "linear-gradient(180deg, rgba(243, 127, 120, 0.75) 0%, rgba(247, 83, 103, 0.75) 100%)",
            width: "21.4375rem",
            height: "3rem",
            fontWeight: 500,
            color: "rgba(255, 255, 255, 0.75)",
            fontSize: "1rem",
            opacity: 1,
            userSelect: "none",
            "&:disabled": {
                opacity: 0.7
            }
        },
    }
});

const StyledGoalImageInput = styled("div")({
    "&.AddGoal_StyledGoalImageInput": {
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
        margin: "auto",
        "& .input": {
            height: "8.6rem",
            width: "8.5rem",
            border: "0.51px solid rgba(25, 181, 185, 0.27)",
            display: "flex",
            background: "linear-gradient(180deg, #23404B 0%, #19282F 100%)",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            borderRadius: "2.04875rem",
            gap: "0.8rem",
            cursor: "pointer",
            "& p": {
                color: "rgba(255, 255, 255, 1)",
                fontSize: "1rem",
                fontWeight: 500
            }
        },
        "& .image": {
            width: "8.5rem",
            borderRadius: "2.04875rem",
            height: "8.6rem",
            backgroundSize: "cover",
            position: "relative",
            backgroundPosition: "center",
            "& > .icon": {
                background: "linear-gradient(180deg, #F37F78 0%, #F75367 100%)",
                width: "1.25rem",
                borderRadius: "100%",
                height: "1.25rem",
                alignItems: "center",
                justifyContent: "center",
                display: "flex",
                position: "absolute",
                right: 0,
                bottom: 0,
                cursor: "pointer"
            }
        },
        "& .error": {
            fontWeight: 400,
            fontSize: "0.75rem",
            color: "rgba(255, 47, 63, 1)"
        }
    }
});

const StyledReminderRadiusSlider = styled(Slider)({
    "&.ReminderRadius_Slider": {
        "& .MuiSlider-rail": {
            height: "0.5rem !important",
            background: "linear-gradient(180deg, #76b1c7 0%, #19282F 100%) !important",
            boxShadow: "0px 0px 12px 0px rgba(0, 0, 0, 0.15) !important",
            borderRadius: "62.5rem !important"
        },
        "& .MuiSlider-track": {
            boxShadow: "0px 0px 12px 0px rgba(0, 0, 0, 0.15) !important",
            background: "linear-gradient(180deg, #23404B 0%, #19282F 100%) !important",
            borderRadius: "62.5rem !important",
            height: "0.5rem",
        },
        "& .MuiSlider-thumb": {
            width: "1.5rem",
            background: "linear-gradient(90deg, #FF7F77 0%, #FF6376 100%) !important",
            height: "1.5rem",
            marginTop: "-0.625rem",
            "&:hover": {
                boxShadow: "none"
            }
        },
        "& .MuiSlider-valueLabel": {
            top: "26px !important",
            left: "calc(-30%) !important",
            "& > span": {
                background: "transparent !important",
                padding: "0.2rem !important",
            }
        },
        "& .slider-label-comp": {
            color: "white",
            marginTop: "10px",
            fontSize: "14px",
        }
    }
})

// Customizable Area End

// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { ContactList, IAppointmentData, IChatData, IEventData, IGroupChat } from "../../../components/src/CommonTypes";
export const configJSON = require("./config.js");
const {
    HelperFunctions: helper,
} = require("../../../components/src/HelperFunctions");
import { 
    getUpcomingAppointments, 
    fetchContacts, 
    onSearchContact , 
    searchContactApiCallHandler,
    handleFetchContactsAPICall,
    handleGetUpcomingAppointmentsAPICall,
    searchAppointments,
    createChat,
    fetchPhoneBookContacts,
    handleFetchPhoneBookContactsAPICall,
    getGroupChats,
    getGroupChatsApiCallHandler,
    getUpcomingEvents,
    handleGetEvents,
    editGroupChat,
    addMembersInGroupChat,
    handleAddMembersInGroupChat,
    handleEditGroupChat,
    deleteGroupChat,
    handleDeleteGroupChat
} from "./actions";
import { CometChat } from "@cometchat-pro/chat";
// Customizable Area End

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

export interface S {
    // Customizable Area Start
    token: string | null;
    activeTab: "all" | "appointments" | "events";
    searchString: string;
    selectedGroupChatId: number | string | null;
    selectedGroupChatForEdit: IChatData | null;
    selectedMembersForEdit: ContactList[];
    selectedStartNewChatForm: "appointments" | "events" | "";
    openStartNewChatForm: boolean;
    contactList: ContactList[];
    phoneBookContactList: ContactList[];
    appointments: IAppointmentData[];
    groupChats: IGroupChat[];
    events: IEventData[];
    openSelectChatTypeDialog: boolean;
    isEditMode: boolean;
    isAddMembersMode: boolean;
    updateChildComponent?: any;
    registeredContactsPagination: {
        per_page: number,
        page_no: number,
        hasMore: boolean
    }
    callback?: any;
    isLoadingContacts: boolean;
    // Customizable Area End
}

export interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class GroupChatController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    searchContactApiCallId: string = "";
    fetchContactsApiCallId: string = "";
    getUpcomingAppointmentsApiCallId: string = "";
    createChatApiCallId:string = "";
    fetchPhoneBookApiCallId: string = "";
    getGroupChatsApiCallId: string = "";
    getEventsApiCallId: string = "";
    addMembersChatApiCallId: string = "";
    editChatApiCallId: string = "";
    deleteChatGroupApiCallId: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            // Customizable Area Start
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionResponseMessage),
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            token: "",
            activeTab: "all",
            searchString: "",
            selectedGroupChatId: "",
            openStartNewChatForm: false,
            contactList: [],
            phoneBookContactList: [],
            appointments: [],
            groupChats: [],
            events: [],
            selectedStartNewChatForm: "",
            openSelectChatTypeDialog: false,
            selectedGroupChatForEdit: null,
            isEditMode: false,
            selectedMembersForEdit: [],
            isAddMembersMode: false,
            registeredContactsPagination: {
                per_page: 50,
                page_no: 1,
                hasMore: true
            },
            isLoadingContacts: false
            // Customizable Area End
        };

        // Customizable Area Start
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async componentDidMount() {
        await super.componentDidMount();
        this.getToken();
        // Customizable Area Start
        const searchParams = new URLSearchParams(this.props.navigation.history.location.search)
        const guid = searchParams.get("id");
        if(guid){
            this.setState({
                selectedGroupChatId: guid
            })
        }
        // Customizable Area End
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS | undefined): void {
        const searchParams = new URLSearchParams(this.props.navigation.history.location.search)
        const guid = searchParams.get("id");
        if(prevState.selectedGroupChatId !== guid){
            this.setState({
                selectedGroupChatId: guid
            })
        }
    }

    getToken = async () => {
        const token = await helper.getStorageData("authToken");
        this.setState({
            token: token
        },()=>{
            this.getChatGroups();
            this.getUpcomingAppointments();
            this.getEvents();
        })
    };

    receive = async (from: string, message: Message) => {
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const webApiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );
            switch (webApiRequestCallId) {
                case this.fetchContactsApiCallId:
                    this.handleFetchContactsAPICall(message)
                    break;
                case this.searchContactApiCallId:
                    this.searchContactApiCallHandler(message)
                    break;
                case this.getUpcomingAppointmentsApiCallId:
                    this.handleGetUpcomingAppointmentsApiCall(message);
                    break;
                case this.createChatApiCallId:
                    this.onCreateChatApiHandler(message);
                    break;
                case this.fetchPhoneBookApiCallId:
                    this.handleFetchPhoneBookContactsApiCal(message);
                    break;
                case this.getGroupChatsApiCallId:
                    this.handleGetChatGroupsApiCall(message);
                    break;
                case this.getEventsApiCallId:
                    this.handleGetEventApiCall(message);
                    break;
                case this.editChatApiCallId:
                    this.handleEditGroupChatApiCall(message);
                    break;
                case this.addMembersChatApiCallId:
                    this.handleAddMemberApiCall(message);
                    break;
                case this.deleteChatGroupApiCallId:
                    this.handleDeleteGroupChat(message);
                    break;
            }
        }
        // Customizable Area End
    };

    // Customizable Area Start
    changeTab = (newTab: "all" | "appointments" | "events") => {
        this.setState({
            activeTab: newTab
        })
    }

    goToChatView = (chatId: string) => {
        this.props.navigation.history.push(`/chats?id=${chatId}`)
        this.setState({
            selectedGroupChatId: chatId
        })
    }

    onChangeHandler = (value: string) => {
        this.setState({
            searchString: value
        })
    }

    openStartNewChatForm = ()=>{
        const {
          activeTab
        } = this.state;
        if(activeTab !== "all"){
            this.setState({
                openStartNewChatForm: true,
                selectedStartNewChatForm: activeTab
            })
        }else{
            this.setState({
                openSelectChatTypeDialog: true
            })
        }
        this.fetchPhoneBookContacts();
    }

    closeStartNewChatForm = ()=>{
        this.setState({
            openStartNewChatForm: false,
            selectedGroupChatForEdit: null,
            isEditMode: false,
            isAddMembersMode: false,
            selectedMembersForEdit: [],
            contactList: [],
            phoneBookContactList: [],
            registeredContactsPagination: {
                per_page: 50,
                page_no: 1,
                hasMore: true
            }
        })
    }

    onSelectChatType = (event: "appointments" | "events")=>{
        this.setState({
            selectedStartNewChatForm: event,
            openStartNewChatForm: true,
            openSelectChatTypeDialog: false
        })
    }

    closeSelectChatTypeModal = ()=>{
        this.setState({
            openSelectChatTypeDialog: false,
            contactList: [],
            phoneBookContactList: [],
            registeredContactsPagination: {
                per_page: 50,
                page_no: 1,
                hasMore: true
            }
        })
    }

    onSearchContact = async (contact: string) => {
        if(contact){
            this.searchContactApiCallId = await onSearchContact({
                token: this.state.token,
                query: contact
            })
        }else{
            this.setState({
                registeredContactsPagination: {
                    per_page: 50,
                    page_no: 1,
                    hasMore: true
                },
                contactList: [],
                phoneBookContactList: []
            })
            this.fetchPhoneBookContacts()
        }
    }

    searchContactApiCallHandler = (message: Message) => {
        const responseJson = searchContactApiCallHandler(message);
        this.setState({
            contactList: responseJson.data,
            phoneBookContactList: responseJson.phoneBookContactsList
        })
    }

    fetchContacts = async (callback?: any) => {
        this.setState({
            isLoadingContacts: true,
            callback
        })
        const {
            registeredContactsPagination,
            token
        } = this.state;

        this.fetchContactsApiCallId = await fetchContacts({
            token,
            query: `?per_page=${registeredContactsPagination.per_page}&page_no=${registeredContactsPagination.page_no}`
        })
    }

    handleFetchContactsAPICall = async (message: Message) => {
        const responseJson = handleFetchContactsAPICall(message);
        this.setState(prevState => {
            return {
                contactList: [...prevState.contactList, ...responseJson.data]
            }
        })
        if(this.state.callback){
            this.state.callback();
        }

        this.setState(prevState => {
            return {
                isLoadingContacts: false,
                registeredContactsPagination: {
                    ...prevState.registeredContactsPagination,
                    page_no: prevState.registeredContactsPagination.page_no + 1,
                    hasMore: responseJson && responseJson.data && responseJson.data.length > 0 ? true : false
                }
            }
        })
    }

    fetchPhoneBookContacts = async ()=>{
        this.setState({
            isLoadingContacts: true
        })
        this.fetchPhoneBookApiCallId = await fetchPhoneBookContacts({
            token: this.state.token
        }) 
    }

    handleFetchPhoneBookContactsApiCal = (message: Message) => {
        const responseJson = handleFetchPhoneBookContactsAPICall(message);
        
        this.setState({
            phoneBookContactList: responseJson.data
        })
        this.fetchContacts();
    }


    getUpcomingAppointments = async ()=>{
        this.getUpcomingAppointmentsApiCallId = await getUpcomingAppointments({
            token: this.state.token,
            page_no: 1,
            per_page: 10
        })
    }

    handleGetUpcomingAppointmentsApiCall = (message: Message)=>{
        const response = handleGetUpcomingAppointmentsAPICall(message);
        this.setState({
            appointments: response.data
        })
    }

    onSearchAppointments = async (query: string)=>{
        if(query){
            this.getUpcomingAppointmentsApiCallId = await searchAppointments({
                token: this.state.token,
                query
            })
        }else{
            this.getUpcomingAppointments();
        }
    }

    onCreateChat = async (body: any) => {
        const {
            selectedStartNewChatForm,
            isEditMode,
            selectedGroupChatForEdit,
            isAddMembersMode
        } = this.state;

        if (selectedStartNewChatForm === "appointments" && !isEditMode && !isAddMembersMode) {
            this.closeStartNewChatForm();
            helper.showLoader();

            this.createChatApiCallId = await createChat({
                token: this.state.token,
                body
            })
        }else if(selectedGroupChatForEdit){
            helper.showLoader();

            if(selectedGroupChatForEdit.group.data.name !== body.name){
                this.editChatApiCallId = await editGroupChat({
                    token: this.state.token,
                    body: {
                        guid: selectedGroupChatForEdit.group.data.guid,
                        name: body.name,
                        type: "public"
                    }
                })
            }

            if(isAddMembersMode){
                this.addMembersChatApiCallId = await addMembersInGroupChat({
                    token: this.state.token,
                    body: {
                        guid: selectedGroupChatForEdit.group.data.guid,
                        members: body.members.participants
                    }
                })
            }

            this.closeStartNewChatForm();
        }
    }

    onCreateChatApiHandler = (message: Message)=>{
        const responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        if(responseJson && responseJson.comet_response && responseJson.comet_response.data){
            helper.showSuccessToast(responseJson.message)
            this.getChatGroups();
        }else if(responseJson && responseJson.errors){
            helper.showErrorToast(responseJson.errors)
        }else{
            helper.showErrorToast("Something went wrong please try again!")
        }
        helper.hideLoader();
    }

    getChatGroups = async () => {
        helper.showLoader();
        this.getGroupChatsApiCallId = await getGroupChats({
            token: this.state.token
        })
    }

    handleGetChatGroupsApiCall = (message: Message)=>{
        const response = getGroupChatsApiCallHandler(message);
        this.setState({
            groupChats: response.data
        })
        helper.hideLoader();
    }

    getEvents = async ()=>{
        this.getEventsApiCallId = await getUpcomingEvents({
            token: this.state.token
        })
    }

    handleGetEventApiCall = (message: Message)=>{
        const response = handleGetEvents(message);
        this.setState({
            events: response.data
        })
    }

    onClickAddMemberButton = (event: any)=>{
        if(event && event.chatData){
            this.setState({
                selectedGroupChatForEdit: event.chatData
            })
            if(event.groupMembers){
                const memberList: ContactList[] = [];
                event.groupMembers.forEach((groupMember: CometChat.GroupMember)=> {
                    if(groupMember.getScope() !== "admin"){
                        const contact: ContactList = {
                            id: parseInt(groupMember.getUid()),
                            name: groupMember.getName(),
                            email: "",
                            full_phone_number: ""
                        }
                        memberList.push(contact);
                    }
                })
                
                this.setState({
                    selectedMembersForEdit: memberList,
                    isAddMembersMode: true
                })
            }
            this.fetchPhoneBookContacts();
        }
    }

    onClickEditButton = (event: any)=>{
        if(event && event.chatData){
            this.setState({
                selectedGroupChatForEdit: event.chatData,
                isEditMode: true
            })
            if(event.groupMembers){
                const contactList: ContactList[] = [];
                event.groupMembers.forEach((member: CometChat.GroupMember)=> {
                    if(member.getScope() !== "admin"){
                        const contact: ContactList = {
                            id: parseInt(member.getUid()),
                            name: member.getName(),
                            email: "",
                            full_phone_number: ""
                        }
                        contactList.push(contact);
                    }
                })
                
                this.setState({
                    selectedMembersForEdit: contactList,
                    isAddMembersMode: true
                })
            }
            this.fetchPhoneBookContacts();
        }
    }

    handleAddMemberApiCall = (message: Message)=>{
        const addMemberResponse = handleAddMembersInGroupChat(message);
        if(addMemberResponse){
            helper.showSuccessToast(addMemberResponse.message)
            this.state.updateChildComponent?.()
        }else{
            helper.showErrorToast("Something went wrong! Please try again")
        }
        helper.hideLoader();
    }

    handleEditGroupChatApiCall = (message: Message)=>{
        const response = handleEditGroupChat(message);
        if(response){
            helper.showSuccessToast(response.message);
            this.getChatGroups();
            this.state.updateChildComponent?.()
        }else{
            helper.showErrorToast("Something went wrong! Please try again")
        }
        helper.hideLoader();
    }

    deleteGroupChat = async (guid: string)=>{
        helper.showLoader();
        this.deleteChatGroupApiCallId = await deleteGroupChat({
            token: this.state.token,
            body: {
                guid
            }
        })
    }

    handleDeleteGroupChat = (message: Message)=>{
        const response = handleDeleteGroupChat(message);
        if(response && response.message){
            helper.showSuccessToast(response.message)
            this.props.navigation.history.push("/chats")
        }else{
            helper.showErrorToast("Something went wrong! please try again")
        }
        helper.hideLoader();
    }

    registerUpdateCallBack = (callback: any)=>{
        this.setState({
            updateChildComponent: callback
        })
    }

    // Customizable Area End
}
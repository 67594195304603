// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import React from "react";
import { SearchLocation, ILocation } from "../../../components/src/CommonTypes";
const { HelperFunctions: helper } = require("../../../components/src/HelperFunctions")
import { Moment } from "moment/moment";
import { getStorageData } from "../../../framework/src/Utilities";
export const configJSON = require("./config");
// Customizable Area End

export interface Props {
    // Customizable Area Start
    navigation: any;
    id: string;
    // Customizable Area End
}

export interface S {
    // Customizable Area Start
    title: string;
    goalDate: string;
    showGoalDatePickerModal: boolean;
    loading: boolean;
    searchLocation: string;
    lat: string;
    lng: string;
    token: string;
    imageInputRef: React.RefObject<HTMLInputElement> | null;
    selectedImage: string | null;
    goalImageError: string | null;
    location: ILocation | null;
    description: string;
    isDatePickerOpen: boolean;
    currentFocusIndex: number | null;
    mapMenuAnchorEle: HTMLDivElement | null;
    selectedLocation: SearchLocation | null;
    predictions: SearchLocation[];
    recentLocations: SearchLocation[];
    base64Image: File | null;
    editMode: boolean;
    goalId: string;
    isFormUpdated: boolean;
    userId: number | null;
    reminderRadius: number;
    // Customizable Area End
}

interface SS {
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

// Customizable Area Start

// Customizable Area End

export default class AddEventDetailController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    searchLocationApiCallId: string = "";
    recentSearchLocationApiCallId: string = "";
    createGoalApiCallId: string = "";
    getGoalDetailsApiCallId: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        runEngine.attachBuildingBlock(this as IBlock, [
            // Customizable Area Start
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionResponseMessage),
            // Customizable Area End
        ]);
        this.receive = this.receive.bind(this);

        this.state = {
            // Customizable Area Start
            title: "",
            loading: false,
            token: "",
            showGoalDatePickerModal: false,
            goalDate: "",
            goalId: "",
            searchLocation: "Location",
            lat: "",
            lng: "",
            imageInputRef: React.createRef<HTMLInputElement>(),
            selectedImage: null,
            goalImageError: null,
            location: null,
            description: "",
            isDatePickerOpen: false,
            currentFocusIndex: null,
            mapMenuAnchorEle: null,
            selectedLocation: null,
            predictions: [],
            recentLocations: [],
            base64Image: null,
            editMode: false,
            isFormUpdated: false,
            userId: null,
            reminderRadius: 0,
            // Customizable Area End
        };
    }

    // Customizable Area Start
    async componentDidMount() {
        this.getToken();
        // Customizable Area Start
        const userDetail = await helper.getUserData();
        if (userDetail) {
            this.setState({
                userId: userDetail.id
            })
        }
        this.setToken();
        // Customizable Area End
    }

    // Customizable Area End
    getToken = () => {
        const message: Message = new Message(
            getName(MessageEnum.SessionRequestMessage)
        );
        this.send(message);
    };

    async receive(from: string, message: Message) {
        // Customizable Area Start

        const apiCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            switch (apiCallId) {
                case this.searchLocationApiCallId:
                    this.handleSearchLocationCall(message);
                    break;
                case this.recentSearchLocationApiCallId:
                    this.handleRecentSearchLocationCall(message);
                    break;
                case this.createGoalApiCallId:
                    this.handleCreateGoalCall(message);
                    break;
                case this.getGoalDetailsApiCallId:
                    this.handleGetGoalDetailApiCall(message);
                    break;
            }
        }
        // Customizable Area End
    }

    // Customizable Area Start
    setToken = async () => {
        const token = await getStorageData("authToken");
        this.setState({ token: token as string }, () => {
            this.getRecentLocations()
            const goalId = this.props.navigation.getParam("id");
            if (goalId) {
                this.setState({
                    goalId: goalId,
                    editMode: true
                }, () => {
                    this.getGoalDetail();
                })
            }
        });
    }

    onSelectImage = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const resultImg = await helper.covertToObjectUrl(event);

        if (!resultImg.imgSizeExceed) {
            this.setState({
                selectedImage: resultImg.selectedImage,
                goalImageError: null,
                isFormUpdated: true
            })
            if(event.target.files){
                this.setState({
                    base64Image: event.target.files[0]
                })
            }
        } else {
            this.setState({
                goalImageError: "Please upload JPG and PNG, up to 10 MB."
            })
        }
    }

    onChangeInputs = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const name = event.target.name;
        const maxLength = event.target.maxLength;
        let sanitizedValue = event.target.value.replace(/^\s+/, '');
        if (sanitizedValue.length <= maxLength) {
            sanitizedValue = sanitizedValue;
        } else {
            sanitizedValue = sanitizedValue.slice(0, maxLength);
        }
        this.setState(prevState => ({ ...prevState, [name]: sanitizedValue, isFormUpdated: true }))
    }

    openDatePicker = () => {
        const datePickerDialog = document.getElementById("mui-date-picker");
        datePickerDialog?.click();
    }

    onGoalDateChangeHandler = (value: Date | Moment | null) => {
        const formatDate = helper.formatDate(value, "YYYY-MM-DD");
        this.setState({
            goalDate: formatDate,
            isFormUpdated: true
        })
    }

    isGoalAddButtonDisabled = () => {
        const { title, goalDate, selectedLocation, isFormUpdated, editMode, reminderRadius } = this.state;
        if (!title || !goalDate || !selectedLocation || !reminderRadius) {
            return true;
        }

        if (editMode && !isFormUpdated) {
            return true;
        }
        return false;
    }

    redirectTo = (destination: string) => {
        this.props.navigation.navigate(destination);
    }

    openMapMenu = (event: React.MouseEvent<HTMLDivElement>) => {
        this.setState({
            mapMenuAnchorEle: event.currentTarget
        })
    }

    onSelectLocation = (location: SearchLocation | null) => {
        this.setState({
            selectedLocation: location,
            mapMenuAnchorEle: null,
            isFormUpdated: true
        })
    }

    onRadiusChange = (value: number) => {
        this.setState({ reminderRadius: value, isFormUpdated: true })
    }

    onCloseMapMenu = () => {
        this.setState({
            mapMenuAnchorEle: null
        })
    }

    onSubmitGoal = async () => {
        const { editMode, goalId } = this.state;
        helper.showLoader();
        if (editMode) {
            this.createGoalApiCallId = await helper.apiCall({
                method: "PUT",
                endPoint: `${configJSON.bucketListEndPoint}/${goalId}`,
                token: this.state.token,
                type: "formData",
                body: await this.getDataForGoalPost()
            })
        } else {
            this.createGoalApiCallId = await helper.apiCall({
                method: "POST",
                endPoint: `${configJSON.bucketListEndPoint}`,
                token: this.state.token,
                type: "formData",
                body: await this.getDataForGoalPost()
            })
        }
    }

    getRecentLocations = async () => {
        this.recentSearchLocationApiCallId = await helper.apiCall({
            method: "GET",
            endPoint: `${configJSON.recentLocationEndpoint}`,
            contentType: configJSON.validationApiContentType,
            token: this.state.token
        })
    }

    onSearchLocation = async (location: string) => {
        this.setState({
            searchLocation: location
        })
        this.searchLocationApiCallId = await helper.apiCall({
            method: "GET",
            endPoint: `${configJSON.locationEndpoint}?query=${location}`,
            contentType: configJSON.validationApiContentType,
            token: this.state.token
        })
    }

    handleSearchLocationCall = (message: Message) => {
        const responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );

        if (responseJson && !responseJson.errors && responseJson.predictions) {
            const locations: SearchLocation[] = [];
            responseJson.predictions.map((prediction: SearchLocation) => {
                const location: SearchLocation = {
                    description: prediction.description,
                    place_id: prediction.place_id,
                    structured_formatting: prediction.structured_formatting
                };
                locations.push(location);
            })
            this.setState({
                predictions: locations
            })
        } else {
            this.setState({
                predictions: []
            })
        }
    }

    handleRecentSearchLocationCall = (message: Message) => {
        const responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        if (responseJson && responseJson.data && responseJson.data.length) {
            const locations: SearchLocation[] = [];
            responseJson.data.map((prediction: { id: string, attributes: { [key: string]: string } }) => {
                const location: SearchLocation = {
                    description: "",
                    place_id: prediction.attributes.place_id,
                    structured_formatting: {
                        main_text: prediction.attributes.city,
                        secondary_text: `${prediction.attributes.state}, ${prediction.attributes.country}`
                    }
                };
                locations.push(location);
            })
            this.setState({
                recentLocations: locations
            })
        } else {
            this.setState({
                recentLocations: []
            })
        }
    }

    getDataForGoalPost = async () => {
        const formData = new FormData();
        const { title, goalDate, base64Image, selectedLocation, reminderRadius, description } = this.state;
        formData.append("bucketlist[title]", title);
        formData.append("bucketlist[description]", description);
        if (selectedLocation?.place_id) {
            formData.append("bucketlist[place_id]", selectedLocation?.place_id);
        }
        if (base64Image) {
            formData.append("bucketlist[image]", base64Image);
        }
        formData.append("bucketlist[target_date]", goalDate.toString());
        formData.append("bucketlist[reminder_radius]", reminderRadius.toString());
        return formData;
    }

    handleCreateGoalCall = (message: Message) => {
        const responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        helper.hideLoader();
        if (responseJson && responseJson.data) {
            const successToast = this.state.editMode ? "Goal updated successfully!" : "Goal created successfully!";
            helper.showSuccessToast(successToast);
            this.redirectTo("BucketList");
        } else {
            const createGoalErrMsg = responseJson?.errors?.[0]?.message ? responseJson.errors[0].message : "Something went wrong, please try again!";
            helper.showErrorToast(createGoalErrMsg);
        }
    }

    getGoalDetail = async () => {
        helper.showLoader();
        this.getGoalDetailsApiCallId = await helper.apiCall({
            method: "GET",
            endPoint: `${configJSON.bucketListEndPoint}/${this.state.goalId}`,
            contentType: configJSON.validationApiContentType,
            token: this.state.token
        })
    }

    handleGetGoalDetailApiCall = (message: Message) => {
        const responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        helper.hideLoader();
        if (responseJson && responseJson.data) {
            const responseData = responseJson.data.attributes;
            this.setState({
                selectedImage: responseData.image_url,
                title: responseData.title,
                goalDate: responseData.target_date,
                selectedLocation: responseData.location ? {
                    description: "",
                    place_id: responseData.location.place_id,
                    structured_formatting: {
                        main_text: responseData.location.city,
                        secondary_text: responseData.location.country
                    }
                } : null,
                description: responseData.description,
                reminderRadius: responseData.reminder_radius,
            })
        } else {
            this.redirectTo("BucketList")
        }

    }

    // Customizable Area End
}

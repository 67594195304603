// Customizable Area Start
import React from "react";
import CommentsListController, { Props } from "./CommentsListController.web";
import { Box, CircularProgress, Menu, Typography, styled } from "@material-ui/core";
const { HelperFunctions: helper } = require("../../../components/src/HelperFunctions");
import { MoreIcon, likeIcon } from "../../activityfeed/src/assets";
import FavoriteIcon from '@material-ui/icons/Favorite';
import Avatar from "../../activityfeed/src/components/Avatar.web";
import RepliesList from "./RepliesList.web";
import ReplyTextfield from "./components/ReplyTextfield.web";
import LikedUserListModal from "../../activityfeed/src/components/LikedUserListModal.web";
// Customizable Area End

export default class CommentsList extends CommentsListController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  CommentsListMenuOptionDropdown = () => {
    const { onDeleteComment } = this.props;
    const { 
        optionMenuAnchor,
        selectedCommentItem
      } = this.state;
    return (
        <CustomCommentListMenuOptions
            className="Custom_List_Menu_Comment"
            data-test-id="custom-list-menu-comment"
            open={Boolean(optionMenuAnchor)}
            onClose={this.closeCommentListOptionMenu}
            anchorEl={optionMenuAnchor}
            PaperProps={{
                style: {
                    transform: "translateY(0.5rem)"
                }
            }}
            MenuListProps={{
                "aria-labelledby": "basic-button",
            }}
            getContentAnchorEl={null}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
            transformOrigin={{
                horizontal: 'right',
                vertical: 'top',
            }}
        >
            <StyledCommentsListMenuOptionDropdown id="group-post-comment-options-menu" className="CommentsListMenuOptionDropdown_Web">
                <Box id="delete-group-post-comment-icon" className="item"
                    onClick={() => {
                        if (onDeleteComment) {
                            if(selectedCommentItem){
                             onDeleteComment(selectedCommentItem.id);
                            }
                            this.closeCommentListOptionMenu()
                        }
                    }}
                >
                    <svg id="delete-group-post-comment-icon-svg" width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M3.3077 17.5017C2.80898 17.5017 2.38302 17.3251 2.02982 16.9719C1.67661 16.6187 1.5 16.1928 1.5 15.694V3.00176H0.5V1.50179H4.99997V0.617188H11V1.50179H15.5V3.00176H14.5V15.694C14.5 16.1992 14.325 16.6267 13.975 16.9767C13.625 17.3267 13.1974 17.5017 12.6922 17.5017H3.3077ZM13 3.00176H2.99997V15.694C2.99997 15.7838 3.02883 15.8575 3.08652 15.9152C3.14422 15.9729 3.21795 16.0018 3.3077 16.0018H12.6922C12.7692 16.0018 12.8397 15.9697 12.9038 15.9056C12.9679 15.8415 13 15.771 13 15.694V3.00176ZM5.40385 14.0018H6.90382V5.00176H5.40385V14.0018ZM9.09613 14.0018H10.5961V5.00176H9.09613V14.0018Z" fill="white" />
                    </svg>
                    Delete
                </Box>
            </StyledCommentsListMenuOptionDropdown>
        </CustomCommentListMenuOptions>
    )
}

  CommentsList = () => {
    const { 
      commentsList,
      isCommentsLoading,
      userId,
      commentsMetaData,
      mentionsList = [],
    } = this.props;
    const { optionMenuAnchor, repliesList, replyMetaData, isLoadingReplies, loggedInUser, replyLikedUserList } = this.state;
    return (
        <StyledGroupPostComments className="Group_Post_Comments_Container">
            <Box className="group-comments-body">
                <div id="group-post-comment-top-div"></div>
                {
                    commentsList && commentsList.length > 0 && (
                        <Box className="group-comments-container">
                            {
                                commentsList.map((comment) => {
                                    return (
                                        <React.Fragment key={comment.id}>
                                            <Box className="group-post-comment">
                                                <Box className="group-post-comment-details">
                                                    <Box className="name">
                                                        <Avatar
                                                            text={comment.attributes.account_info.full_name}
                                                            image_url={comment.attributes.account_info.image_url}
                                                        />
                                                        <p>
                                                            {
                                                                comment.attributes.account_info.full_name
                                                            }
                                                            <span>{comment.attributes.raw_created_at}</span>
                                                        </p>

                                                    </Box>
                                                    {
                                                        userId === comment.attributes.account_id && (
                                                            <div>
                                                                <Box id="delete-group-post-comment-option-icon" style={{
                                                                    cursor: "pointer"
                                                                }} onClick={event => {
                                                                    this.openCommentListOptionMenu(event);
                                                                    this.setState({ selectedCommentItem: comment })
                                                                }}>
                                                                    <img src={MoreIcon} />
                                                                </Box>
                                                                {optionMenuAnchor && this.CommentsListMenuOptionDropdown()}
                                                            </div>
                                                        )
                                                    }
                                                </Box>
                                                <Box className="group-post-comment-text">{helper.replaceUsernamesWithStyling(comment.attributes.comment, this.handleGoToMentionedProfile)}</Box>
                                                <Box className="comment-like-replies-info-box" display={"flex"}>
                                                    <Box className="comment-like cursor-pointer">
                                                        <Typography onClick={() => this.props.fetchLikedUsersList?.(comment.id)} id="comment-liked-users" className="text-underline" component={"span"}>{comment.attributes.total_likes || 0} {comment.attributes.total_likes > 1 ? "Likes" : "Like"}.</Typography>
                                                        {comment.attributes.liked_by_me ? <FavoriteIcon  onClick={() => this.props.handleLikeDislikeComment?.(comment.id)} className="liked_by_me" /> : <img src={likeIcon} alt="like" id="like-comment" className="liked_by_me" onClick={() => this.props.handleLikeDislikeComment?.(comment.id)} />}
                                                    </Box>
                                                    <Box className="comment-reply">
                                                        {!comment.attributes.show_replies_section ? <Typography id="load-replies" className="cursor-pointer text-underline" onClick={() => this.handleOpenRepliesSection(comment.id)} component={"span"}>| {comment.attributes.reply_count || "0"} {comment.attributes.reply_count > 1 ? "Replies" : "Reply"}</Typography>
                                                            :
                                                            <Typography id="load-replies" className="cursor-pointer text-underline" onClick={() => this.handleHideRepliesSection(comment.id)} component={"span"}>| Hide replies</Typography>}
                                                    </Box>
                                                </Box>
                                            </Box>
                                            {comment.attributes.show_replies_section &&
                                                <>
                                                    <Box marginLeft={repliesList.length ? "45px" : "0px"} className={`reply-container show-line`}>
                                                        <RepliesList redirectTo={this.props.redirectTo} userId={userId} handleFetchNextPageReplies={() => this.handleFetchNextRepliesForCurrentComment(comment.id)} isRepliesLoading={isLoadingReplies} onDeleteReply={(replyId: string | number) => this.onDeleteCommentReply(replyId, comment.id)} repliesList={repliesList} replyMetaData={replyMetaData} focusOnReplyTextfield={this.focusOnReplyField} fetchLikedUsersList={this.getReplyLikedUsersList} handleLikeDislikeReply={this.onLikeReply} />
                                                    </Box>
                                                    {loggedInUser?.id &&
                                                        <Box display={"flex"} alignItems={"flex-start"} gridGap={"10px"} marginLeft={"34px"}>
                                                            <Box className="name">
                                                                <Avatar
                                                                    text={loggedInUser.full_name}
                                                                    image_url={loggedInUser.image_url}
                                                                />
                                                            </Box>
                                                            <ReplyTextfield replyMentionsList={mentionsList} onAddReply={(replyText: string) => this.addGroupCommentReply(comment.id, replyText)} parentId={comment.id} />
                                                        </Box>}
                                                </>
                                            }
                                        </React.Fragment>
                                    )
                                })
                            }
                        </Box>
                    )
                }
                {
                    commentsMetaData?.next_page &&
                    <Box onClick={this.props.handleFetchNextPageComments} className="more-comments">Load more comments</Box>
                }
                {
                    (!isCommentsLoading && commentsList?.length) === 0 && (
                        <Box className="no-comments">No comments found</Box>
                    )
                }
            </Box>

            {isCommentsLoading &&
                <Box className="circular-progress-loader">
                    <CircularProgress />
                </Box>
            }
            {
                replyLikedUserList && (
                    <LikedUserListModal list={replyLikedUserList} open={Boolean(replyLikedUserList)} onClose={()=> this.setReplyLikedUserList?.()} />
                )
            }
        </StyledGroupPostComments>
    );
  };

  // Customizable Area End

  render() {
    // Customizable Area Start

    return (
        <>
            {this.CommentsList()}
        </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const StyledGroupPostComments = styled("div")({
    "&.Group_Post_Comments_Container": {
        minHeight: "4rem",
        maxHeight: "26.8125rem",
        overflowY: "auto",
        display: "flex",
        flexDirection: "column",
        position: "relative",
        "& .reply-container": {
        },
        "& .show-line": {
            marginLeft: "2.5rem",
            position: "relative",
            "&::before": {
              content: '""',
              position: "absolute",
              left: "-24px",  // Adjust based on positioning
              top: "-45px",
              bottom: "-17px",
              width: "1px",
              backgroundColor: "rgba(25, 181, 185, 0.27)", 
            },
            "&::after": {
                content: '""',
                position: "absolute",
                left: "-23px", 
                bottom: "-17px", 
                width: "15px", 
                height: "1px",
                backgroundColor: "rgba(25, 181, 185, 0.27)", 
            }
        },
        "& .group-comments-body": {
            display: "flex",
            flex: 1,
            flexDirection: "column",
            overflowX: "auto",
            gap: "1rem",
            padding: "3px",
            "& .group-comments-container":{
                display: "flex",
                flexDirection: "column",
                "& .group-post-comment":{
                    display: "flex",
                    flexDirection: "column",
                    // borderBottom: "1px solid #2d4953",
                    position: "relative",
                    padding: "0.5rem 0rem",
                    "& .group-post-comment-details":{
                        display: "flex",
                        gap: "0.5rem",
                        justifyContent: "space-between",
                        alignItems: "center",
                        "& .name":{
                            display: "flex",
                            alignItems: "center",
                            gap: "0.5rem",
                            "& > p": {
                                fontSize: "0.75rem",
                                fontWeight: 400,
                                color: "rgba(255, 255, 255, 1)",
                                "& > span": {
                                    fontSize: "0.5rem",
                                    color: "rgba(255, 255, 255, 0.8)",
                                    marginLeft: "0.5rem"
                                }
                            }
                        }
                    },
                    "& .group-post-comment-text": {
                        fontSize: "0.875rem",
                        fontWeight: 400,
                        color: "rgba(255, 255, 255, 1)",
                        marginLeft: "2.5rem"
                    },
                    "& .comment-like-replies-info-box": {
                        alignItems: "center",
                        marginLeft: "2.5rem",
                        gap: "8px",
                        marginTop: "10px",
                        "& div": {
                            display: "flex",
                            alignItems: "center",
                            gap: "2px",
                            "& span": {
                                fontSize: "12px",
                                color: "rgba(255, 255, 255, 0.8)"
                            },
                            "& img": {
                                width: "12px",
                                height: "12px",
                            },
                            "& svg": {
                                width: "18px",
                                height: "18px",
                                color: "red"
                            }
                        },
                    },
                    "&:last-child":{
                        borderBottom: "none"
                    }
                }
            },
            "& .no-comments":{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontSize: "0.875rem",
                color: "white",
                fontWeight: "400",
                flex: 1
            },
            "& .more-comments": {
                fontSize: "12px",
                color: "rgba(255, 255, 255, 1)",
                fontWeight: "500",
                cursor: "pointer",
            }
        },
        "& .footer": {
            padding: "1rem",
            borderTop: "1px solid #2d4953",
            display: "flex"
        },
        "& .circular-progress-loader": {
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            "& .MuiCircularProgress-colorPrimary":{
                color: "#F75367 !important"
            }
        }
    }
})

const StyledCommentsListMenuOptionDropdown = styled("div")({
    "&.CommentsListMenuOptionDropdown_Web": {
        display: "flex",
        flexDirection: "column",
        border: "1px solid #19B5B945",
        width: "9.8125rem",
        background: "linear-gradient(180deg, #23404B 0%, #19282F 100%)",
        borderRadius: "0.5rem",
        padding: "0.25rem 0rem",
        "& .item": {
            display: "flex",
            alignItems: "center",
            color: "#ffffff",
            fontSize: "1rem",
            fontWeight: 400,
            gap: "0.5rem",
            cursor: "pointer",
            padding: "0.25rem 0.7rem",
            backgroundColor: "transparent",
            transition: "background-color 0.3s ease-in-out",
            "&:hover": {
                backgroundColor: "#0000001A"
            }
        }
    }
})

const CustomCommentListMenuOptions = styled(Menu)({
    "&.Custom_List_Menu_Comment": {
        "& .MuiList-padding": {
            padding: 0,
        },
        "& .MuiPaper-root": {
            background: "transparent"
        }
    }
})
// Customizable Area End

// Customizable Area Start
import React from "react";
import GroupChatInfoController, {
    Props
} from "./GroupChatInfoController";
import { styled } from "@material-ui/styles";
import { IconButton } from "@material-ui/core";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { closeIcon } from "../../postcreation/src/assets";
import { Button, ConfirmationPopUp, MorePopUp } from "../../../components/src";
import { EditIcon, DeleteIcon } from "../../appointmentmanagement/src/assets";
import { AddIcon } from "../../groups/src/assets";
const {
    HelperFunctions: helper,
} = require("../../../components/src/HelperFunctions");
// Customizable Area End

export default class GroupChatInfo extends GroupChatInfoController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start

    // Customizable Area End

    render() {
        // Customizable Area Start
        const {
          group,
          groupMembers,
          isFromAppointmentOrEvent,
          closeChatRoom,
          chatData
        } = this.props;
        const {
           confirmationPopUpData,
           uid
        } = this.state;
        return (
            <StyledGroupChatInfoContainer className="GroupChatInfo_StyledGroupChatInfoContainer">
                <div className="header">
                    <div className="left">
                        <IconButton onClick={this.props.closeInfo}>
                            {isFromAppointmentOrEvent ? <ArrowBackIosIcon id={"close_info"} /> : <img src={closeIcon} />}
                        </IconButton>
                        <p>Group Info</p>
                    </div>
                    <div className="right right-header">
                        {
                            group && group.getOwner() === uid && (
                                <MorePopUp
                                    menuItems={[
                                        {
                                            label: "Edit",
                                            icon: EditIcon,
                                            onClick: () => {
                                                if (this.props.onClickEditButton) {
                                                    this.props.onClickEditButton({ group, groupMembers, chatData })
                                                }
                                            }
                                        },
                                        {
                                            label: "Delete",
                                            icon: DeleteIcon,
                                            onClick: () => {
                                                if (group) {
                                                    this.openDeleteModal(group.getName(), group.getGuid())
                                                }
                                            }
                                        }
                                    ]}
                                />
                            )
                        }
                        {isFromAppointmentOrEvent &&
                            <img src={closeIcon} onClick={closeChatRoom} />
                        }
                    </div>
                </div>
                {
                    group && groupMembers && chatData && (
                        <div className="info">
                            <div className="chat-details">
                                <div className="chat-image">
                                    {helper.getInitials(group.getName())}
                                </div>
                                <p className="chat-name capital-text">{group.getName()}</p>
                                {
                                    chatData.appointment && (
                                        <p className="chat-type">Appointment: <span>{chatData.appointment.title}</span></p>
                                    )
                                }
                            </div>
                            <div className="chat-members-container">
                                <div className="total-members">
                                    <div className="left">
                                        <svg width="22" height="16" viewBox="0 0 22 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M0.797852 15.3106V13.0876C0.797852 12.5722 0.930868 12.1123 1.1969 11.7078C1.46292 11.3033 1.81818 10.9886 2.2627 10.7637C3.21305 10.2989 4.16867 9.94048 5.12955 9.68856C6.09045 9.43665 7.14654 9.31069 8.29783 9.31069C9.44911 9.31069 10.5052 9.43665 11.4661 9.68856C12.427 9.94048 13.3826 10.2989 14.333 10.7637C14.7775 10.9886 15.1327 11.3033 15.3988 11.7078C15.6648 12.1123 15.7978 12.5722 15.7978 13.0876V15.3106H0.797852ZM17.7978 15.3106V12.9645C17.7978 12.3081 17.6371 11.6825 17.3156 11.0878C16.9942 10.493 16.5382 9.98271 15.9478 9.55684C16.6183 9.65684 17.2549 9.81165 17.8574 10.0213C18.46 10.2309 19.0349 10.4786 19.5824 10.7645C20.099 11.0401 20.4981 11.365 20.7795 11.7391C21.0609 12.1132 21.2016 12.5217 21.2016 12.9645V15.3106H17.7978ZM8.29783 7.69524C7.33534 7.69524 6.51139 7.35254 5.82598 6.66714C5.14056 5.98172 4.79785 5.15777 4.79785 4.19529C4.79785 3.23279 5.14056 2.40884 5.82598 1.72344C6.51139 1.03802 7.33534 0.695312 8.29783 0.695312C9.26031 0.695312 10.0843 1.03802 10.7697 1.72344C11.4551 2.40884 11.7978 3.23279 11.7978 4.19529C11.7978 5.15777 11.4551 5.98172 10.7697 6.66714C10.0843 7.35254 9.26031 7.69524 8.29783 7.69524ZM16.9324 4.19529C16.9324 5.15777 16.5896 5.98172 15.9042 6.66714C15.2188 7.35254 14.3949 7.69524 13.4324 7.69524C13.3196 7.69524 13.176 7.68242 13.0016 7.65679C12.8273 7.63116 12.6837 7.60296 12.5709 7.57219C12.9652 7.09807 13.2683 6.5721 13.4801 5.99426C13.6919 5.41643 13.7978 4.81637 13.7978 4.19409C13.7978 3.57182 13.6898 2.97415 13.4738 2.40106C13.2577 1.828 12.9568 1.30044 12.5709 0.818388C12.7145 0.767105 12.858 0.733772 13.0016 0.718388C13.1452 0.703005 13.2888 0.695312 13.4324 0.695312C14.3949 0.695312 15.2188 1.03802 15.9042 1.72344C16.5896 2.40884 16.9324 3.23279 16.9324 4.19529Z" fill="white" fill-opacity="0.7" />
                                        </svg>
                                        <p>Members</p>
                                    </div>
                                    <div className="right">{groupMembers.length}</div>
                                </div>
                                {
                                    group.getOwner() === uid && (
                                        <Button
                                            text="Add Members"
                                            icon={AddIcon}
                                            type="link"
                                            buttonStyles={{
                                                width: "fit-content",
                                                padding: 0,
                                                fontSize: "1rem",
                                                margin: "0.8rem 0rem"
                                            }}
                                            onClick={() => this.props.onClickAddMemberButton && this.props.onClickAddMemberButton({ group, groupMembers, chatData })}
                                        />
                                    )
                                }
                                <div className="members-list">
                                    {
                                        groupMembers && groupMembers.map(member => {
                                            return (
                                                <div className="member" key={member.getUid()}>
                                                    <div className="avatar-text">
                                                        {member.getName()[0]}
                                                    </div>
                                                    <p className="capital-text">
                                                        {member.getName()}
                                                        {
                                                            member.getScope() === "admin" && (
                                                                <span>{" "}(Admin)</span>
                                                            )
                                                        }
                                                    </p>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                    )
                }
                {
                    confirmationPopUpData && (
                        <ConfirmationPopUp
                            open={Boolean(confirmationPopUpData)}
                            header={confirmationPopUpData.header}
                            message={confirmationPopUpData.message}
                            onReject={this.onCloseConfirmationModal}
                            onAccept={this.onAcceptConfirmation}
                            onClose={this.onCloseConfirmationModal}
                        />
                    )
                }
            </StyledGroupChatInfoContainer>
        );
        // Customizable Area End
    }
}

// Customizable Area Start
const StyledGroupChatInfoContainer = styled("div")({
    "&.GroupChatInfo_StyledGroupChatInfoContainer": {
        display: "flex",
        flexDirection: "column",
        height: "100%",
        "& > .header": {
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            height: "4.75rem",
            borderBottom: "1px solid rgba(255, 255, 255, 0.1)",
            boxSizing: "border-box",
            padding: "0rem 0.5rem",
            "& > .left": {
                display: "flex",
                alignItems: "center",
                fontSize: "1.125rem",
                fontWeight: 500,
                color: "rgba(255, 255, 255, 1)",
                "& #close_info": {
                    color: "#fff",
                    fontSize: "14px",
                }
            },
            "& > .right-header":{
                gap: "10px",
                display: "flex",
                alignItems: "center",
                "& img": {
                    marginRight: "15px",
                    cursor: "pointer"
                }
            }
        },
        "& > .info": {
            display: "flex",
            flexDirection: "column",
            height: "calc(100% - 4.75rem)",
            overflow: "auto",
            "& > .chat-details": {
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                textAlign: "center",
                marginBottom: "1rem",
                "& > .chat-image": {
                    width: "6.5rem",
                    height: "6.5625rem",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontSize: "3rem",
                    fontWeight: 400,
                    color: "rgba(255, 255, 255, 0.8)",
                    background: "rgba(0, 0, 0, 0.2)",
                    borderRadius: "1rem",
                    marginTop: "3.125rem",
                    marginBottom: "0.5rem",
                    textTransform: "uppercase"
                },
                "& > .chat-name": {
                    fontSize: "1rem",
                    fontWeight: 400,
                    color: "rgba(255, 255, 255, 1)"
                },
                "& > .chat-type": {
                    fontSize: "0.75rem",
                    fontWeight: 400,
                    color: "rgba(255, 255, 255, 0.8)",
                    "& > span": {
                        fontWeight: 500
                    }
                }
            },
            "& > .chat-members-container":{
                display: "flex",
                flexDirection: "column",
                padding: "0rem 1rem",
                boxSizing: "border-box",
                "& > .total-members":{
                    height: "3.3125rem",
                    width: "100%",
                    boxSizing: "border-box",
                    borderBottom: "1px solid rgba(255, 255, 255, 0.1)",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    flexShrink: 0,
                    "& > .left":{
                        display: "flex",
                        alignItems: "center",
                        gap: "1rem",
                        fontSize: "1rem",
                        fontWeight: 400,
                        color: "rgba(255, 255, 255, 1)"
                    },
                    "& > .right":{
                        fontSize: "1rem",
                        fontWeight: 400,
                        color: "rgba(255, 255, 255, 0.8)"
                    },
                },
                "& > .members-list":{
                    display: "flex",
                    flexDirection: "column",
                    "& > .member":{
                        display: "flex",
                        alignItems: "center",
                        gap: "1rem",
                        padding: "0.5rem 0rem",
                        fontSize: "0.875rem",
                        fontWeight: 400,
                        color: "rgba(255, 255, 255, 1)"
                    }
                }
            }
        }
    }
})
// Customizable Area End

// Customizable Area Start
import React from "react";
import TrendingPostsListController, { Props } from "./TrendingPostsListController";
import { Grid, styled } from "@material-ui/core";
import NavigationMenu from "../../navigationmenu/src/NavigationMenu.web";
import PostListCard from "../../activityfeed/src/components/PostListCard.web";
import CustomNoDataComponent from "../../../components/src/CustomNoDataComponent.web";
import CreatePostModal from "../../postcreation/src/components/CreatePostModal.web";
import ConfirmationPopUp from "../../../components/src/ConfirmationPopUp.web";
import { IPost } from "../../../components/src/CommonTypes";
// Customizable Area End

export default class TrendingPostsList extends TrendingPostsListController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    TrendingPostList = () => {
        const {
            trendingPostsList,
            selectedPostDetail,
            userId,
            isEdit,
            confirmationPostDeletePopUpData,
            currentPostCommentsList,
            likedUserList,
            isLoadingComments,
            searchedMentionsList,
            isPostUploading,
            postCommentsMetaData
        } = this.state;
        return (
            <StyledTrendingPostListContainer className="Trending_Post_List_Web_Container">
                <Grid container direction="row">
                    <Grid item xs={12} sm={6}>
                        <div
                            data-test-id="trending-post-list-back-btn"
                            className="back-btn"
                            onClick={() => this.props.navigation?.goBack()}
                        >
                            <svg
                                width="8"
                                height="12"
                                viewBox="0 0 8 12"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M5.99953 11.6553L0.345703 6.00148L5.99953 0.347656L7.05335 1.40148L2.45335 6.00148L7.05335 10.6015L5.99953 11.6553Z"
                                    fill="white"
                                />
                            </svg>
                            Trending Posts
                        </div>
                    </Grid>
                </Grid>
                <Grid container direction="row" spacing={2}>
                    {trendingPostsList.length > 0 ? (
                        trendingPostsList.map((post, index) => (
                            <Grid key={post.id} className="post-detail-card" item xs={12}>
                                <PostListCard
                                    userId={userId}
                                    handleFetchNextPage={this.handleChangePageNumber}
                                    isLastElement={index === trendingPostsList.length - 1}
                                    post={post}
                                    onEditPost={(post: IPost) => this.onOpenEditPostModal(post)}
                                    onDeletePost={(title: string, id: string | number) => this.openPostDeleteModal(title, id)}
                                    onOpenCommentsModal={this.openCommentsModal}
                                    comments={currentPostCommentsList}
                                    onLikePost={this.onLikeTrendingPost}
                                    setLikedUsers={this.setLikedUserList}
                                    fetchLikedUsers={this.getLikedUsersList}
                                    likedUserList={likedUserList}
                                    isLoadingComments={isLoadingComments}
                                    onAddComment={this.onAddNewComment}
                                    onDeleteComment={this.onDeleteComment}
                                    onCloseCommentsModal={this.closeCommentsModal}
                                    handleFetchNextComments={this.handleChangeCommentsPageNumber}
                                    mentionesUserList={searchedMentionsList}
                                    fetchLikedUsersForComment={this.getCommentLikedUsersList}
                                    commentsMetaData={postCommentsMetaData}
                                    onLikeGroupPostComment={this.onLikeComment}
                                    handleReplySectionHide={this.handleHideReplySection}
                                    handleReplySectionShow={this.handleShowReplySection}
                                    handleUpdateRepliesCount={this.handleUpdatePostCommentsReplyCount}
                                    isPostCommentSectionOpen={post.attributes.show_comments_section}
                                />
                            </Grid>
                        ))
                    ) : (
                        <CustomNoDataComponent
                            titleMsg="No Trending Posts"
                            subTitleMsg="No Trending posts available. Stay tuned for the updates."
                        />
                    )}
                </Grid>
                <ConfirmationPopUp
                    open={Boolean(confirmationPostDeletePopUpData)}
                    header={confirmationPostDeletePopUpData?.header as string}
                    message={confirmationPostDeletePopUpData?.message as string}
                    onAccept={this.onAcceptPostDeleteConfirmation}
                    onReject={this.onCloseDeleteConfirmationModal}
                    onClose={this.onCloseDeleteConfirmationModal}
                />
                <CreatePostModal
                    postDetails={selectedPostDetail}
                    onCloseCreatePostModal={() => this.onCloseCreatePostModal()}
                    isEditMode={isEdit}
                    onSubmitPostDetails={this.onClickShareTrendingPost}
                    isPostUploading={isPostUploading}
                    onRemoveAttachmentFromPost={this.onRemoveAttachmentFromPost}
                    handleUpdateFilesUploaded={(number) => this.setState({ numberOfFilesUploaded: number })}
                />
            </StyledTrendingPostListContainer>
        );
    };

    // Customizable Area End

    render() {
        // Customizable Area Start

        return <NavigationMenu {...this.props} children={this.TrendingPostList()} />;
        // Customizable Area End
    }
}

// Customizable Area Start
const StyledTrendingPostListContainer = styled("div")({
    "&.Trending_Post_List_Web_Container": {
        display: "flex",
        flexDirection: "column",
        padding: "1rem 1rem",
        gap: "1.5rem",
        "& .ellipsis": {
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            overflow: "hidden",
        },
        "& .back-btn": {
            fontSize: "1.125rem",
            fontWeight: 500,
            color: "white",
            display: "flex",
            gap: "0.5rem",
            alignItems: "center",
            cursor: "pointer",
            width: "fit-content",
        },
    },
});
// Customizable Area End

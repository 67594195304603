import React, { useState, useEffect } from "react";
import { RouteComponentProps } from "react-router-dom";
import { getStorageData } from "framework/src/Utilities";


type StaticContext = {
    statusCode?: number;
}

type Props = RouteComponentProps<{
    [key: string]: string | undefined
}, StaticContext, unknown> & { children: React.ReactNode, routeMap: {[key:string]: { path: string }} }



export default function ProtectedRoute(props: Props){
    const { routeMap } = props;
    const publicRoutes = new Set([routeMap["EmailAccountRegistrationBlock"].path, routeMap["EmailAccountRegistrationBlock2"].path, routeMap["EmailAccountLoginBlock"].path, routeMap["OTPInputAuth"].path]);
    
    const [ isUserLoggedIn, setIsUserLoggedIn ] = useState<boolean>(false);

    useEffect(()=>{
      (async ()=>{
        const token = await getStorageData("authToken");
        const userData = await getStorageData("userData", true);

        const isPublicRoute = publicRoutes.has(props.location.pathname);

        if((!token || !userData) && !isPublicRoute){
            props.history.push(routeMap["EmailAccountLoginBlock"].path)
            return;
        }

        setIsUserLoggedIn(true)

        if(token && isPublicRoute){
            props.history.push(routeMap["Dashboard"].path)
            return;
        }
      })()
    },[])

    return isUserLoggedIn ? props.children : null;
}
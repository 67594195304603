// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
export const configJSON = require("./config.js");
const {
    HelperFunctions: helper,
} = require("../../../components/src/HelperFunctions");
import { IComment, IEventCard, IEventData, IInterest, SearchLocation } from "../../../components/src/CommonTypes";
import { LatLngExpression } from 'leaflet';
import { ITrendingPost } from "./types"
// Customizable Area End

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

export interface S {
    // Customizable Area Start
    token: string | null;
    bestEvents: IEventCard[];
    filterAnchorEle: HTMLButtonElement | null;
    recentLocations: SearchLocation[];
    predictions: SearchLocation[];
    selectedNearByLocationCoordinates: LatLngExpression | null;
    interests: IInterest[];
    currentSelectedInterest: IInterest[];
    selectedInterest: IInterest[];
    trendingPost: ITrendingPost | null;
    isLoadingCommentsList: boolean;
    commentsList: IComment[];
    isAllCommentsFetched: boolean;
    selectedCommentId: string | number;
    postCommentModal: {
        id: string;
    } | null;
    userId: number;
    likedUserList: { id: string | number, attributes: { like_by_name: string} }[] | undefined;
    filters: {
        position?: LatLngExpression | null,
        location?: SearchLocation | null,
        radius?: number
    };
    // Customizable Area End
}

export interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export const groupData = [
    {
        id: 1,
        title: "Graphic Coper",
        description: "Keval: You not have any builder code on your dawd dawd dawd"
    },
    {
        id: 2,
        title: "Graphic Coper",
        description: "Keval: You not have any builder code on your dawd dawd dawd"
    },
    {
        id: 3,
        title: "Graphic Coper",
        description: "Keval: You not have any builder code on your dawd dawd dawd"
    },
    {
        id: 4,
        title: "Graphic Coper",
        description: "Keval: You not have any builder code on your dawd dawd dawd"
    },
    {
        id: 5,
        title: "Graphic Coper",
        description: "Keval: You not have any builder code on your dawd dawd dawd"
    },
    {
        id: 6,
        title: "Graphic Coper",
        description: "Keval: You not have any builder code on your dawd dawd dawd"
    },
    {
        id: 7,
        title: "Graphic Coper",
        description: "Keval: You not have any builder code on your dawd dawd dawd"
    },
    {
        id: 8,
        title: "Graphic Coper",
        description: "Keval: You not have any builder code on your dawd dawd dawd"
    }
]

export default class ExploreController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    getBestEventApiCallId: string = "";
    recentSearchLocationApiCallId?: string = "";
    searchLocationApiCallId?: string = "";
    getPlaceCoordinatesApiId?: string = "";
    fetchInterestsApiCallId?: string = "";
    addNewInterestApiCallId?: string = "";
    getTrendingPostApiCallId: string = "";
    getCommentsListApiCallId: string = "";
    addCommentInRecentPostApiCallId: string = "";
    deleteCommentFromRecentPostApiCallId: string = "";
    likedUsersListApiCallId: string = "";
    likePostApiCallId: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            // Customizable Area Start
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionResponseMessage),
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            token: "",
            bestEvents: [],
            filterAnchorEle: null,
            recentLocations: [],
            predictions: [],
            selectedNearByLocationCoordinates: null,
            interests: [],
            currentSelectedInterest: [],
            selectedInterest: [],
            trendingPost: null,
            isLoadingCommentsList: false,
            commentsList: [],
            isAllCommentsFetched: false,
            selectedCommentId: "",
            postCommentModal: null,
            userId: 0,
            likedUserList: undefined,
            filters: {
                position: null,
                location: null
            },
            // Customizable Area End
        };

        // Customizable Area Start
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async componentDidMount() {
        await super.componentDidMount();
        this.getToken();
        // Customizable Area Start
        const token = await helper.getStorageData("authToken");
        this.setToken(token);
        const userData = await helper.getUserData();
        if(userData){
            this.setState({
                userId: userData.id
            })
        }
        // Customizable Area End
    }

    getToken = () => {
        const message: Message = new Message(
            getName(MessageEnum.SessionRequestMessage)
        );
        this.send(message);
    };

    receive = async (from: string, message: Message) => {
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const webApiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            switch (webApiRequestCallId) {
                case this.getBestEventApiCallId:
                    this.handleGetBestEventsApiCall(message);
                    break;
                case this.recentSearchLocationApiCallId:
                    this.handleRecentSearchLocationCall(message);
                    break;
                case this.searchLocationApiCallId:
                    this.handleSearchLocationCall(message);
                    break;
                case this.getPlaceCoordinatesApiId:
                    this.handleLocationCoordinatesApiCall(message);
                    break;
                case this.fetchInterestsApiCallId:
                    this.handleFetchInterestsAPICall(message);
                    break;
                case this.addNewInterestApiCallId:
                    this.handleAddNewInterestApiCall(message);
                    break;
                case this.getTrendingPostApiCallId:
                    this.handleGetTrendingPostApiCall(message);
                    break;
                case this.getCommentsListApiCallId:
                    this.handleGetCommentsApiCall(message);
                    break;
                case this.addCommentInRecentPostApiCallId:
                    this.handleAddPostCommentApiCall(message);
                    break;
                case this.deleteCommentFromRecentPostApiCallId:
                    this.handleDeletePostCommentApiCall(message);
                    break;
                case this.likedUsersListApiCallId:
                    this.handleGetPostLikedUsersListCall(message);
                    break;
                case this.likePostApiCallId:
                    this.likePostApiCallHandler(message);
                    break;
            }
        }

        // Customizable Area End
    };

    // Customizable Area Start

    setToken = async (token: string | null) => {
        this.setState({ token }, () => {
            this.getBestEvents();
            this.getRecentLocations();
            this.fetchInterests();
            this.getTrendingPosts()
        })
    }

    getBestEvents = async () => {
        helper.showLoader();
        let endPoint = configJSON.bestEventsAPIEndpoint;
        const { filters } = this.state;
        if (filters && filters.position) {
            let position: string | string[] = filters.position.toString();
            position = position.replace(/(^[^(]*\()|(\)[^)]*$)/g, '');
            position = position.split(",");
            endPoint += `?current_latitude=${position[0]}&current_longitude=${position[1]}&radius_in_km=${filters.radius}`
        }
        this.getBestEventApiCallId = await helper.apiCall({
            method: configJSON.apiMethod,
            endPoint,
            contentType: configJSON.apiContentType,
            token: this.state.token
        })
    }

    handleGetBestEventsApiCall = (message: Message) => {
        const responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        if (responseJson && responseJson.data) {
            const eventData = responseJson.data.map((event: IEventData) => {
                return {
                    image: event.attributes.image_url,
                    title: event.attributes.title,
                    id: event.id,
                    location: event.attributes.location?.city,
                    time: helper.addDateAndTime(event.attributes.date_time_event, event.attributes.start_time_event, "DD MMM, ddd hh:mm A"),
                    price: "",
                    isTrending: false,
                }
            })
            this.setState({
                bestEvents: eventData
            })
        }
        helper.hideLoader();
    }

    redirectTo = (endpoint: string, params?: { [key: string]: string | number }) => {
        this.props.navigation.navigate(endpoint, params)
    }

    openFilterPopup = (event: React.MouseEvent<HTMLButtonElement>) => {
        this.setState({
            filterAnchorEle: event.currentTarget
        })
        this.getRecentLocations()
    }

    closeFilterPopup = () => {
        this.setState({
            filterAnchorEle: null
        })
    }

    getRecentLocations = async () => {
        this.recentSearchLocationApiCallId = await helper.apiCall({
            method: configJSON.apiMethod,
            token: this.state.token,
            endPoint: `bx_block_maps3/locations/recent_location?type=event`,
            contentType: configJSON.apiContentType
        })
    }

    handleRecentSearchLocationCall = (message: Message) => {
        const responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        if (responseJson && responseJson.data && responseJson.data.length) {
            const recentLocations: SearchLocation[] = [];
            responseJson.data.map((prediction: { attributes: { [key: string]: string }, id: string }) => {
                const location: SearchLocation = {
                    place_id: prediction.attributes.place_id,
                    structured_formatting: {
                        main_text: prediction.attributes.city,
                        secondary_text: `${prediction.attributes.state}, ${prediction.attributes.country}`
                    },
                    description: "",
                };
                recentLocations.push(location);
            })
            this.setState({
                recentLocations: recentLocations
            })
        } else {
            this.setState({
                recentLocations: []
            })
        }
    }

    onSearchLocation = async (searchLocationString: string) => {
        this.searchLocationApiCallId = await helper.apiCall({
            method: configJSON.apiMethod,
            token: this.state.token,
            endPoint: `bx_block_maps3/locations?query=${searchLocationString}`,
            contentType: configJSON.apiContentType
        })
    }

    handleSearchLocationCall = (message: Message) => {
        const responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );

        if (responseJson && !responseJson.errors && responseJson.predictions) {
            const predictions: SearchLocation[] = [];
            responseJson.predictions.map((prediction: SearchLocation) => {
                const location: SearchLocation = {
                    place_id: prediction.place_id,
                    structured_formatting: prediction.structured_formatting,
                    description: prediction.description,
                };
                predictions.push(location);
            })
            this.setState({
                predictions: predictions
            })
        } else {
            this.setState({
                predictions: []
            })
        }
    }

    handleLocationCoordinatesApiCall = (message: Message) => {
        const responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        if (responseJson && responseJson.coordinates && responseJson.coordinates.length) {
            this.setState({ selectedNearByLocationCoordinates: responseJson.coordinates })
        }
    }

    getLocationCoordinates = async (place: string) => {
        this.getPlaceCoordinatesApiId = await helper.apiCall({
            method: configJSON.apiMethod,
            token: this.state.token,
            endPoint: `${configJSON.detectCoordinatesApiEndpoint}?address=${place}`,
            contentType: configJSON.apiContentType
        })
    }

    fetchInterests = async () => {
        this.fetchInterestsApiCallId = await helper.apiCall({
            method: configJSON.apiMethod,
            contentType: configJSON.apiContentType,
            token: this.state.token,
            endPoint: configJSON.interestApiEndPoint
        })
    }

    handleFetchInterestsAPICall = (message: Message) => {
        const responseData = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        if (responseData && responseData.data) {
            this.setState({
                interests: responseData.data
            })
        } else {
            this.setState({
                interests: []
            })
        }
    }

    onSubmitNewInterest = async (event: string) => {
        this.addNewInterestApiCallId = await helper.apiCall({
            contentType: configJSON.apiContentType,
            method: configJSON.postApiMethod,
            endPoint: configJSON.addNewInterestApiEndPoint,
            body: {
                name: event
            }
        })
    }

    handleAddNewInterestApiCall = (message: Message) => {
        const responseData = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        if (responseData && responseData.data) {
            const interest = responseData.data;
            interest.attributes.manual = true;
            this.setState((prevState) => {
                return {
                    currentSelectedInterest: [...prevState.currentSelectedInterest, interest]
                }
            })
            this.fetchInterests();
        }
    }

    onAddInterest = (interests: IInterest[]) => {
        this.setState({
            selectedInterest: interests
        })
    }

    getTrendingPosts = async () => {
        this.getTrendingPostApiCallId = await helper.apiCall({
            contentType: configJSON.apiContentType,
            method: configJSON.apiMethod,
            endPoint: configJSON.trendingPostApiEndpoint,
            token: this.state.token
        })
    }

    handleGetTrendingPostApiCall = (message: Message) => {
        const responseData = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        if (responseData && !responseData.errors) {
            this.setState({
                trendingPost: responseData
            })
        }
    }

    getCommentsList = async (postId: string, pageNumber: number = 1) => {
        this.setState({
            isLoadingCommentsList: true
        })

        this.getCommentsListApiCallId = await helper.apiCall({
            method: configJSON.apiMethod,
            contentType: configJSON.apiContentType,
            token: this.state.token,
            endPoint: `${configJSON.commentsApiEndpoint}?page_no=${pageNumber}&per_page=10&commentable_id=${postId}&order=desc`,
        });
    }

    handleGetCommentsApiCall = (message: Message) => {
        const responseData = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        const {
            commentsList
        } = this.state;
        if (!responseData?.meta?.next_page) {
            this.setState({ isAllCommentsFetched: true })
        }
        if (responseData && responseData.data) {
            this.setState({
                commentsList: [...commentsList, ...responseData.data]
            })
        }
        this.setState({
            isLoadingCommentsList: false
        })
    }

    getNextComments = (postId: string, pageNumber: number = 1) => {
        if (!this.state.isAllCommentsFetched) {
            this.getCommentsList(postId, pageNumber)
        }
    }

    onOpenComments = (postId: string, pageNumber: number = 1) => {
        this.setState({
            postCommentModal: {
                id: postId
            }
        }, () => { this.getCommentsList(postId, pageNumber) })
    }

    onCloseComments = () => {
        this.setState({
            commentsList: [],
            isAllCommentsFetched: false,
            postCommentModal: null
        })
    }

    onAddPostComment = async (comment: { text: string, postId: string }) => {
        this.setState({
            isLoadingCommentsList: true
        })
        const body = {
            comment: {
                commentable_id: comment.postId,
                comment: comment.text,
                commentable_type: "BxBlockPosts::Post",
            }
        }
        this.addCommentInRecentPostApiCallId = await helper.apiCall({
            method: configJSON.postApiMethod,
            contentType: configJSON.apiContentType,
            token: this.state.token,
            endPoint: `${configJSON.commentsApiEndpoint}`,
            body
        });
    }

    scrollToTopOnPostComment = () => {
        const topCommentDiv = document.getElementById("top_comment_div");
        if (topCommentDiv) {
            topCommentDiv.scrollTo({
                top: 0,
                behavior: "smooth"
            })
        }
    }

    handleAddPostCommentApiCall = (message: Message) => {
        const responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        if (responseJson && responseJson.data) {
            const { trendingPost, commentsList } = this.state;
            let updatedCommentsList = [{ ...responseJson.data }, ...commentsList];
            if (trendingPost) {
                let updatedTrendingPost = { ...trendingPost };
                updatedTrendingPost.post.data.attributes.comments_count += 1;
                this.setState({ trendingPost: updatedTrendingPost })
            }
            this.setState({
                commentsList: updatedCommentsList,
            })
            this.scrollToTopOnPostComment();
        } else {
            helper.showErrorToast("Something went wrong! please try again")
        }
        this.setState({
            isLoadingCommentsList: false
        })
    }

    onDeletePostComment = async (commentId: string) => {
        this.setState({
            isLoadingCommentsList: true,
            selectedCommentId: commentId,
        })
        const { token, postCommentModal, trendingPost } = this.state;
        if (trendingPost && postCommentModal) {
            const eventId = trendingPost.post.data.attributes.event_id ? trendingPost.post.data.attributes.event_id : trendingPost.post.data.attributes.group_id;

            this.deleteCommentFromRecentPostApiCallId = await helper.apiCall({
                method: configJSON.deleteApiMethod,
                endPoint: `${configJSON.commentsApiEndpoint}/${commentId}?event_id=${eventId}&commentable_id=${postCommentModal.id}`,
                contentType: configJSON.apiContentType,
                token
            })
        }
    }

    handleDeletePostCommentApiCall = (message: Message) => {
        const responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        if (responseJson && responseJson.message) {
            const { trendingPost, commentsList, selectedCommentId } = this.state;
            let updatedCommentsList = commentsList.filter((item) => item.id !== selectedCommentId);
            if (trendingPost) {
                let updatedRecentPost = { ...trendingPost };
                updatedRecentPost.post.data.attributes.comments_count -= 1;
                this.setState({ trendingPost: updatedRecentPost })
            }
            this.setState({
                commentsList: updatedCommentsList,
            })
        } else {
            helper.showErrorToast("Something went wrong! please try again")
        }
        this.setState({
            isLoadingCommentsList: false,
            selectedCommentId: "",
        })
    }

    onOpenLikedUserList = (postId: string)=>{
       this.getLikedUsersList(postId);
    }

    onCloseLikedUserList = ()=>{
       this.setState({
        likedUserList: undefined
       })
    }

    getLikedUsersList = async (postId: string) => {
        helper.showLoader();
        this.likedUsersListApiCallId = await helper.apiCall({
            method: configJSON.apiMethod,
            contentType: configJSON.apiContentType,
            token: this.state.token,
            endPoint: `${configJSON.likedUsersListApiEndpoint}?post_id=${postId}`,
        });
    }

    handleGetPostLikedUsersListCall = (message: Message) => {
        const responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        if (responseJson && responseJson.data && responseJson.data.data) {
            this.setState({
                likedUserList: [...responseJson.data.data],
            });
        } else {
            this.setState({ likedUserList: [] });
        }
        helper.hideLoader();
    };

    onLikePost = async (postId: string) => {
        const body = {
            likeable_id: postId,
            like_type: "post",
        };
        this.likePostApiCallId = await helper.apiCall({
            method: configJSON.postApiMethod,
            contentType: configJSON.apiContentType,
            token: this.state.token,
            endPoint: `${configJSON.likePostEndPoint}`,
            body,
        });
        this.likePost();
    };

    likePostApiCallHandler = (message: Message) => {
        const responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );

        if(!responseJson || (!responseJson.message && !responseJson.data)){
            this.likePost();
        }
        
        helper.hideLoader();
    };

    likePost = ()=>{
        const { trendingPost } = this.state;
        if(trendingPost){
            const updatedTrendingPost = {...trendingPost};
            if(updatedTrendingPost.post.data.attributes.liked_by_me){
                updatedTrendingPost.post.data.attributes.total_likes -= 1;
                updatedTrendingPost.post.data.attributes.liked_by_me = false;
            }else{
                updatedTrendingPost.post.data.attributes.total_likes += 1;
                updatedTrendingPost.post.data.attributes.liked_by_me = true;
            }
            this.setState({
                trendingPost: updatedTrendingPost
            })
        }
    }

    applyFilter = (filter: { position: LatLngExpression | null, location: SearchLocation | null, radius?: number }) => {
        let {
            selectedInterest
        } = this.state;
        if(!filter.position && !filter.location && !filter.radius){
            selectedInterest = [];
        }
        this.setState({
            filters: filter,
            selectedInterest,
        }, () => {
            this.getBestEvents();
            this.closeFilterPopup();
        })
    }


    // Customizable Area End
}
import React, { useState } from "react";
import { styled } from "@material-ui/styles";
import { Dialog } from "@material-ui/core";
import { closeIcon, searchIcon } from "../../../appointmentmanagement/src/assets";
import { IAppointmentData, IEventData } from "../../../../components/src/CommonTypes";
const {
    HelperFunctions: helper,
} = require("../../../../components/src/HelperFunctions");

interface Props {
    onClose: () => void;
    appointments?: IAppointmentData[];
    events?: IEventData[]; 
    type?: "events" | "appointments";
    onSelectData?: (event: IAppointmentData | IEventData) => void;
    onSearch?: (event: string)=>void;
}

const SelectAppointmentAndEvent: React.FC<Props> = (props: Props) => {
    const { 
        onClose, 
        appointments = [], 
        onSelectData = () => {}, 
        onSearch= ()=> {},
        events = [],
        type
    } = props;
    
    const [searchString, setSearchString] = useState("");

    const searchHandler = (query: string)=>{
       onSearch(query);
       setSearchString(query);
    }

    return (
        <Dialog
            open={true}
            onClose={onClose}
            PaperProps={{
                style: {
                    background: "transparent"
                }
            }}
        >
            <StyledSelectAppointmentAndEvent className="GroupChat_StyledSelectAppointmentAndEvent">
                <div className="header">
                    <p>Select {type === "appointments" ? "Appointment" : "Event"}</p>
                    <img src={closeIcon} alt="close.svg" onClick={onClose} />
                </div>
                <div className="body">
                    <div className="search-container">
                        <img src={searchIcon} alt="search.svg" />
                        <input placeholder="Search" data-test-id="add-contact-modal" value={searchString} onChange={(event) => searchHandler(event.target.value)} />
                    </div>
                    <p className="data-title">
                        {
                            type === "appointments" ? "Appointments" : "Events"
                        }
                    </p>
                    <div className="data">
                        {
                            type === "appointments" && appointments.length > 0 && appointments.map(appointment => {
                                return (
                                    <div className="item appointment_items" key={appointment.id} onClick={() => onSelectData(appointment)}>
                                        <div className="appointment-image">
                                            {helper.getInitials(appointment.attributes.title)}
                                        </div>
                                        <p>{appointment.attributes.title}</p>
                                    </div>
                                )
                            })
                        }
                        {
                            type === "events" && events.length > 0 && events.map(event => {
                                return (
                                    <div className="item appointment_items" key={event.id} onClick={() => onSelectData(event)}>
                                        {
                                            event.attributes.image_url && (
                                                 <img src={event.attributes.image_url} className="appointment-image" />
                                            )
                                        }
                                        {
                                            !event.attributes.image_url && (
                                                <div className="appointment-image">
                                            {helper.getInitials(event.attributes.title)}
                                        </div>
                                            )
                                        }
                                        <p>{event.attributes.title}</p>
                                    </div>
                                )
                            })
                        }
                        {
                            type === "appointments" && appointments.length === 0 && (
                                <div className="no-data">No data found</div>
                            )
                        }

                        {
                            type === "events" && events.length === 0 && (
                                <div className="no-data">No data found</div>
                            )
                        }
                    </div>
                </div>

            </StyledSelectAppointmentAndEvent>
        </Dialog>
    )
}

const StyledSelectAppointmentAndEvent = styled("div")({
    "&.GroupChat_StyledSelectAppointmentAndEvent": {
        display: "flex",
        flexDirection: "column",
        backgroundColor: "#23404B",
        boxShadow: "0px 0px 20px 0px #00000040",
        borderRadius: "1rem",
        width: "26.25rem",
        height: "34.3125rem",
        "& > .header": {
            position: "relative",
            padding: "1rem",
            borderBottom: "1px solid #2d4953",
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
            "& p": {
                margin: 0,
                fontWeight: 500,
                color: "white",
                fontSize: "1.125rem",
            },
            "& img": {
                position: "absolute",
                right: "1rem",
                top: "50%",
                transform: "translate(-50%,-50%)",
                cursor: "pointer"
            }
        },
        "& > .body": {
            display: "flex",
            flex: 1,
            flexDirection: "column",
            overflow: "auto",
            gap: "0.5rem",
            padding: "1rem",
            "& > .search-container": {
                display: "flex",
                borderRadius: "6.25rem",
                padding: "0.75rem 1rem",
                backgroundColor: "#00000026",
                gap: "0.5rem",
                "& input": {
                    outline: "none",
                    border: "none",
                    width: "100%",
                    color: "rgba(215, 215, 215, 1)",
                    fontSize: "0.875rem",
                    fontWeight: 400,
                    background: "transparent",
                    "&::placeholder": {
                        color: "rgba(215, 215, 215, 1)",
                        fontSize: "0.875rem",
                        fontWeight: 400,
                    }
                }
            },
            "& > .data-title": {
                fontSize: "0.75rem",
                fontWeight: 500,
                color: "rgba(255, 255, 255, 1)"
            },
            "& > .data": {
                display: "flex",
                flexDirection: "column",
                flex: 1,
                "& > .item": {
                    display: "flex",
                    gap: "1rem",
                    alignItems: "center",
                    padding: "0.5rem 0rem",
                    borderBottom: "1px solid #2d4953",
                    cursor: "pointer",
                    "& > .appointment-image": {
                        width: "3.3125rem",
                        height: "3.3125rem",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        textTransform: "uppercase",
                        background: "rgba(0, 0, 0, 0.2)",
                        borderRadius: "0.5rem",
                        fontSize: "1.5rem",
                        fontWeight: 400,
                        color: "rgba(255, 255, 255, 0.8)"
                    },
                    "& > p": {
                        fontSize: "1rem",
                        fontWeight: 500,
                        color: "rgba(255, 255, 255, 1)"
                    },
                    "&:last-child": {
                        borderBottom: "none"
                    },
                    "&:first-child": {
                        paddingTop: "0"
                    },
                },
                "& > .no-data":{
                    flex: 1,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontSize: "1rem",
                    fontWeight: 400,
                    color: "white"
                }
            },
        },
    }
})

export default SelectAppointmentAndEvent;
import React, { useState } from "react";
import { styled } from "@material-ui/styles";
import { Group3Icon } from "../assets";
import { Box } from "@material-ui/core";
interface Props {
    image_url?: string;
    imageContainerStyles?: React.CSSProperties
    defaultImageStyles?: React.CSSProperties
}


const GroupImage: React.FC<Props> = (props: Props) => {
    const { image_url, imageContainerStyles, defaultImageStyles } = props;
    const [isImageLoading, setIsImageLoading] = useState(true);

    return (
        <StyledGroupImage style={imageContainerStyles} className="Groups_GroupImage_StyledGroupImage">
            {
                image_url && (
                    <img className="image" id="group-image" src={image_url} onLoad={() => setIsImageLoading(false)} />
                )
            }
            {
                isImageLoading && (
                    <Box width={"inherit"} height={"inherit"} borderRadius={"inherit"} top={"0px"} position={"absolute"} className="shimmer"></Box>
                )
            }
            {
                (!image_url) && (
                    <img style={defaultImageStyles} id="group-default-image" src={Group3Icon} onLoad={() => setIsImageLoading(false)} />
                )
            }
        </StyledGroupImage>
    )
}

const StyledGroupImage = styled("div")({
    "&.Groups_GroupImage_StyledGroupImage": {
        width: "3.0625rem",
        height: "3.0625rem",
        borderRadius: "0.5rem",
        overflow: "hidden",
        userSelect: "none",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "relative",
        background: "linear-gradient(180deg, #23404B 0%, #19282F 100%)",
        border: "0.52px solid rgba(25, 181, 185, 0.27)",
        flexShrink: 0,
        "& > img": {
            "&.image": {
                width: "100%",
                height: "100%"
            },
            flexShrink: 0
        }
    }
})

export default GroupImage;
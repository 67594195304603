// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
export const configJSON = require("./config.js");
import { deleteRoutine, getFilteredRoutineList, handleDeleteRoutine, handleGetRoutineListApiCall } from "./actions";
import { ITask } from "../../../components/src/CommonTypes";
const { HelperFunctions: helper } = require("../../../components/src/HelperFunctions");
import moment from "moment";
// Customizable Area End

export interface ITaskItem {
    id: string;
    title: string;
    description: string | null;
    recurrence: string;
    start_time: string;
    end_time?: string;
    completed?: boolean;
    lapsed?: boolean;
    start_date: string;
    end_date?: string;
    status: string;
}

export interface ITransformedTask {
    id: string;
    formattedDate: string;
    items: ITaskItem[];
}

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

export interface S {
    // Customizable Area Start
    token: string | null;
    isDatePickerOpen: boolean;
    currentDate: Date;
    routinePageNum: number;
    isAllRoutineFetch: boolean;
    tasks: ITransformedTask[];
    confirmationPopUpData: {
        header: string;
        message: string;
        type: string;
        routineId: string;
    } | null;
    // Customizable Area End
}

export interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export interface IRoutine {
    id: number;
    formattedDate: string;
    items: IRoutineItem[]
}
export interface IRoutineItem {
    title: string;
    description: string;
    recurrence: string;
    start_time: string;
    end_time?: string;
    completed?: boolean;
    lapsed?: boolean;
}

export default class RoutineController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    getRoutineListApiCallId: string = '';
    deleteRoutineApiCallId: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            // Customizable Area Start
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionResponseMessage),
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            token: "",
            isDatePickerOpen: false,
            currentDate: new Date(),
            routinePageNum: 1,
            isAllRoutineFetch: false,
            tasks: [],
            confirmationPopUpData: null
            // Customizable Area End
        };

        // Customizable Area Start
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async componentDidMount() {
        await super.componentDidMount();
        // Customizable Area Start
        const token = await helper.getStorageData("authToken");
        if(token){
            this.setState({
                token
            },()=>{
                this.getRoutineListFromServer();
            })
        }
        // Customizable Area End
    }


    receive = async (from: string, message: Message) => {
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const webApiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );
            switch (webApiRequestCallId) {
                case this.getRoutineListApiCallId:
                    this.handleGetRoutineListApiCall(message)
                    break;
                case this.deleteRoutineApiCallId:
                    this.handleDeleteRoutineApiCall(message);
                    break;
            }
        }
        // Customizable Area End
    };

    // Customizable Area Start
    goToSelectedDate = (selectedDate: Date | null) => {
        if (selectedDate) {
            this.setState({
                currentDate: selectedDate,
                routinePageNum: 1,
                isAllRoutineFetch: false,
                isDatePickerOpen: false
            },()=>{
                this.getRoutineListFromServer()
            })
        }
    }

    openDatePicker = () => {
        this.setState({
            isDatePickerOpen: true
        })
    }
    closeDatePicker = () => {
        this.setState({
            isDatePickerOpen: false
        })
    }
    
    redirectTo = (destination: string)=>{
        this.props.navigation.navigate(destination)
    }

    getRoutineListFromServer = async ()=>{
        helper.showLoader();
        const {
            currentDate
        } = this.state;

        const startDate = new Date(currentDate);
        const endDate = new Date(startDate);
        endDate.setDate(startDate.getDate() + 6) 
        this.getRoutineListApiCallId = await getFilteredRoutineList({
            token: this.state.token,
            query: `task_start_date=${moment(startDate).format("DD-MM-YYYY")}&task_end_date=${moment(endDate).format("DD-MM-YYYY")}`
        })
    }

    handleGetRoutineListApiCall = (message: Message)=>{
        const response = handleGetRoutineListApiCall(message);
        this.setState({
            tasks: this.transformTasksData(response.data)
        })
        helper.hideLoader();
    }

    transformTasksData(data: ITask[]): ITransformedTask[] {
        const dateWiseTasks: Record<string, ITransformedTask> = {};
    
        data.forEach((task) => {
            const { title, description, reminder_frequency, start_time, task_date, status, end_time, task_end_date } = task.attributes;
            const today = moment().format('YYYY-MM-DD');

            // Format the task start and end dates
            const startDateFormatted = task_date === today ? `Today / ${moment(task_date).format('dddd')}` 
            : moment(task_date).format('DD MMM / dddd');
    
            // Helper function to create a task item structure
            const createTaskItem = (date: string, isEndTime: boolean): ITaskItem => ({
                id: task.id,
                title,
                description,
                recurrence: reminder_frequency.charAt(0).toUpperCase() + reminder_frequency.slice(1),
                start_time,
                end_time: end_time,
                end_date: task_end_date,
                completed: status === 'completed',
                lapsed: status === 'lapsed',
                start_date: task_date,
                status: status,
            });
    
            // Add task item for start date
            if (!dateWiseTasks[startDateFormatted]) {
                dateWiseTasks[startDateFormatted] = {
                    id: task.id,
                    formattedDate: startDateFormatted,
                    items: [],
                };
            }
            dateWiseTasks[startDateFormatted].items.push(createTaskItem(task_date, false));
        });
    
        // Convert the date-wise tasks object to an array
        return Object.values(dateWiseTasks);
    }

    openDeleteModal = (title: string, routineId?: string) => {
        this.setState({
            confirmationPopUpData: {
                header: "Delete Routine?",
                message: `Are you sure that you want to delete ${title}?`,
                type: "delete",
                routineId: routineId as string
            }
        })
    }

    onCloseConfirmationModal = () => {
        this.setState({
            confirmationPopUpData: null
        })
    }

    onAcceptConfirmation = async () => {
        if (this.state.confirmationPopUpData) {
            const { type, routineId } = this.state.confirmationPopUpData;
            if (type === "delete") {
                helper.showLoader();
                this.deleteRoutineApiCallId = await deleteRoutine({
                    token: this.state.token,
                    query: routineId
                })
                this.setState({
                    confirmationPopUpData: null
                })
            }
        }
    }

    handleDeleteRoutineApiCall = (message: Message)=>{
        const response = handleDeleteRoutine(message);
        helper.hideLoader();
        if(response.message){
            helper.showSuccessToast(response.message)
            this.getRoutineListFromServer();
        }else if(response.error){
            helper.showErrorToast(response.error)
        }
    }

    // Customizable Area End
}
import React from "react";

// Customizable Area Start
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import {
  leftAreaImage,
  checkBoxImage,
  logoImage,
  emptyCheckobxImage,
  leftAreaImageLowRes,
} from "./assets";
import CreateIcon from "@material-ui/icons/Create";
import AddPhotoAlternateIcon from "@material-ui/icons/AddPhotoAlternate";
import {
  Avatar,
  Button,
  Chip,
  Grid,
  Menu,
  Typography,
  Modal,
  IconButton,
  Dialog
} from "@material-ui/core";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import { ExpandMore, ExpandLess } from "@material-ui/icons";
import CloseIcon from "@material-ui/icons/Clear";
import { styled } from "@material-ui/styles";

import EmailAccountRegistrationController, {
  Props,
  configJSON,
  ICountryCode,
} from "./EmailAccountRegistrationController";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ErrorMessage from "../../../components/src/ErrorMessage.web";
import { LazyImageLoading } from "../../../components/src";
// Customizable Area End

export default class EmailAccountRegistrationBlock extends EmailAccountRegistrationController {constructor(props: Props) {
  super(props);
  // Customizable Area Start
  // Customizable Area End
}

// Customizable Area Start
  renderAvatarArea = () => {
    return (
      <>
        {this.state.selectedImage === null ? (
          <div
            onClick={this.onChooseFile}
            className="addImageContainer"
            data-testId="chooseFileId"
          >
            <input
              onChange={this.handleFileChange}
              data-testId="setUserProfileTestId"
              ref={this.fileInputRef}
              type={configJSON.fileType}
              accept="image/png, image/jpeg"
              className="imgUploadInput"
              hidden
            />
            <div className="addImgBox">
              <AddPhotoAlternateIcon className="addProfileIcon" />
            </div>
            <Typography style={{fontSize:'0.75rem',fontWeight:500,margin:0}} className="addProfileTxt">
              {configJSON.profile}
            </Typography>
          </div>
        ) : (
          <div className="avatarImgContainer">
            <Avatar className="avatarImg" src={this.state.selectedImage} />
            <input
              onChange={this.handleFileChange}
              data-testId="setUserProfileTestId"
              ref={this.fileInputRef}
              type={configJSON.fileType}
              accept={"image/jpeg, image/png"}
              className="imgUploadInput"
              hidden
            />
            <Button
              onClick={this.onChooseFile}
              className="editAvatarBtn"
              data-testId="chooseFileId"
            >
              <CreateIcon className="editIcon" />
            </Button>
          </div>
        )}
        {this.state.imgSizeExceed === true ? (
          <div className="imageErrorBox">
            <Typography className="sizeErrorTxt">
              {configJSON.imgErrorMessage}
            </Typography>
          </div>
        ) : (
          ""
        )}
      </>
    );
  };

  renderInputFields = () => {
    return (
      <>
        <div className="form-control">
          <label data-test-id="nameLabel" className="inputTextLabel">
            {this.state.nameInputLabel}
          </label>
          <div className="textFieldBox">
            <input
              data-testId="nameFiled"
              className="inputTextField"
              placeholder={this.state.nameInputLabel ? "" : configJSON.fullNameField}
              type={configJSON.typeText}
              maxLength={42}
              style={{
                textTransform: "capitalize"
              }}
              onFocus={this.handleNameFocus}
              onBlur={this.handleNameBlur}
              value={this.state.firstName}
              onChange={(event) => this.hanldeNameChange(event)}
            />
            {this.state.fullNameError.length > 0 && (
              <span id="nameErr" className="errorMsg">
                {this.state.fullNameError}
              </span>
            )}
          </div>
        </div>
        <div className="form-control">
          <label className="inputTextLabel">{this.state.userNameLabel}</label>
          <div className="textFieldBox">
            <input
              data-testId="userNameFiled"
              className="inputTextField"
              maxLength={42}
              onFocus={this.handleUserNameFocus}
              onBlur={this.handleUserNameBlur}
              placeholder={this.state.userNameLabel ? "" : configJSON.userNameField}
              type={configJSON.typeText}
              onChange={(event) =>
                this.handleUserNameChange(event.target.value)
              }
              value={this.state.userName}
            />
            {this.state.userNameError && (
            <span id="userNameError" className="errorMsg">
              {this.state.userNameError}
            </span>
          )}
          </div>
        </div>
        <div className="form-control">
          <label data-test-id="countryLabel" className="inputTextLabel">
            {this.state.labelData}
          </label>
          <div className="textFieldBox">
            <div className="inputCountryField">
              <PhoneInput
                country={"in"}
                inputClass="phoneInputField"
                placeholder={this.state.labelData ? "" : configJSON.phoneNumberTextField}
                value={this.state.phone}
                onMount={this.handleLabelFocus}
                searchPlaceholder="Search Country"
                enableSearch={true}
                enableLongNumbers={true}
                disableSearchIcon
                onFocus={this.handleLabelFocus}
                onBlur={this.handleLabelBlur}
                disableCountryGuess={false}
                countryCodeEditable={false}
                searchStyle={{ margin: "0", width: "97%", height: "30px" }}
                onChange={(value, country: ICountryCode) => {
                  this.handleNumberChange(value, country)
                }}
              />
            </div>
            {this.state.phoneNumberError && (
              <span id="phoneErrorId" className="errorMsg">
                {this.state.phoneNumberError}
              </span>
            )}
          </div>
        </div>
        <div className="form-control">
          <label data-test-id="dateLabel" className="inputTextLabel">
            { this.state.date && 'Select Date of Birth'}
          </label>
          <div className="textFieldBox">
            <input
              data-testId="dateField"
              placeholder={configJSON.selectDateField}
              className="inputTextField"
              type={configJSON.typeText}
              value={this.state.date}
              onClick={(e) => {
                this.setState({ dateRef: e.currentTarget });
                const ele = document.getElementById("change-date");
                ele?.click();
              }}
              readOnly
            />
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <KeyboardDatePicker
                variant="inline"
                format="DD MMMM YYYY"
                margin="normal"
                id="date-picker-inline"
                value={this.state.date}
                onChange={(e) => this.handleDateChange(e)}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                  id: "change-date",
                }}
                className="customDatePicker"
                style={{ display: "none" }}
                PopoverProps={{
                  anchorEl: this.state.dateRef,
                }}
                DialogProps={{
                  className: "datePickerDialog",
                }}
                autoOk={true}
                disableFuture
                shouldDisableDate={(date)=> {
                  return (new Date(date).toDateString() === new Date().toDateString());
                }}
              />
            </MuiPickersUtilsProvider>
          </div>
        </div>
        <div className="form-control">
          <label data-test-id="emailLabel" className="inputTextLabel">
            {this.state.emailInputLabel}
          </label>
          <div className="textFieldBox">
            <input
              data-testId="emailField"
              placeholder={this.state.emailInputLabel ? "" : configJSON.emailField}
              onFocus={this.handleEmailFocus}
              onBlur={this.handleEmailBlur}
              className="inputTextField"
              type={configJSON.typeText}
              onChange={(event) => this.handleEmailChange(event?.target.value)}
              value={this.state.email}
              disabled={this.state.isInvitedUser}
            />
            {this.state.emailErrorMessage && (
              <span id="email-error" className="errorMsg">
                {this.state.emailErrorMessage}
              </span>
            )}
          </div>
        </div>
      </>
    );
  };

  renderInterestMenuList = () => {
    const {
      selectedItems
    } = this.state;
    return (
      <>
        <div className="textFieldBox" style={{ marginBottom: selectedItems.length ? "0.5rem" : "1.25rem", marginTop: "0.5rem" }}>
          <label data-test-id="interestLabel" className="inputTextLabel">
            {this.state.interestInputLabel}
          </label>
          <CustomDropDown className="SignUp_CustomDropDown">
            <DropDownHeader
              data-testId="toggleInterestDropdown"
              onClick={this.handleInterestMenuList}
              className="SignUp_DropDownHeader"
            >
              <p
                style={{
                  fontSize: "1rem",
                  margin: 0,
                  padding: 0,
                  fontStyle: "normal",
                  fontWeight: 400,
                  lineHeight: "normal",
                }}
              >
                Select Interest*
              </p>
              <span>
                {this.state.dropdownOpen ? (
                  <ExpandLessIcon data-testid="expand-icon" className="right" />
                ) : (
                  <ExpandMoreIcon data-testid="more-icon" className="right" />
                )}
              </span>
            </DropDownHeader>
          </CustomDropDown>
          <Modal data-testId="modal" id="basic-menu" open={this.state.showModal}>
            <SelectDataModal>
              <div className="modalContainer">
                <div className="modalChild">
                  <Typography className="modalText">
                    {configJSON.interest}
                  </Typography>
                  <IconButton
                    onClick={this.handleModalClose}
                    className="cancelButton"
                  >
                    <CloseIcon />
                  </IconButton>
                </div>
                <div>
                  <div className="inputBox" style={{marginBottom:'1.2rem'}}>
                    <input
                      className="inputField interest item"
                      style={{marginBottom:0}}
                      type="text"
                      data-testId="inputItem"
                      value={this.state.newItem}
                      onChange={this.handleNewItemChange}
                    />
                    {
                    this.state.addInterestModalError && (
                      <div style={{fontSize:'0.75rem', color:'#FF2F3F',marginTop:'0.2rem'}}>{this.state.addInterestModalError}</div>
                    )
                  }
                  </div>
                  <Button
                    className="addBtn"
                    data-testId="addnewInterestBtn"
                    onClick={this.submitNewInterest}
                    style={{ opacity: this.state.newItem.trim() ? 1 : 0.5 }}
                    disabled={!this.state.newItem.trim()}
                  >
                    {configJSON.addValue}
                  </Button>
                </div>
              </div>
            </SelectDataModal>
          </Modal>
        </div>
        <SelectedItems className="interestList" style={{ marginBottom: selectedItems.length ? "1.25rem" : "0rem" }}>
          {this.state.selectedItems.map((item, index) => (
            <ChipData
              key={item.id}
              label={item?.attributes?.name}
              data-testid={`chipData${index}`}
              onDelete={() => this.handleRemoveInterest(item.id)}
              deleteIcon={
                <CloseIcon data-testid="delete-chip" className="closeIcon" />
              }
            />
          ))}
        </SelectedItems>
      </>
    );
  };

  renderTermsAndConditionModal = () => {
    return (
      <Dialog 
       onClose={this.toggleTermsAndConditionModal} 
       open={this.state.termsAndConditionModal} 
       data-testid="termsAndConditionModal"
       PaperProps={{
        style:{background: "transparent"}
       }}
      >
        <TermsAndConditionModalContainer className="SignUp_TermsAndConditionModalContainer">
          <div className="termsAndConditionModal">
            <div className="header">
              <header>Terms & Condition</header>
              <div data-testid="termsAndConditionCloseBtn" className="closeIcon" onClick={this.toggleTermsAndConditionModal}>
                <CloseIcon style={{ color: 'white' }}></CloseIcon>
              </div>
            </div>
            <div className="body show-scrollbar" dangerouslySetInnerHTML={{ __html: this.state.termsAndConditionString }}>
            </div>
            <div className="footer">
              {!this.state.termsAndCondition ? (
                <img
                  data-testid="termsAndConditionEmptyBox"
                  src={emptyCheckobxImage}
                  onClick={this.onClickTerms}
                  className="cursor-pointer"
                />
              ) : (
                <img
                  className="cursor-pointer"
                  data-testid="termsAndConditionCheckedBox"
                  onClick={this.onClickTerms}
                  src={checkBoxImage}
                />
              )}
              <span>
                I agree to the{" "}
                <span>Terms & Condition</span>
              </span>
            </div>
          </div>
        </TermsAndConditionModalContainer>
      </Dialog>
    )
  }

  renderSignUpBox = () => {
    return (
      <div className="signUpInputBoxContainer">
        {this.renderInputFields()}
        {this.renderInterestMenuList()}
        <div className="termsAndConditionBox" data-testId="termsCheckBoxId">
          {!this.state.termsAndCondition ? (
            <img
              src={emptyCheckobxImage}
              data-testid="checkboxImg"
              className="checkboxImg cursor-pointer"
              onClick={this.onClickTerms}
            />
          ) : (
            <img
              onClick={this.onClickTerms}
              className="termsCheckedImg cursor-pointer"
              src={checkBoxImage}
            />
          )}
          <span className="txtNormalPalette" data-testid="toggleTermsLink" onClick={this.toggleTermsAndConditionModal}>
            I agree to the {" "}
            <span className="txtPallete">Terms & Condition</span>
          </span>
        </div>
        {this.renderTermsAndConditionModal()}
        <div
          className="signUpBtnContainer"
          data-testId="singupBtn"
          onClick={this.handleSignUp}
        >
          <button
            className="signUpBtn"
            disabled={this.state.isSubmitting as any || !this.state.firstName.trim() || !this.state.phone || this.state.selectedItems.length === 0 || !this.state.userName.trim() || !this.state.termsAndCondition}
          >
            {configJSON.signUpBtn}
          </button>
        </div>
        <div style={{justifyContent:'center'}} className="alreadyAccountText">
          <span className="txtNormalPalette">
            {configJSON.alreadyAccount}{" "}
            <span
              className="txtPallete"
              onClick={this.navigateToLogin}
              data-testId={"signUpHeadingTestID"}
            >
              Sign In
            </span>
          </span>
        </div>
      </div>
    );
  };
  
  AddNewInterestInput = (value: string, readonly: boolean = false ,interestId?: string, onClose?:(interestId?: string)=>void, hideCheck?: boolean)=>{
    return (
      <StyledAddNewInterestInput className="Registration_StyledAddNewInterestInput">
        <input placeholder="Enter Interest" maxLength={42} value={value} readOnly={readonly} onChange={this.handleNewItemChange}/>
        <div className="icons">
          {
            !hideCheck && (
              <div className={`icon ${value ? "" : "disabled"}`}
                onClick={() => {
                  this.submitNewInterest();
                }}
              >
                <svg width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M5.54993 11.6534L0.215332 6.31881L1.28456 5.24961L5.54993 9.51499L14.7153 0.349609L15.7845 1.41881L5.54993 11.6534Z" fill="#F37976" />
                </svg>
              </div>
            )
          }
          <div className="icon" id="close-interest-menu" onClick={()=>{
            if(onClose){
              onClose(interestId);
            }
          }}>
            <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M1.227 9.83555L0.163574 8.77213L3.93667 4.99902L0.163574 1.25092L1.227 0.1875L5.0001 3.9606L8.7482 0.1875L9.81162 1.25092L6.03852 4.99902L9.81162 8.77213L8.7482 9.83555L5.0001 6.06245L1.227 9.83555Z" fill="#F37976" />
            </svg>
          </div>
        </div>
      </StyledAddNewInterestInput>
    )
  }
// Customizable Area End

render() {
  return (
      // Customizable Area Start
      <>
        <div>
          <SignUpView className="SignUpView_Styled">
            <Grid container>
              <Grid className="left" item sm={12} xs={12} md={6} lg={6}>
                <div className="signUpLeft" style={{ padding: "0.5rem", boxSizing: "border-box" }}>
                  <LazyImageLoading highResolutionImage={leftAreaImage} lowResolutionImage={leftAreaImageLowRes} className="signUpLeftImg" />
                </div>
              </Grid>
              <Grid className="right" item sm={12} xs={12} md={6} lg={6}>
                <div className="signUpRightArea">
                  <div className="signUpContainer">
                    <div>
                      <img style={{height:'5.5rem'}} src={logoImage} alt="" />
                    </div>
                    {this.renderAvatarArea()}
                    {this.renderSignUpBox()}
                  </div>
                </div>
              </Grid>
            </Grid>
          </SignUpView>
        </div>
      <Dialog 
        open={Boolean(this.state.interestMenuRef)} 
        style={{ display: "flex", justifyContent: "center", alignItems: "center" }}
        onClose={this.closeInterestModal}
        PaperProps={{
          style:{ background: "transparent" }
        }}
      >
        <StyledInterestPopup className="Registration_Interest_Popup">
          <div className="header">
            <p>Select Interest</p>
            <div className="icon">
              <IconButton data-test-id="close-interest-modal" onClick={this.closeInterestModal}>
                <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1.227 9.83555L0.163574 8.77213L3.93667 4.99902L0.163574 1.25092L1.227 0.1875L5.0001 3.9606L8.7482 0.1875L9.81162 1.25092L6.03852 4.99902L9.81162 8.77213L8.7482 9.83555L5.0001 6.06245L1.227 9.83555Z" fill="white" />
                </svg>
              </IconButton>
            </div>
          </div>
          <div className="interests-container">
            <div className="interests">
              {
                this.state.options.map(option => {
                  return (
                    <div data-testId="option" className={`item ${this.checkInterestAlreadyAdded(option.id) ? "selected" : ""} ${this.state.manuallyAddedInterestList.length >= 5 ? "disabled" : ""}`} key={option.id} onClick={this.onClickOptionItem(option)}>
                      {option.attributes.name}
                      <div className="icon" onClick={(event) => {
                        event.stopPropagation();
                        this.handleDelete(option.id)
                      }}>
                        <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M1.227 9.83555L0.163574 8.77213L3.93667 4.99902L0.163574 1.25092L1.227 0.1875L5.0001 3.9606L8.7482 0.1875L9.81162 1.25092L6.03852 4.99902L9.81162 8.77213L8.7482 9.83555L5.0001 6.06245L1.227 9.83555Z" fill="white" />
                        </svg>
                      </div>
                    </div>
                  )
                })
              }

            </div>
            <div className="manually-selected">
              {
                this.state.manuallyAddedInterestList.filter(interest=> interest.attributes.manual).map(interest => {
                  return (
                    <div key={interest.id}>
                      {this.AddNewInterestInput(interest.attributes.name, true, interest.id, this.handleDelete, true)}
                    </div>
                  )
                })
              }
            </div>
          </div>
          {
            this.state.isAddNewInterestInputOpen && (
              this.AddNewInterestInput(this.state.newItem, false, "", this.closeAddNewInterestInput, false)
            )
          }
          {
            this.state.addInterestModalError && (
              <ErrorMessage error={{message: this.state.addInterestModalError}} />
            )
          }
          <button disabled={this.state.manuallyAddedInterestList.length >= 5 || this.state.isAddNewInterestInputOpen} data-test-id="add-new-interest" className="add-new" onClick={this.handleAddNewItemClick}>Add New Interest</button>
          <button onClick={this.onAddInterest} className="add-btn" disabled={this.state.isAddNewInterestInputOpen || !this.state.isInterestModalUpdated}>Add</button>
        </StyledInterestPopup>
      </Dialog>
      </>
      // Customizable Area End
)}
}

// Customizable Area Start
const StyledInterestPopup = styled("div")({
  "&.Registration_Interest_Popup": {
    width: "20.4375rem",
    maxHeight: "80vh",
    borderRadius: "1.5rem",
    background: "linear-gradient(0deg, #23404B, #23404B)",
    padding: "1rem",
    display: "flex",
    flexDirection: "column",
    userSelect: "none",
    overflowX: "hidden",
    "& .header": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      position: "relative",
      "& .icon": {
        position: "absolute",
        top: "0rem",
        right: "1rem",
        transform: "translate(15%,-5%)"
      },
      "& > p": {
        fontSize: "1.125rem",
        fontWeight: 500,
        color: "rgba(255, 255, 255, 1)"
      }
    },
    "& .interests-container":{
      display: "flex",
      flexDirection: "column",
      gap: "0.5rem",
      overflowY: "auto",
      flex: 1,
      marginBottom: "0.5rem",
      "& .interests": {
        display: "flex",
        flexWrap: "wrap",
        gap: "0.5rem",
        padding: "0.7rem 0rem",
        "& .item": {
          height: "1.8125rem",
          borderRadius: "1rem",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: "0.5rem",
          padding: "0rem 0.5rem",
          border: "1px solid rgba(243, 121, 118, 1)",
          color: "rgba(243, 121, 118, 1)",
          fontSize: "0.875rem",
          fontWeight: 400,
          cursor: "pointer",
          "& > .icon": {
            display: "none"
          },
          "&.selected": {
            background: "linear-gradient(180deg, #F37F78 0%, #F75367 100%)",
            color: "rgba(255, 255, 255, 1)",
            fontWeight: 500,
            "& > .icon": {
              display: "block"
            }
          },
          "&:not(.selected)": {
            "&.disabled": {
              opacity: 0.5,
              cursor: "not-allowed"
            }
          }
        },

      },
      "& .manually-selected": {
        display: "flex",
        flexDirection: "column",
        gap: "0.5rem",
      }
    },
    "& .add-btn": {
      border: "none",
      outline: "none",
      height: "3rem",
      width: "100%",
      background: "linear-gradient(180deg, #F37F78 0%, #F75367 100%)",
      boxShadow: "0px 0px 20px 0px rgba(0, 0, 0, 0.3)",
      color: "rgba(255, 255, 255, 1)",
      fontSize: "1rem",
      fontWeight: 500,
      borderRadius: "3.5rem",
      cursor: "pointer",
      "&:disabled": {
        opacity: 0.5,
        cursor: "not-allowed"
      }
    },
    "& .add-new":{
      outline: "none",
      border: "none",
      background: "transparent",
      width: "100%",
      fontSize: "1rem",
      fontWeight: 500,
      color: "rgba(243, 121, 118, 1)",
      margin: "1rem 0rem",
      cursor: "pointer",
      "&:disabled": {
        opacity: 0.5,
        cursor: "not-allowed"
      }
    },
  }
})
const SignUpView = styled('div')({
  "&.SignUpView_Styled": {
    width: "100%",
    background: "#0F2631",
    "& .form-control": {
      display: "flex",
      flexDirection: "column",
      gap: "8px",
    },
    "& .avatarImg": {
      width: "100%",
      height: "100%",
      borderRadius: "20px",
    },
    "& .editAvatarBtn": {
      top: "-17%",
      minWidth: "0px",
      padding: "0px",
      float: "right",
      background: "linear-gradient(180deg, #F37F78 0%, #F75367 100%)",
      borderRadius: "100px",
      width: "24px",
      height: "24px",
    },
    "& .editIcon": {
      color: "white",
      fontSize: "20px",
      padding: "2px",
    },
    "& .signUpLeft": {
      width: "100%",
      height: "100vh",
    },
    "& .signUpLeftImg": {
      width: "100%",
      height: "100%",
      backgroundSize: "auto",
    },
    "& .checkData": {
      color: "white",
    },
    "& .signUpRightArea": {
      width: "100%",
      height: "100vh",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      overflow: "auto",
    },
    "& .signUpContainer": {
      width: "100%",
      height: "100%",
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
      marginTop: "3rem",
    },
    "& .inputCountryField": {
      display: "flex",
      "& .flag-dropdown": {
        width: "80px",
        borderRadius: "40px",
        background: "linear-gradient(180deg, #23404B 0%, #19282F 100%)",
        padding: "10px",
        justifyContent: "center",
        display: "flex",
        border: "none",
        fontSize: "16px",
        fontWeight: 400,
        color: "white",
        "& .selected-flag": {
          background: "none",
          "&:hover": {
            background: "none",
          },
          "& .arrow": {
            marginTop: "-4px",
            left: "24px",
            borderRight: "6px solid transparent",
            borderLeft: "6px solid transparent",
            borderTop: "6px solid #fff",
          }
        },
        "& .country-list": {
          background: "linear-gradient(180deg, #23404B 0%, #19282F 100%)",
          borderRadius: "6px",
          width: "160px",
          "& .search": {
            backgroundColor: "#23404B",
            "& input": {
              background: "none",
              color: "#fff"
            }
          },
          "& .country": {
            "&:hover": {
              background: "linear-gradient(180deg, #23404B 0%, #19282F 100%)",
            }, 
            "&.highlight": {
              background: "linear-gradient(180deg, #23404B 0%, #19282F 100%)"
            }   
          }
        },
        "& .selectDropdownIcon": {
          color: "white",
        },
        "&.isActive": {
          border: "1px solid red",
        },
        "&:hover": {
          backgroundColor: "#F0F0F0",
        },
      }
    },
    "& .avatarImgContainer": {
      width: "5.5rem",
      height: "5.578rem",
      borderRadius: "1.25rem",
      backgroundColor: "grey",
      marginTop: "1.5rem",
      marginBottom: '1rem'
    },
    "& .textFieldBox": {
      display: "flex",
      flexDirection: "column",
      marginBottom: "16px",
    },
    "& .textFieldBox:hover": {
      border: "none",
      outline: "none",
    },
    "& .inputTextField": {
      background: "linear-gradient(180deg, #23404B 0%, #19282F 100%)",
      padding: "10px 16px 10px 16px",
      borderRadius: "100px",
      border: "1px solid rgba(28, 150, 154, 0.11)",
      width: "100%",
      height: "48px",
      fontSize: '1rem',
      borderBottom: "none",
      color: "#FFFFFF",
      "&.MuiInput-underline::before": {
        borderBottom: "none",
      },
      "&.MuiInput-underline::after": {
        borderBottom: "none",
      },
      "&::placeholder": {
        fontSize: '1rem',
        fontWeight: 400,
        color: "white"
      }
    },
    "& .inputTextField:focus": {
      outline: "none",
      border: "1px solid #24eef41c",
    },
    "& .inputPhoneNumberDropDown:focus": {
      outline: "none",
      border: "1px solid #24eef41c",
    },
    "& .menuItemLabel": {
      fontSize: "16px",
    },
    "& .errorMsg": {
      lineHeight: "18px",
      fontSize: "12px",
      fontWeight: 400,
      color: "#ff2f3f",
      maxWidth: "320px",
      wordBreak: "break-word",
    },
    "& .inputPhoneNumberDropDown": {
      background: "linear-gradient(180deg, #23404B 0%, #19282F 100%)",
      paddingLeft: "14px",
      borderRadius: "100px",
      border: "1px solid rgba(28, 150, 154, 0.11)",
      width: "91px",
      appearance: "none",
      borderBottom: "none",
      color: "red",
      padding: "10px 16px 10px 16px",
      "& .MuiInputBase-input": {
        color: "white",
      },
      "&.MuiInput-underline::before": {
        borderBottom: "none",
      },
      "&.MuiInput-underline::after": {
        borderBottom: "none",
      },
    },
    "& .phoneInputField": {
      background: "linear-gradient(180deg, #23404B 0%, #19282F 100%)",
      padding: "10px 16px 10px 16px",
      borderRadius: "100px",
      border: "1px solid rgba(28, 150, 154, 0.11)",
      width: "247px",
      borderBottom: "none",
      height: "48px",
      marginLeft: "95px",
      color: "white",
      flex: 1,
      "&::placeholder": {
        fontSize: '1rem',
        fontWeight: 400,
        color: "white"
      },
    },
    "& .phoneInputField:focus": {
      outline: "none",
      border: "none",
    },
    "& .inputTextLabel": {
      color: "white",
      fontSize: "0.875rem",
      fontWeight: 400,
    },
    "& .textFieldLabel": {
      color: "white",
      paddingBottom: "4px",
      fontSize: "0.875rem",
    },
    "& .signUpBtn": {
      background: "linear-gradient(90deg, #FF7F77 0%, #FF6376 100%)",
      width: "100%",
      height: "48px",
      boxShadow: "0px 0px 20px 0px rgba(0, 0, 0, 0.3)",
      padding: "12px, 16px, 12px, 16px",
      borderRadius: "100px",
      marginTop: "0.75rem",
      opacity: "100%",
      border: "none",
      color: "rgba(255, 255, 255)",
      cursor: "pointer",
      "&:disabled": {
        opacity: 0.5,
        cursor: "inherit",
      },
    },
    "& .tncCheckbox": {
      width: "20px",
      height: "20px",
      borderRadius: "14px",
      border: "none",
      marginLeft: "5px",
    },
    "& .termsCheckedImg": {
      width: "20px",
      height: "20px",
    },
    "& .termsAndConditionBox": {
      display: "flex",
      alignItems: "center",
      gap: '0.5rem',
      marginBottom: "0.6rem"
    },
    "& .txtNormalPalette": {
      color: "rgba(255, 255, 255, 0.8)",
      fontSize: "0.875rem",
    },
    "& .txtPallete": {
      color: "rgba(255, 107, 118, 1)",
      margin: "auto",
      cursor: 'pointer'
    },
    "& .alreadyAccountText": {
      display: "flex",
      justifyContent: "center",
      width: "100%",
      marginTop: "1.5rem",
      marginBottom: "1rem",
    },
    "& .selectArrowIcon": {
      color: "white",
      right: "10px",
      size: "18px",
    },
    "& .dropDownPhIcon": {
      right: "9%",
      color: "rgba(255, 255, 255, 0.8)",
      position: "relative",
    },
    "& .addImageContainer": {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      padding: '1.13rem 0.8rem',
      borderRadius: "1.25rem",
      background: "linear-gradient(180deg, #23404B 0%, #19282F 100%)",
      marginTop: "1.5rem",
      marginBottom: "0.5rem",
      border: '0.312px solid rgba(22, 113, 116, 0.11)'
    },
    "& .addImageContainer:hover": {
      cursor: "pointer",
    },
    "& .addProfileIcon": {
      color: "rgba(32, 212, 217, 1)",
      zIndex: 10000,
    },
    "& .addProfileTxt": {
      fontSize: "12px",
      color: "white",
      marginTop: "10px",
    },
    "& .imageErrorBox": {
      marginTop: "16px",
    },
    "& .sizeErrorTxt": {
      color: "rgba(255, 47, 63, 1)",
      fontSize: "12px",
      fontWeight: 400,
    },
    "& .inputFieldOutlined": {
      maxWidth: "359px",
      "& .inputFieldOutlined:hover": {
        border: "none",
        outline: "none",
      },
      "& .MuiOutlinedInput-root": {
        background: "linear-gradient(180deg, #23404B 0%, #19282F 100%)",
        padding: "8px 15px 5px 6px",
        borderRadius: "100px",
        border: "1px solid rgba(28, 150, 154, 0.11)",
        color: "rgba(255, 255, 255, 1)",
      },
      "&.MuiOutlinedInput-notchedOutline": {
        borderColor: "transparent",
      },

      "&.MuiAutocomplete-hasClearIcon": {
        color: "white",
      },
      "&.MuiAutocomplete-option": {
        backgroundColor: "pink",
      },
      "& .MuiChip-outlined": {
        color: "#F37976",
        border: "1px solid #F37976",
      },
    },
    "& .MuiAutocomplete-paper": {
      background: "blue",
    },
    [`@media screen and (max-width: 960px)`]: {
      "& .left":{
        display: "none"
      },
      "& .signUpInputBoxContainer":{
        width: "90vw"
      }
    },
  }
});

const CustomMenu = styled(Menu)({
  "& .MuiList-padding": {
    padding: 0, // Set padding to 0
  },
  "& .MuiPaper-root":{
    background: "transparent"
  }
});

const TermsAndConditionModalContainer = styled('div')(({ theme }) => ({
  "&.SignUp_TermsAndConditionModalContainer": {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    "& .termsAndConditionModal": {
      width: '45vw',
      height: '85%',
      maxHeight: '35rem',
      borderRadius: '1rem',
      backgroundColor: '#23404B',
      display: 'flex',
      flexDirection: 'column',
      "& .header": {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '1rem',
        borderBottom: '0.0625rem solid #2d4953',
        "& header": {
          color: '#fff',
          fontSize: '1.125rem',
          fontStyle: 'normal',
          fontWeight: 500,
          lineHeight: 'normal',
        },
        position: 'relative',
        "& .closeIcon": {
          position: 'absolute',
          right: '1rem',
          top: '50%',
          transform: 'translateY(-50%)',
          "&:hover": {
            cursor: 'pointer'
          }
        }
      },
      "& .body": {
        display: 'flex',
        flex: 1,
        overflowY: 'auto',
        padding: '1rem 1rem 0rem',
        flexDirection: 'column',
        color: '#fff',
        fontSize: '0.875rem',
        fontWeight: 400,
        margin: 0,
        maxHeight: "65vh"
      },
      "& .footer": {
        display: 'flex',
        alignItems: 'center',
        padding: '1rem',
        gap: '0.71rem',
        "& span": {
          fontSize: '0.875rem',
          fontWeight: 400,
          fontStyle: "normal",
          fontHeight: 'normal',
          color: '#fff',
          "& span": {
            color: "#FF6B76"
          }
        }
      },
      "@media (max-width: 500px)": {
        width: '85%'
      }
    }
  }
}))

const CustomDatePicker = styled(KeyboardDatePicker)({
  "&.customDatePicker": {
    "& .MuiInput-underline::before": {
      borderBottom: "none",
    },
    "& .MuiInput-underline::after": {
      borderBottom: "none",
    },
  },
});

const ChipData = styled(Chip)({
  "&.MuiChip-root": {
    border: "1px solid #F37976",
    color: "#F37976",
    backgroundColor: "transparent",
    fontWeight: 400,
    lineHeight: "21px",
    fontSize: "14px",
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
  "& .closeIcon": {
    color: "#F37976",
  },
});

const SelectDataModal = styled('div')({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "100vh",
  "& .modalContainer": {
    width: "100%",
    maxWidth: "350px",
    display: "flex",
    flexDirection: "column",
    background: "#0F2631",
    padding: "15px",
    borderRadius: "24px",
  },
  "& .modalChild": {
    color: "white",
    alignItems: "center",
    justifyContent: "space-between",
    display: "flex",
  },
  "& .modalText": {
    margin: "auto",
  },
  "& .addBtn": {
    borderRadius: "56px",
    height: "48px",
    width: "100%",
    border: "1px, 2.5px, 2.5px, 1px",
    background: "linear-gradient(180deg, #F37F78 0%, #F75367 100%)",
    color: "#fff",
    fontSize: 16,
    fontWeight: 500,
    lineHeight: "24px",
    textTransform: "capitalize",
    padding: "10px 16px",
  },
  "& .inputField": {
    background: "linear-gradient(180deg, #23404B 0%, #19282F 100%)",
    padding: "10px 16px",
    borderRadius: "100px",
    border: "1px solid rgba(28, 150, 154, 0.11)",
    width: "100%",
    height: "48px",
    marginTop: "30px",
    marginBottom: "20px",
    color: "rgba(255, 255, 255, 1)",
  },
  "& .inputField:focus": {
    border: "none",
    outline: "none",
  },
  "& .cancelButton": {
    color: "white",
  },
});

const CustomDropDown = styled('div')({
  "&.SignUp_CustomDropDown": {
    position: "relative",
    cursor: "pointer",
    background: "linear-gradient(180deg, #23404B 0%, #19282F 100%)",
    padding: "10px 16px 10px 16px",
    borderRadius: "100px",
    border: "1px solid rgba(28, 150, 154, 0.11)",
    maxWidth: "343px",
    color: "rgba(255, 255, 255, 1)",
    wordWrap: "break-word",
    width: "343px",
    display: "flex",
    alignItems: "center",
    boxSizing: "border-box",
    "& .MuiIconButton-root": {
      paddingLeft: "0 !Important",
    },
    [`@media screen and (max-width: 960px)`]: {
      width: "100%",
      maxWidth: "100%"
    },
  }
});

const DropDownHeader = styled('div')({
  "&.SignUp_DropDownHeader": {
    gap: "15px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    border: "none",
    borderRadius: "2px",
    maxWidth: "343px",
    width: "100%",
    color: "white",
    fontSize: "18px",
    "&.isActive": {
      border: "1px solid red",
    },
    "&:hover": {
      color: "white",
    },

    "& .right": {
      flaot: "right",
    },
    [`@media screen and (max-width: 960px)`]: {
      width: "100%",
      maxWidth: "100%"
    },
  }
});


const SelectedItems = styled('div')({
  display: "flex",
  gap: "16px",
  width: "21.4375rem",
  flexWrap: "wrap",
});

const StyledSearchCountryInput = styled("div")({
  boxSizing:"border-box",
  padding:"0.5rem 0.3rem",
  "& input":{
    outline:"none",
    background:"transparent",
    border:"1px solid #19B5B945",
    width:"100%",
    borderRadius:"0.5rem",
    color:"white",
    padding:"0.1rem 0.5rem",
    fontSize:"0.875rem"
  }
});

const StyledAddNewInterestInput = styled("div")({
  "&.Registration_StyledAddNewInterestInput":{
    display: "flex",
    alignItems: "center",
    background: "linear-gradient(180deg, #23404B 0%, #19282F 100%)",
    border: "1px solid rgba(25, 181, 185, 0.27)",
    borderRadius: "6.25rem",
    padding: "0rem 1rem",
    height: "3rem",
    flexShrink: 0,
    "& input":{
      width: "100%",
      outline: "none",
      border: "none",
      background: "transparent",
      fontSize: "1rem",
      color: "rgba(255, 255, 255, 1)"
    },
    "& .icons":{
      display: "flex",
      alignItems: "center",
      gap: "1rem",
      "& .icon":{
        cursor: "pointer",
        "&.disabled":{
          cursor: "not-allowed",
          opacity: 0.5
        }
      }
    }
  }
})
// Customizable Area End
